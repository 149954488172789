import { 
  Box, 
  Flex,
  Heading, 
  Text 
} from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { Fragment } from "react";
import { RFPMenu } from "../../platform/rfps/RFPMenu";
import { OrderMenu } from "../../platform/orders/OrderMenu";
import { ProposalMenu } from "./ProposalMenu";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { selectAppInfo } from "../../../store/features/appInfoSlice";

export const VendorHome = () => {
  const user = useSelector(selectUser);
  const appInfo = useSelector(selectAppInfo);

  //get all openrfps
  const openRfps =
    appInfo.allRfps?.filter(function (rfp) {
      return (
        rfp.status !== "withdrawn" &&
        rfp.status !== "completed" &&
        rfp.status !== "confirmed"
      );
    }) ?? [];

  //get proposals and rfps proposed on
  const myProposals = appInfo.allProposals ?? [];

  //get vendor orders
  const myOrders = appInfo.allOrders;

  let proposedRfpsIds = [];
  let notProposedRfps = []; //rfps without proposals

  //get open rfps that have been proposed on
  openRfps.forEach((orfp) => {
    myProposals.forEach((myp) => {
      if (orfp.id === myp.rfp_id) {
        proposedRfpsIds.push(orfp.id);
      }
    });
  });

  //find rfps that have not been proposed on
  openRfps.forEach((orfp) => {
    if (!proposedRfpsIds?.includes(orfp.id)) {
      notProposedRfps.push(orfp);
    }
  });

  //of the ones not proposed on, get open rfps that match interest
  let interestRfps =
    notProposedRfps?.filter(function (rfp) {
      return user.products_offered?.includes(rfp.product);
    }) ?? [];

  //get proposals that have been rejected
  /*   let rejectedProposals = myProposals.filter(function (myp) {
    return myp.status === "rejected";
  }); */

  //get proposals that are active - not rejected or confirmed
  let activeProposals =
    myProposals?.filter(function (myp) {
      return (
        myp.status !== "rejected" &&
        myp.status !== "confirmed" &&
        myp.status !== "withdrawn"
      );
    }) ?? [];

  //get orders that are current and being executed
  let activeOrders =
    myOrders?.filter(function (myo) {
      return myo.status === "current" || myo.status === "paying";
    }) ?? [];

  const rfpContent = () => {
    return (
      interestRfps?.length > 0 ? (
        <Fragment>
          <RFPMenu rfps={interestRfps} />
        </Fragment>
      ) : (
        <Flex direction="column" align="center" justify="center">
          <Text fontSize="lg" my="2rem">
            We could not find any active requests right now. Check back later.
          </Text>
        </Flex> 
      )
    );
  };

  const proposalContent = () => {
    return (
      <Fragment>
        {activeOrders.length > 0 && (
          <Fragment>
            <Heading
              as="h3"
              textAlign="center"
              fontSize="sm"
              fontWeight="extrabold"
              letterSpacing="0.2rem"
              textTransform="uppercase"
              color="gray.500"
              my={{
                default: 6,
                md: 8,
              }}
              >
              {" "}
              - Open Orders -{" "}
            </Heading>
            <OrderMenu orders={activeOrders} />
          </Fragment>
        )}
        {activeProposals.length > 0 && (
          <Heading
            as="h3"
            textAlign="center"
            fontSize="sm"
            fontWeight="extrabold"
            letterSpacing="0.2rem"
            textTransform="uppercase"
            color="gray.500"
            my={{
              default: 8,
              md: 8,
            }}
          >
            {" "}
            - Active Proposals -{" "}
          </Heading>
        )}
        <ProposalMenu proposals={activeProposals} />
      </Fragment>
    );
  };

  return (
    <Layout>
      <PageLayout>
        <Box
          position="relative"
          zIndex="1"
          pl={{
            default: 0,
            md: "4rem",
          }}
          className="gridWrapper"
        >
          {/* {orderContent()} */}
          <Box>
            <Heading
              as="h2"
              variant="underline"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap={{
                default: "wrap",
                md: "nowrap",
              }}
              ml={{
                md: "8rem",
              }}
            >
              <Text>My Proposals</Text>
              {/* <Link variant="button" href="/vendor/create-proposal">
            <Icon mr="0.5rem" as={FiPlus} />
            Create Proposal
          </Link> */}
            </Heading>
          </Box>
          {proposalContent()}

          <Box mt="2rem">
            <Heading
              as="h2"
              variant="underline"
              mb={{
                default: 8,
                md: 8,
              }}
            >
              Find a Request
            </Heading>
          </Box>
          {rfpContent()}
        </Box>
      </PageLayout>
    </Layout>
  );
};
