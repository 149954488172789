import { Fragment } from "react";
import { NavBar } from "./Menu/NavBar";
import { Box, Container } from "@chakra-ui/react";
import { Footer } from "./Footer";

export function Layout({ children }) {
  return (
    <Fragment>
      <Box 
        flex="1 1 auto"
        maxW="1800px"
        mx="auto"
        pb={40}
        bg="white"
        overflow="hidden"
        >
        <NavBar />
        <Container 
          maxW="100%" 
          p="0" 
          mt={{
            default: "0",
            md: "-5rem",
          }}
          className="layout_Container"
          >
            {children}
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
}
