import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { Text } from "@chakra-ui/react";

export const BuyerHelp = () => {
  return (
    <Layout>
      <PageLayout>
        <Text>This is the space where the help page will go for Buyer</Text>
      </PageLayout>
    </Layout>
  );
};
