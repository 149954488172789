// import { theme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";

const transitionSpeed = "0.2s";
const outlineColor = "blue.200";

const theme = {
  breakpoints: {
    default: "0px",
    base: "320px",
    sm: "768px",
    md: "1080px",
    lg: "1400px",
    xl: "2000px",
  },
  fonts: {
    body: `'Lato', Helvetica, Arial, sans-serif`,
  },
  fontSizes: {
    xxsm: '0.7rem',
    xsm: '0.85rem',
    sm: '1rem',
    md: '1.15rem',
    lg: '1.4rem',
    xl: '2rem',
    xxl: '2.5rem',
  },
  fontWeights: {
    light: "100",
    regular: "300",
    semibold: "400",
    bold: "700",
    extrabold: "900",
  },
  colors: {
    white: "#fff",
    black: "#1F1F1F",
    gray: {
      100: '#f2f2f2',
      200: '#d9dbdb',
      300: '#b3b7b7',
      400: '#8e9294',
      500: '#686e70',
      600: '#424a4c',
    },
    blue: {
      100: "#5D8F9B",
      200: "#00627C",
      300: "#003B4F",
      400: "#003547",
      500: "#002f3f",
    },
    yellow: {
      100: "#ffe780",
      200: "#ffe266",
      300: "#ffdd4d",
      400: "#ffd833",
      500: "#FFCE00",
      600: "#e6b900",
      700: "#cca500",
      800: "#b39000",
      900: "#997c00",
    },
    red: {
      500: "#D35B50",
    },
    green: {
      100: "#66bca7",
      200: "#4db199",
      300: "#33a58a",
      400: "#1a9a7c",
      500: "#008F6D",
    },
  },
  components: {
    Badge: {
      baseStyle: {
        padding: "0.25rem 0.5rem",
        fontSize: "xsm",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "0",
        fontSize: "md",
        transition: transitionSpeed,
        _hover: {
          backgroundColor: "gray.100",
        },
        _focus: {
          outlineColor: outlineColor,
          outlineOffset: "5px",
          boxShadow: "none",
        }
      },
      variants: {
        link: {
          background: "none",
          color: "blue.300",
          textDecoration: "underline",
        },
        primary: {
          position: "relative",
          padding: "0 2.5rem",
          background: 'blue.300',
          color: "white",
          fontSize: {
            default: "sm",
            md: "sm",
          },
          _before: { 
            content: "' '",
            position: "absolute",
            top: "0",
            left: "0",
            width: "0",
            height: "0",
            borderTop: "1rem solid",
            borderColor: "white",
            borderRight: "1rem solid transparent",
            transition: transitionSpeed,
          },
          _after: { 
            content: "' '",
            position: "absolute",
            bottom: "0",
            right: "0",
            width: "0",
            height: "0",
            borderBottom: "1rem solid",
            borderColor: "white",
            borderLeft: "1rem solid transparent",
            transition: transitionSpeed,
          },
          _hover: {
            background: "blue.200",
            _before: { 
              top: "-7px",
              left: "-7px",
              width: "18px",
              height: "18px",
              borderTop: "0.15rem solid",
              borderLeft: "0.15rem solid",
              borderBottom: "0",
              borderRight: "0",
              borderColor: "blue.200",
            },
            _after: { 
              bottom: "-7px",
              right: "-7px",
              width: "18px",
              height: "18px",
              borderBottom: "0.15rem solid",
              borderRight: "0.15rem solid transparent",
              borderTop: "0",
              borderLeft: "0",
              borderColor: "blue.200",
            },
            _loading: {
              background: 'blue.300',
            }
          },
        },
        simple: {
          background: "none",
          color: "blue.300",
        },
        stacked: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
          color: "blue.300",
          gap: {
            default: "0.1rem",
            md: "0.5rem",
          },
          padding: {
            default: "0.15rem",
            md: "0.5rem",
          },
          borderRadius: "4px",
          fontSize: {
            default: "0.75rem",
            md: "0.85rem",
          },
          textAlign: "center",
          textDecoration: "none",
          textTransform: "uppercase",
          _hover: {
            background: "gray.100"
          }
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: "0px",
          outline: "auto",
          outlineWidth: "0",
          outlineOffset: "0",
          transition: transitionSpeed,
          _checked: {
            background: "blue.300",
            borderColor: "blue.300",  
          },
          _focus: {
            boxShadow: "none",
            outlineColor: outlineColor,
            outlineWidth: "2px",
            outlineOffset: "5px",
          },
        },
        label: {
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "gray.200",
        opacity: "1",
      }
    },
    Heading: {
      baseStyle: {
        fontFamily: `'Lato', Helvetica, Arial, sans-serif`,
      },
      variants: {
        underline: {
          display: "inline-block",
          padding: {
            default: "0 1rem",
            md: "1rem 1.4rem",
          },
          borderBottom: "1px solid",
          borderColor:  "gray.200",
        }
      }
    },
    Input: {
      baseStyle: {
        bg: "gray.100",
      },
      variants: {
        text: {
          field: {
            position: "relative",
            border: "1px solid",
            borderColor: "gray.200",
            boxSizing: "border-box",
            borderRadius: 0,
            bg: "gray.100",
            fontSize: {
              default: "md",
              md: "lg",
            },
            p: "0.5rem",
            _focus: {
              outline: "auto",
              outlineColor: outlineColor,
            },
            _disabled: {
              bg: "none",
              border: "0",
            },
          },          
        },
        file: {
          height: "auto",
          minHeight: "2rem",
          py: "2rem",
          bg: "transparent",
          borderRadius: "0",
        }
      },
      defaultProps: {
        variant: "text"
      }
    },
    Link: {
      baseStyle: {
        color: "blue.300",
        textDecoration: "underline",
        outlineWidth: "0",
        outlineOffset: "0",
        transition: transitionSpeed,
        _focus: {
          boxShadow: "none",
          outlineColor: outlineColor,
          outlineWidth: "1px",
          outlineOffset: "3px",
        },
      },
      variants: {
        simpleIconLink: {
          display: "flex",
          alignItems: "center",
          textDecoration: "none"
        },
        simpleIconLinkStacked: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: {
            default: "0.1rem",
            md: "0.5rem",
          },
          padding: {
            default: "0.15rem",
            md: "0.5rem",
          },
          borderRadius: "4px",
          fontSize: {
            default: "0.75rem",
            md: "0.85rem",
          },
          textAlign: "center",
          textDecoration: "none",
          textTransform: "uppercase",
          _hover: {
            background: "gray.100"
          }
        },
        button: {
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
          padding: {
            default: "0 1.5rem",
            md: "0 2.5rem",
          },
          background: 'blue.300',
          color: "white",
          fontSize: {
            default: "xsm",
            md: "sm",
          },
          lineHeight: "2.5rem",
          textDecoration: "none",
          transition: transitionSpeed,
          _before: { 
            content: "' '",
            position: "absolute",
            top: "0",
            left: "0",
            width: "0",
            height: "0",
            borderTop: "1rem solid",
            borderColor: "white",
            borderRight: "1rem solid transparent",
            transition: transitionSpeed,
          },
          _after: { 
            content: "' '",
            position: "absolute",
            bottom: "0",
            right: "0",
            width: "0",
            height: "0",
            borderBottom: "1rem solid",
            borderColor: "white",
            borderLeft: "1rem solid transparent",
            transition: transitionSpeed,
          },
          _hover: {
            background: "blue.200",
            textDecoration: "none",
            _before: { 
              top: "-7px",
              left: "-7px",
              width: "18px",
              height: "18px",
              borderTop: "0.15rem solid",
              borderLeft: "0.15rem solid",
              borderBottom: "0",
              borderRight: "0",
              borderColor: "blue.200",
            },
            _after: { 
              bottom: "-7px",
              right: "-7px",
              width: "18px",
              height: "18px",
              borderBottom: "0.15rem solid",
              borderRight: "0.15rem solid transparent",
              borderTop: "0",
              borderLeft: "0",
              borderColor: "blue.200",
            },
          },
          _focus: {
            outlineWidth: "2px",
          },
        },
        menu: {
          textDecoration: "none",
        }
      }
    },
    Menu: {
      variants: {
        avatar: {
          button: {
            w: "40px",
            h: "40px",
            overflow: "hidden",
            clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
          }
        }
      }
    },
    NumberInput: {
      variants: {
        numberinput: {
          field: {
            bg: "gray.100",
          }
        }
      },
      defaultProps: {
        variant: "numberinput"
      },
    },
    Select: {
      variants: {
        select: {
          field: {
            bg: "gray.100",
            _focus: {
              outline: "auto",
              outlineColor: outlineColor,
            },
          }
        }
      },
      defaultProps: {
        variant: "select"
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          color: "gray.500",
          _selected: {
            color: "blue.500",
            borderBottom: '2px solid',
            borderColor: "blue.500",
          },
        },
      },
    },
    Tag: {
      baseStyle: {
        container: {
          borderRadius: "0",
          backgroundColor: "#222222",
          border: "1px solid",
          textTransform: "uppercase",
          letterSpacing: "0.1rem",
          fontWeight: "extrabold",
          clipPath: {
            md: "polygon(21px 0%, 100% 0%, 100% 100%, 0% 100%)",
          },
        }
      },
      sizes: {
        sm: {
          container: {
            backgroundColor: "#222222",
            fontSize: {
              default: 'xxsm',
              md: 'xsm',
            },
            mr: {
              default: 4,
              md: 0,
            },
            px: {
              default: 5,
              md: 8,
            }, 
            py: 1, 
          },
        },
        md: {
          container: {
            backgroundColor: "#222222",
            fontSize: 'md',
            px: 10,
            py: 2, 
          },
        },
      },
      variants: {
        subtle: {
          container: {
            bg: "transparent",
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        bg: "gray.100",
      },
      variants: {
        textarea: {
          bg: "gray.100",
        }
      },
      defaultProps: {
        variant: "textarea"
      }
    },
    
  },
  styles: {
    global: {
      "html, body": {
        height: "100vh",
        bg: "blue.400",
        backgroundImage: "linear-gradient(to top, transparent, #003547), url(/background-pattern-blue.png)",
        backgroundRepeat: "repeat",
        backgroundSize: "30px",
      },
    },
  },
}


// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: { ...theme.colors },
};

// 3. extend the theme
const myTheme = extendTheme(config, theme);

export default myTheme;
