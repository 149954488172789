import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Text,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState, Fragment } from "react";
import { Select as ChakraSelect } from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useForm } from "react-hook-form";
import { UsaStateOptions } from "./ProfileData";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { useSelector } from "react-redux";
import { selectUser, addUser } from "../../../store/features/userSlice";
import { useDispatch } from "react-redux";
import { setMyUserProfile } from "../../../store/firebase/FirebaseFunctions";
import { captializeFirstLetter, sortByField } from "../../utilities/utilities";
import ReactStars from "react-rating-stars-component";

export const BuyerProfile = () => {
  const [defaultProducts, setDefaultProducts] = useState([]);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector(selectUser);
  const appInfo = useSelector(selectAppInfo);

  let sortedProductOptions = sortByField(appInfo.productOptions, "name");

  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //See if user exists and if does then pre-populate with profile info
  useEffect(() => {
    //pre-populate form
    if (user !== null) {
      for (const [key, value] of Object.entries(user)) {
        setValue(key, value);
        if (key === "products_interested") {
          setDefaultProducts(value);
        }
      }
    }
  }, [setValue, dispatch, user]);

  const onSubmitUpdate = async (data) => {
    setIsSubmitting(true);
    //send to firestore
    setMyUserProfile(data)
      .then((userData) => {
        //dispatch to profile
        dispatch(addUser(userData.data));
        toast({
          position: "top",
          description: "Profile updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
        toast({
          position: "top",
          description: "Something went wrong. Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleChange = (data) => {
    setDefaultProducts(data);
  };

  const ratingStars = () => {
    if (user.rating != null) {
      return (
        <Fragment>
          <Text as="em" mt={1} fontSize={"md"}>
            Current rating:{" "}
          </Text>
          <ReactStars
            value={Number(user.rating)}
            edit={false}
            count={5}
            size={20}
            activeColor="#6495ED"
            color="#DCDCDC"
          />
        </Fragment>
      );
    }
  };

  return (
    <Layout>
      <PageLayout>
        <Container maxW="100%" p={0}>
          <Stack
            spacing="5"
            pt={{
              default: 0,
              md: "0.5rem",
            }}
            pl={{
              default: 0,
              md: 44,
            }}
            pr={{
              default: 0,
              md: 0,
            }}
          >
          <chakra.form onSubmit={handleSubmit(onSubmitUpdate)}>
              <Heading
                as="h2"
                variant="underline"
                pos="relative"
                top={{
                  default: "0",
                  md: "-0.5rem",
                }}
                pt={0}
                mt={0}
                mb={4}
              >
                Your Profile
              </Heading>
              <Box>
                <Stack
                  spacing="5"
                  pl={{
                    default: "4",
                    md: 4,
                  }}
                  pr={{
                    default: "4",
                    md: 4,
                  }}
                  py={{
                    default: "5",
                    md: "0",
                  }}
                >
                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="first_name" isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input type="text" {...register("first_name")} />
                    </FormControl>
                    <FormControl id="last_name" isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input type="text" {...register("last_name")} />
                    </FormControl>
                  </Stack>

                  <FormControl id="street" isRequired>
                    <FormLabel>Street</FormLabel>
                    <Input type="text" {...register("street")} />
                  </FormControl>

                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="city" isRequired>
                      <FormLabel>City</FormLabel>
                      <Input type="text" {...register("city")} />
                    </FormControl>
                    <FormControl isRequired id="state">
                      <FormLabel>State / Province</FormLabel>

                      <ChakraSelect {...register("state")}>
                        {Object.entries(UsaStateOptions).map(([key, value]) => (
                          <option key={key}>{value}</option>
                        ))}
                      </ChakraSelect>
                    </FormControl>
                    <FormControl id="zipcode" isRequired>
                      <FormLabel>ZIP Code</FormLabel>
                      <Input
                        type="number"
                        {...register("zipcode", {
                          minLength: {
                            value: 5,
                            message: "Must be at least 5 digits long",
                          },
                        })}
                      />
                      {errors.zipcode && errors.zipcode?.message}
                    </FormControl>
                  </Stack>

                  <Heading as="h3" size="sm" pt="1rem">
                    Products of Interest
                  </Heading>

                  <FormControl id="products_interested">
                    <CheckboxGroup
                      size="md"
                      value={defaultProducts}
                      onChange={handleChange}
                    >
                      <Grid
                        templateColumns='repeat(5, 1fr)' 
                        gap={6}
                      >
                        {Object.entries(sortedProductOptions).map(
                          ([key, value]) => (
                            <GridItem>
                              <Checkbox
                                key={key}
                                value={value.id}
                                defaultChecked="true"
                                {...register("products_interested")}
                                >
                                {captializeFirstLetter(value.name)}
                              </Checkbox>
                            </GridItem>
                          )
                        )}
                      </Grid>
                    </CheckboxGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Divider mt={8} />

              <Flex justifyContent="center" mt={6}>
                <Button
                  variant="primary"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Save Profile
                </Button>
              </Flex>

              <Stack
                spacing="4"
                direction={{
                  default: "column",
                  md: "row",
                }}
                p={2}
              >
                {ratingStars()}
              </Stack>
            </chakra.form>
          </Stack>
        </Container>
      </PageLayout>
    </Layout>
  );
};
