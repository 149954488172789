import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiDollarSign } from "react-icons/fi";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { VendorRFPInfoCard } from "./VendorRFPInfoCard";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addIsUpdateProposal,
  selectSessionInfo,
} from "../../../store/features/sessionInfoSlice";
import { rfpStatusOptions } from "../../utilities/utilities";
import { useGetProduct } from "../../../hooks/useGetProduct";
var pluralize = require("pluralize");

export const VendorCurrentRFP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;

  const product = useGetProduct(rfp);

  const statusColor = rfpStatusOptions[rfp.status][0];
  const status = rfp.status;

  let proposalHeader = pluralize(" vendor", rfp.num_proposals);

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "10.5rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Current Request
          </Heading>

          <Flex
            direction={{
              default: "column",
              md: "row",
            }}
            px={{
              default: "2",
              md: "0",
            }}
            py={{
              default: "2",
              md: "0",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
              borderBottom={{
                default: "4px solid",
                sm: "0",
              }}
              borderColor="gray.200"
              mb={{
                default: "1rem",
                sm: "0",
              }}
            >
              <VendorRFPInfoCard product={product} statusColor={statusColor} status={status} />
            </Box>
            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 0,
                md: "1rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              <VStack spacing={"8"} mt={4}>
                {rfp.num_proposals === 0 ? (
                  <Text fontSize="lg">Be the first to bid on this request!</Text>
                ) : (
                <Text fontSize="lg">
                  There {pluralize("is", rfp.num_proposals)}{" "}
                  <Text as="span" fontWeight="extrabold">
                    {rfp.num_proposals}
                  </Text>{" "}
                  other {proposalHeader} bidding on this request.
                </Text>
                )}
                <Button
                  leftIcon={<FiDollarSign />}
                  variant="primary"
                  onClick={() => {
                    dispatch(addIsUpdateProposal(false));
                    navigate("/vendor/create-proposal");
                  }}
                >
                  Make a Proposal
                </Button>
              </VStack>
            </Box>
          </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
