import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  chakra,
  Button,
  Input,
  Stack,
  VStack,
  Box,
  useColorModeValue,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { useForm } from "react-hook-form";
import { sendEmail } from "../../../store/firebase/FirebaseFunctions";
import { useState } from "react";
import { captializeFirstLetter } from "../../utilities/utilities";

export const ShareRfpModal = (props) => {
  const user = useSelector(selectUser);
  const [isSending, setIsSending] = useState(false);

  const rfp = props.rfp;

  let showInviteDisclaimer = false;
  if (rfp.buyers_limit === "invite") {
    showInviteDisclaimer = true;
  }

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const toast = useToast();

  const onSubmit = async (data) => {
    setIsSending(true);
    try {
      let sender = user.first_name;
      let receipientName = data["name"];

      let productName = captializeFirstLetter(props.product.name);

      if (props.product.type === "service") {
        productName += " services";
      } else {
        productName += "s";
      }

      let emailData = {
        sender: sender,
        receipient: data["email"],
        rfpId: rfp.id,
        productName: productName,
        receipientName: receipientName,
      };

      sendEmail(emailData).then((response) => {
        if (response) {
          toast({
position: "top",
            description: "Invite sent!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
    reset();
    props.setIsSharing(false);
    setIsSending(false);
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader fontSize="lg">
            Share the request and invite a friend!
          </ModalHeader>
          <ModalBody>
            <Box
              bg="bg-surface"
              boxShadow={useColorModeValue("sm", "sm-dark")}
              borderRadius="lg"
            >
              <Stack
                spacing="5"
                px={{
                  default: "4",
                  md: "6",
                }}
                py={{
                  default: "5",
                  md: "6",
                }}
              >
                <VStack spacing="6">
                  {showInviteDisclaimer && (
                    <Text>
                      Make sure your friend is in the same zipcode to qualify
                      for the request
                    </Text>
                  )}
                  <FormControl id="name" isRequired>
                    <FormLabel>Their Name</FormLabel>
                    <Input type="text" {...register("name")} />
                    <FormErrorMessage>
                      {errors.name && errors.name?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl id="email" isInvalid={errors.email} isRequired>
                    <FormLabel>Their Email</FormLabel>
                    <Input
                      type="text"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.email && errors.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </Stack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={isSending}>
              Submit
            </Button>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  );
};
