import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Divider,
} from "@chakra-ui/react";
import { PayPalButtonWrapper } from "./PayPalButtonWrapper";

export const PaymentModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={6}>
          <PayPalButtonWrapper
            amount={props.amount}
            order_id={props.order_id}
            vendor_payee_id={props.vendor_payee_id}
            user_id={props.user_id}
            onCloseModal={props.onClose}
            payment_id={props.payment_id}
            setHasPaid={props.setHasPaid}
          />
        </ModalBody>
        <Divider my={2} />
        <ModalFooter>
          <Button variant="primary" mr={3} onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
