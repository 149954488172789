import {
  Stack,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  chakra,
  useToast,
} from "@chakra-ui/react";
import FocusLock from "react-focus-lock";
import { FiPackage } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { addCurrentOrder } from "../../../store/features/sessionInfoSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateOrderDeliveries } from "../../../store/firebase/FirebaseFunctions";

export const DeliveryPopoverForm = (props) => {
  let existingOrder = props.order;
  let orderField = props.orderField;
  let orderDataField = "num_units_" + orderField;

  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  useEffect( () => {
    setValue("updated_num", Number(existingOrder[orderDataField]));
  });

  const onSubmit = (data) => {
    //update order object in session and in db
    let newOrder = { ...existingOrder };
    let updatedNum = data["updated_num"];
    newOrder[orderDataField] = updatedNum;

    updateOrderDeliveries({
      numUnits: updatedNum,
      orderId: existingOrder.id,
    })
      .then(() => {
        props.setNumUnits(updatedNum);
        dispatch(addCurrentOrder(newOrder));
        toast({
          position: "top",
          description: `Order ${orderField} number updated!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        props.onClose();
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  return (
    <Popover
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      onClose={props.onClose}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button variant="stacked">
          <Icon boxSize={5} as={FiPackage} mr="0.5rem" />
          <Text as="span">{`Update ${orderField}`}</Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Stack spacing={4}>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>{`Update number ${orderField}`}</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    id="updated_num"
                    {...register("updated_num", {
                      validate: (value) =>
                        parseFloat(value) > 0 ||
                        "Amount must be greater than 0",
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>
                  {errors.updated_num && errors.updated_num?.message}
                </FormErrorMessage>
              </FormControl>
              <ButtonGroup mt={4} display="flex" justifyContent="flex-end">
                <Button variant="outline" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="teal"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Save
                </Button>
              </ButtonGroup>
            </chakra.form>
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
