import { FiUser, FiSettings } from "react-icons/fi";

export const items = [
  {
    title: "Profile",
    description: "Edit your profile and settings",
    href: "/profile",
    icon: FiUser,
  },
  {
    title: "Settings",
    description: "Change your app settings",
    href: "/settings",
    icon: FiSettings,
  },
];
