import { 
  Button, 
  Flex,
  Icon, 
  Input, 
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";

export const ChatFooter = ({
  inputMessage,
  setInputMessage,
  handleSendMessage,
}) => {
  return (
    <Flex w="100%" mb={4}>
      <Input
        placeholder="Write message..."
        paddingBottom="0.1rem"
        background="tranparent"
        border="0"
        borderBottom="1px solid"
        borderRadius="none"
        fontSize="sm"
        _focus={{
          outlineOffset: "0",
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSendMessage();
          }
        }}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
      />
      <Button
        variant="simple"
        // _hover={{
        //   bg: "white",
        //   color: "black",
        //   border: "1px solid black",
        // }}
        disabled={inputMessage.trim().length <= 0}
        onClick={handleSendMessage}
        aria-label="Send Message"
      >
        <Icon as={FiSend} />
      </Button>
    </Flex>
  );
};
