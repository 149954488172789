import { Badge, Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import {
  getDateString,
  proposalStatusOptions,
} from "../../utilities/utilities";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { useSelector } from "react-redux";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { ProposalInfoCard } from "./ProposalInfoCard";

/*
Page that hosts proposal info card (which has the actions) and basic rfp info
*/

export const VendorCurrentProposal = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  const proposal = sessionInfo.currentProposal;

  const product = useGetProduct(proposal);
  const statusColor = proposalStatusOptions[proposal.status][0];
  const status = proposal.status === "open" ? "submitted" : proposal.status;
  const today = new Date();
  const dateEnd = new Date(proposal.delivery_date_end);
  const timeDiff = dateEnd.getTime() - today.getTime();
  const daysLeft = Math.trunc(timeDiff / (1000 * 3600 * 24));

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "10.5rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Current Proposal
          </Heading>

          <Flex
            direction={{
              default: "column",
              sm: "row",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
            >
              <ProposalInfoCard
                product={product}
                statusColor={statusColor}
                status={status}
              />
            </Box>

            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 5,
                md: "2.5rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              <Heading
                as="h3"
                width="100%"
                mb={4}
                pb={8}
                borderBottom="1px solid"
                borderColor="gray.200"
                fontSize="xl"
                textAlign="center"
              >
                Proposal Details
              </Heading>

              <Box fontSize="sm" my={8}>
                <Heading as="h4" fontSize="lg">
                  Status
                </Heading>
                <Flex alignItems="center">
                  <Badge background={statusColor} color="white" mt={2}>
                    {proposal.status}
                  </Badge>
                  <Text fontSize="sm" pt={2} px={4}>
                    {proposal.status === "open" && (
                      <Text as="span">
                        Waiting for response from the buyer.
                      </Text>
                    )}
                    {proposal.status === "accepted" && (
                      <Text as="span">
                        The Buyer has accepted your proposal!
                      </Text>
                    )}
                  </Text>
                </Flex>
              </Box>

              <Box fontSize="sm" my={8}>
                <Heading as="h4" fontSize="lg">
                  Delivery Info
                </Heading>
                <Text mt={2}>
                  {getDateString(proposal.delivery_date_start)} –{" "}
                  {getDateString(proposal.delivery_date_end)} 
                </Text>
                {daysLeft >= 0 ? (
                  <Text fontSize="md">
                    Delivery due in{" "}
                    <Text
                      as="span"
                      fontSize="xl"
                      fontWeight="extrabold"
                      textDecoration="underline"
                      px={2}
                    >
                      {daysLeft}
                    </Text>{" "}
                    days
                  </Text>
                ) : (
                  <Text fontSize="lg" fontWeight="bold" color="red.500">
                    This order is Past Due!
                  </Text>
                )}
              </Box>

              <Box fontSize="sm" my={8}>
                <Heading as="h4" fontSize="lg">
                  Details to Buyer
                </Heading>
                <Text mt={2}>{proposal.other_details}</Text>
              </Box>
            </Box>
          </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
