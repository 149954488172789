import { 
    Badge,
    Box,
    Flex,
    Text,
} from "@chakra-ui/react";
import { Fragment } from "react";


export const Amounts = (props) => {

    const proposalRfpDiff = (props.total - props.estimate);
    
    const differenceString = (d) => {
        let diff = d;
        let overUnder = "";
        let diffString;
        
        if(diff === 0) {
            overUnder = "Matches";
            diffString = overUnder;
        } else {
            if (diff > 0) {
                overUnder = " over";
            } else {
                overUnder = " below";
            }

            if(diff !== 0) {
                diff = Math.abs(diff);
                diffString = "$" + diff.toLocaleString("en-US") + overUnder;
            }
        }
        return diffString;
    };
    
    const differenceColor = (d) => {
        let diffColor;
        
        if (d > 0) {
            diffColor = "red.500";
        } else {
            diffColor = "green.500";
        }
        
        return diffColor;
    };
    
    return (
        <Flex py={2} pl={2} w="100%" justifyContent="flex-end" textAlign="right">
            <Box>
                <Text fontSize="sm" color="gray.500">
                    {props.type === "order" && "Price Agreed Upon:" }
                    {props.type === "proposal" && "Your Proposed Amount:" }
                    {props.type === "request" && props.user === "buyer" && "Your Estimate:" }
                    {props.type === "request" && props.user === "vendor" && "Buyer's Estimate:" }
                </Text>
                <Text
                    fontSize={{
                        default: "xl",
                        md: "xxl",
                    }}
                    fontWeight="extrabold"
                    lineHeight="2rem"
                    pt={1}
                >
                    <Text as="span" fontSize="md" position="relative" top="-1rem">
                        {" "}
                        ${" "}
                    </Text>
                    {props.total.toLocaleString("en-US")}
                </Text>
        
                {props.units > 1 && (
                    <Text fontWeight="extrabold">
                        {props.units}{" "}
                        <Text
                            as="span"
                            fontSize={{
                                default: "xsm",
                                md: "sm",
                            }}
                            fontWeight="normal"
                        >
                            {props.units > 1 ? "Units" : "Unit" } @ {" "}
                        </Text>
                        {props.price}{" "}
                        <Text
                            as="span"
                            fontSize={{
                                default: "xsm",
                                md: "sm",
                            }}
                            fontWeight="normal"
                        >
                            per unit
                        </Text>
                    </Text>
                )}
            
                {props.type === "proposal" && (
                    <Fragment>
                        <Text pt={4} fontSize="sm" color="gray.500">Buyer's Request Estimate:</Text>
                        <Text
                            fontSize={{
                                default: "md",
                                md: "lg",
                            }}
                            fontWeight="extrabold"
                        >
                            <Text as="span" fontSize="sm" position="relative" top="-0.3rem">
                                {" "}
                                ${" "}
                            </Text>
                            {props.estimate.toLocaleString("en-US")}
                        </Text>
                        <Text
                            fontSize="sm"
                            fontWeight="bold"
                            color={differenceColor(proposalRfpDiff)}
                            >
                            {differenceString(proposalRfpDiff)} estimate
                        </Text>
                    </Fragment>
                )}
            </Box>
        </Flex>
    );
};
            