import { Button, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { RateUser } from "../../ratings/RateUser";
import { useState, Fragment } from "react";
import { FiStar } from "react-icons/fi";

export const PastOrderButtons = (props) => {
  const order = props.order;

  const user = props.user;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [ratingInfo, setRatingInfo] = useState();

  const setRatings = {};

  for (const [orderKey, orderValue] of Object.entries(props.ratingsGiven)) {
    //check to see if order already rated
    if (orderKey === order.id) {
      for (const [key, value] of Object.entries(orderValue)) {
        if (key === order.lead_buyer) {
          setRatings["lead_buyer"] = value;
        } else {
          setRatings["lead_buyer"] = false;
        }
        if (key === order.vendor) {
          setRatings["vendor"] = value;
        } else {
          setRatings["vendor"] = false;
        }
      }
    }
  }

  const actionsBuyer = () => {
    return (
      <Fragment>
        <Button
          variant="stacked"
          onClick={() => {
            setRatingInfo({
              title: "Vendor",
              userid_receiver: order.vendor,
              userid_giver: user.uid,
              order_id: order.id,
              old_rating: setRatings.vendor,
            });

            onOpen();
          }}
        >
          <Icon boxSize={5} as={FiStar} mr="0.5rem" />
          <Text as="span">Rate Vendor</Text>
        </Button>

        {user.uid !== order.lead_buyer && (
          <Button
            variant="stacked"
            onClick={() => {
              setRatingInfo({
                title: "Lead Buyer",
                userid_receiver: order.lead_buyer,
                userid_giver: user.uid,
                order_id: order.id,
                old_rating: setRatings.lead_buyer,
              });

              onOpen();
            }}
          >
            <Icon boxSize={5} as={FiStar} mr="0.5rem" />
            <Text as="span">Rate Lead Buyer</Text>
          </Button>
        )}
      </Fragment>
    );
  };

  const actionsVendor = () => {
    return (
      <Button
        variant="stacked"
        onClick={() => {
          setRatingInfo({
            title: "Lead Buyer",
            userid_receiver: order.lead_buyer,
            userid_giver: user.uid,
            order_id: order.id,
            old_rating: setRatings.lead_buyer,
          });

          onOpen();
        }}
      >
        <Icon boxSize={5} as={FiStar} mr="0.5rem" />
        <Text as="span">Rate Lead Buyer</Text>
      </Button>
    );
  };

  return (
    <Fragment>
      {user.uid === order.vendor ? actionsVendor() : actionsBuyer()}
      <RateUser
        key={order.id}
        isOpen={isOpen}
        onClose={onClose}
        ratingInfo={ratingInfo}
      />
    </Fragment>
  );
};
