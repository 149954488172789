export const getDateString = (rawDate) => {
  return new Date(rawDate).toLocaleDateString("en-us", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const captializeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatDate = (item) => {
  let date;
  date = new Date(item).toLocaleDateString("en-US");

  return date;
};

export const getMenuItemTimestamp = (item) => {
  let showDate;
  if (item.updated_at != null) {
    let seconds = item.updated_at.seconds || item.updated_at._seconds;
    showDate = new Date(seconds * 1000).toLocaleDateString("en-US");
  } else {
    let seconds = item.created_at.seconds || item.created_at._seconds;
    showDate = new Date(seconds * 1000).toLocaleDateString("en-US");
  }

  return showDate;
};

export const sortByField = (data, field) => {
  let copied = data.slice();
  copied.sort(function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return copied;
};

export const rfpStatusOptions = {
  open: ["yellow.500", "black", "open to and selecting proposals"],
  confirmed: ["green.400", "white", "buyer has confirmed a deal with a vendor"],
  completed: ["green.400", "white", "order completed"],
  withdrawn: ["red.500", "white", "buyer has withdrawn and closed this rfp"],
};

export const proposalStatusOptions = {
  open: ["green.500", "green.500", "submitted and open"],
  negotiating: ["yellow.500", "yellow.500", "in negotiation with Buyer"],
  accepted: ["green.400", "white", "buyer has accepted your proposal"],
  confirmed: ["green.400", "white", "deal confirmed and order ready"],
  rejected: ["red.500", "white", "buyer has rejected your proposal"],
  withdrawn: [
    "gray.500",
    "gray.500",
    "vendor has withdrawn and closed this proposal",
  ],
};

export const orderStatusOptions = {
  paying: ["yellow.500", "payments in progress"],
  current: ["green.400", "currently being executed"],
  completed: ["blue.500", "delivered and completed"],
  cancelled: ["red.500", "order has been cancelled"],
};
