import { addCurrentProposal } from "../../../store/features/sessionInfoSlice";
import { addIsUpdateProposal } from "../../../store/features/sessionInfoSlice";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector } from "react-redux";
import { proposalStatusOptions } from "../../utilities/utilities";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { useGetRFP } from "../../../hooks/useGetRFP";
import "../../../UI/card.css";
import { Card } from "../../../UI/Card";

/*
Renders card showing proposal info in vendor menu
Links to current profile and gives actions of update and negotiate/chat
*/

export const ProposalMenuCard = (props) => {
  const user = useSelector(selectUser);

  const proposal = props.proposal;

  const product = useGetProduct(proposal);
  const rfp = useGetRFP(proposal);

  const price = "$" + proposal.price_per;

  const statusColor = proposalStatusOptions[proposal.status][0];
  const units = proposal.num_units;
  const proposalTotal = proposal.price_per * units;
  const rfpEstimate = rfp?.price_per_range_end
    ? rfp?.price_per_range_end * units
    : rfp?.price_per_range_start * units;

  return (
    <Card
      deliveryDate={rfp?.delivery_date_end}
      details={rfp?.other_details}
      estimate={rfpEstimate}
      id={proposal.id}
      imageUrl={product?.image_url}
      imageName={product?.name}
      name={rfp?.name}
      object={proposal}
      onLinkClick={addCurrentProposal(proposal)}
      onUpdateClick={addIsUpdateProposal(true)}
      price={price}
      product={product}
      productName={product.name}
      statusColor={statusColor}
      status={proposal.status}
      to={"/vendor/current-proposal"}
      total={proposalTotal}
      type="proposal"
      units={units}
      user={user}
    />
  );
};
