import millify from "millify";
import { rfpStatusOptions } from "../../utilities/utilities";
import { selectUser } from "../../../store/features/userSlice";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { useSelector } from "react-redux";
import { addCurrentRfp } from "../../../store/features/sessionInfoSlice";
import { Card } from "../../../UI/Card";
import "../../../UI/card.css";

export const RFPMenuCard = (props) => {
  const user = useSelector(selectUser);
  const appInfo = useSelector(selectAppInfo);
  const rfp = props.rfp;
  const product = useGetProduct(rfp);

  //get how many open proposals set for this rfp
  const openProposals = appInfo.allProposals?.filter(function (proposal) {
    return (
      proposal.rfp_id === rfp.id &&
      proposal.status !== "rejected" &&
      proposal.status !== "withdrawn"
    );
  });

  let numProposals = openProposals?.length ?? 0;

  const price = rfp.price_per_range_end
    ? "$" +
      (Math.trunc(rfp.price_per_range_start) +
        " - $" +
        Math.trunc(rfp.price_per_range_end))
    : "$" + Math.trunc(rfp.price_per_range_start);
  const status = rfp.status;
  const statusColor = rfpStatusOptions[rfp.status][0];
  const units = rfp.num_units;
  const rfpEstimate = rfp.price_per_range_end
    ? millify(rfp.price_per_range_start * units, { lowercase: true }) +
      "–" +
      millify(rfp.price_per_range_end * units, { lowercase: true })
    : millify(rfp.price_per_range_start * units, { lowercase: true });

  return (
    <Card
      deliveryDate={
        rfp?.delivery_date_end
          ? rfp?.delivery_date_end
          : rfp?.delivery_date_start
      }
      details={rfp?.other_details}
      estimate={rfpEstimate}
      id={rfp?.id}
      imageName={product?.name}
      imageUrl={product?.image_url}
      name={rfp?.name}
      object={rfp}
      onLinkClick={addCurrentRfp(rfp)}
      onUpdateClick={""}
      price={price}
      product={product}
      productName={product?.name}
      status={status}
      statusColor={statusColor}
      to={"/" + user?.account_type + "/current-rfp"}
      total={rfpEstimate}
      type="request"
      proposalCount={numProposals}
      units={units}
      user={user}
    />
  );
};
