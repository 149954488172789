import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Skeleton,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Layout } from "../../UI/Layout";
import { PageLayout } from "../../UI/PageLayout";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";
import { selectAppInfo } from "../../store/features/appInfoSlice";
import { captializeFirstLetter } from "../utilities/utilities";

/*
Show the storefront page and give an option to edit
Both the storefront page and option to edit are separate components
*/
export const VendorStorefront = () => {
  const user = useSelector(selectUser);
  const vendor = user;

  let storedDescription = user["store_description"] ?? null;
  let storedImage = user["store_image"] ?? null;

  const appInfo = useSelector(selectAppInfo);
  const vendorProducts = [];

  vendor.products_offered.forEach((prod) => {
    const product = appInfo.productOptions.filter(function (po) {
      return po.id === prod;
    })[0];
    vendorProducts.push(captializeFirstLetter(product.name));
  });

  let allProducts = vendorProducts.join(", ");

  return (
    <Layout>
      <PageLayout>
        <Container 
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            variant="underline"
            ml={{
              md: "12rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            <Text>{vendor.company_name}</Text>
          </Heading>
          <Flex 
            direction={{
              default: "column",
              sm: "row",
            }}
            p={{
              default: "1rem",
              md: "0.5rem 2rem 2rem 13rem",
            }}
          >
            <Box>
              {!storedImage ? (
                <Box 
                  display="flex" 
                  w={{
                    default: "100%",
                    md: 250,
                  }} 
                >
                  <Text as="em">
                    Visit the{" "}
                    <Link color="blue.500" href="/vendor/profile">
                      profile page
                    </Link>{" "}
                    to upload a store image
                  </Text>
                </Box>
              ) : (
                <Box>
                  <Image
                    display="flex" 
                    w={{
                      default: "100%",
                      sm: 250,
                    }} 
                    src={storedImage}
                    alt={vendor.company_name}
                    draggable="false"
                    fallback={<Skeleton />}
                  />
                </Box>
              )}
            </Box>
            <Box 
              pt={{
                default: 8,
                md: 0,
              }}
              px={{
                default: 0,
                sm: 8,
              }}
            >
              <Text fontSize="md">{vendor.street}</Text>
              <Text fontSize="md">
                {vendor.city}, {vendor.state} {vendor.zipcode}
              </Text>
              <Spacer />
              <Text fontSize="md" mt={4}>
                Products offered:{" "}
                <Text as="span" fontWeight="bold">
                  {allProducts}
                </Text>
              </Text>
              <Spacer />
              {!storedDescription ? (
                <Box display="flex" w={250}>
                  <Text>
                    Visit the <Link href="/profile">profile page</Link> to
                    upload a store description
                  </Text>
                </Box>
              ) : (
                <Text mt={4}>{storedDescription}</Text>
              )}
            </Box>
          </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
