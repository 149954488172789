import { Layout } from "./Layout";
import { PageLayout } from "./PageLayout";
import { RotatingLines } from "react-loader-spinner";
import { Center } from "@chakra-ui/react";

/*
Animation for loading page
*/

export const LoadingStart = () => {
  return (
    <Layout>
      <PageLayout header="Loading...">
        <Center>
          <RotatingLines
            strokeColor="#4169E1"
            strokeWidth="5"
            animationDuration="0.75"
            width="200"
            visible={true}
          />
        </Center>
      </PageLayout>
    </Layout>
  );
};
