import {
  Button,
  Heading,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
} from "@chakra-ui/react";

export const CompleteProfileModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={6}>
          <Heading
            align="center"
          >
            Welcome to Yeehive!
          </Heading>
          <Text p={4} fontSize="md">Customize your profile settings to get products catered to your interests,</Text>
        </ModalBody>

        <ModalFooter justifyContent="space-around">
          <Button variant="simple" mr={3} onClick={props.onClose} fontSize="sm">
            Not right now
          </Button>
          <Link href="/profile" variant="button">
            Customize Profile
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
