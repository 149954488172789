import { Route, Routes, Navigate, useLocation, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "./store/features/userSlice";

import Main from "./components/platform/Main";
import { Login } from "./components/auth/Login";
import { ResetPassword } from "./components/auth/ResetPassword";
import { ForgotPassword } from "./components/auth/ForgotPassword";
import { Register } from "./components/auth/Register";
import { PrivacyPolicy } from "./components/auth/PrivacyPolicy";
import { TermsAndConditions } from "./components/auth/TermsAndConditions";
import { CompleteRegister } from "./components/auth/CompleteRegister";

import { CreateRFP } from "./components/buyer/rfp/CreateRFP";
import { BuyerCurrentRFP } from "./components/buyer/rfp/BuyerCurrentRFP";
import { BuyerCurrentOrder } from "./components/buyer/orders/BuyerCurrentOrder";
import { BuyerCompletedOrder } from "./components/buyer/orders/BuyerCompletedOrder";
import { BuyerPastOrdersMenu } from "./components/buyer/orders/BuyerPastOrdersMenu";
import { BuyerCurrentOrdersMenu } from "./components/buyer/orders/BuyerCurrentOrdersMenu";
import { StorefrontView } from "./components/buyer/StorefrontView";

import { VendorCurrentRFP } from "./components/vendor/rfp/VendorCurrentRFP";
import { CreateProposal } from "./components/vendor/proposal/CreateProposal";
import { VendorCurrentProposal } from "./components/vendor/proposal/VendorCurrentProposal";
import { VendorCurrentOrder } from "./components/vendor/orders/VendorCurrentOrder";
import { VendorCompletedOrder } from "./components/vendor/orders/VendorCompletedOrder";
import { VendorPastOrdersMenu } from "./components/vendor/orders/VendorPastOrdersMenu";
import { VendorCurrentOrdersMenu } from "./components/vendor/orders/VendorCurrentOrdersMenu";
import { VendorStorefront } from "./components/vendor/VendorStorefront";

import { Settings } from "./components/platform/settings/Settings";
import { Profile } from "./components/platform/profiles/Profile";
import { Home } from "./components/platform/Home";
import { Help } from "./components/platform/help/Help";
import { NotificationPage } from "./components/notifications/NotificationPage";

/*
Check to see if user is buyer or vendor then route appropriate pages
If not logged in but still trying to access via url, then send to login page
*/

export default function AppRouter() {
  const user = useSelector(selectUser);
  let routesAccount;
  let accountTypeRoutes;

  if (user?.account_type === "buyer") {
    accountTypeRoutes = (
      <Fragment>
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/home" element={<Home />} />
        <Route path="/help" element={<Help />} />
        <Route path="/buyer/create-rfp" element={<CreateRFP />} />
        <Route path="/buyer/current-rfp" element={<BuyerCurrentRFP />} />
        <Route path="/buyer/current-order" element={<BuyerCurrentOrder />} />
        <Route
          path="/buyer/completed-order"
          element={<BuyerCompletedOrder />}
        />

        <Route path="/buyer/past-orders" element={<BuyerPastOrdersMenu />} />
        <Route
          path="/buyer/current-orders"
          element={<BuyerCurrentOrdersMenu />}
        />
        <Route path="/buyer/vendor-storefront" element={<StorefrontView />} />
      </Fragment>
    );
  }
  if (user?.account_type === "vendor") {
    accountTypeRoutes = (
      <Fragment>
        <Route path="/vendor/current-rfp" element={<VendorCurrentRFP />} />
        <Route
          path="/vendor/current-proposal"
          element={<VendorCurrentProposal />}
        />
        <Route path="/vendor/create-proposal" element={<CreateProposal />} />
        <Route path="/vendor/current-order" element={<VendorCurrentOrder />} />
        <Route
          path="/vendor/completed-order"
          element={<VendorCompletedOrder />}
        />
        <Route path="/vendor/past-orders" element={<VendorPastOrdersMenu />} />
        <Route
          path="/vendor/current-orders"
          element={<VendorCurrentOrdersMenu />}
        />
        <Route path="/vendor/storefront" element={<VendorStorefront />} />
      </Fragment>
    );
  }

  if (user?.uid) {
    //reroute to force user to complete registration if they haven't yet
    if (!user?.account_type || !user?.username) {
      routesAccount = (
        <Fragment>
          <Route path="/complete-register" element={<CompleteRegister />} />
          <Route
            path="/*"
            element={<Navigate replace to="/complete-register" />}
          />
        </Fragment>
      );
    }
    //if registration complete then give full access
    else if (user?.account_type && user?.username) {
      routesAccount = (
        <Fragment>
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home />} />
          <Route path="/help" element={<Help />} />
          <Route path="/notifications" element={<NotificationPage />} />

          {accountTypeRoutes}
          <Route path="/*" element={<Navigate replace to="/home" />} />
        </Fragment>
      );
    }
  }

  let loggedOutRoutes = (
    <Fragment>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/register" element={<Register />} />
    </Fragment>
  );
  
  return (
    <Routes>
      <Route path="/main" element={<Main />} />
      <Route element={<AlreadyLoggedInAuth />}>{loggedOutRoutes}</Route>

      <Route element={<RequireAuth />}>{routesAccount}</Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/toc" element={<TermsAndConditions />} />
      <Route path="/*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
}

function AlreadyLoggedInAuth() {
  const user = useSelector(selectUser);

  //if already logged in then don't allow
  if (user?.uid && user?.account_type && user?.username) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected.
    return <Navigate to="/home" />;
  }
  return <Outlet />;
}

function RequireAuth() {
  let location = useLocation();
  const user = useSelector(selectUser);

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
