import millify from "millify";
import {
  Box,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSessionInfo,
} from "../../../store/features/sessionInfoSlice";
import { ChatDrawer } from "../../chat/ChatDrawer";
import { AlertPopup } from "../../platform/elements/AlertPopup";
import { addCurrentRfp } from "../../../store/features/sessionInfoSlice";
import { useEffect, useState } from "react";
import {
  withdrawRfp,
  supporterLeaveRfp,
} from "../../../store/firebase/FirebaseFunctions";
import { useNavigate } from "react-router-dom";
import { ShareRfpModal } from "./ShareRfpModal";
import { Card } from "../../../UI/Card"; 
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { rfpStatusOptions } from "../../utilities/utilities";


export const BuyerRFPInfoCard = (props) => {
  const user = useSelector(selectUser);
  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();

  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useDisclosure();

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  const {
    isOpen: isOpenLeave,
    onOpen: onOpenLeave,
    onClose: onCloseLeave,
  } = useDisclosure();

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLead, setIsLead] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  //if rfp has been withdrawn then do not enable actions
  const [withdrew, setWithdrew] = useState(false);
  const [withdrawPermission, setWithdrawPermission] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState();
  const [canLeave, setCanLeave] = useState();
  const [leavePermission, setLeavePermission] = useState(false);
  const appInfo = useSelector(selectAppInfo);

  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;
  const price = rfp.price_per_range_end ? "$" + (rfp.price_per_range_start + " - $" + rfp.price_per_range_end) : "$" + rfp.price_per_range_start;
  const units = rfp.num_units;
  let product = props.product;
  const statusColor = rfpStatusOptions[rfp.status][0] + ".500";
  const status = rfp.status;
  const total = rfp.price_per_range_end ? 
                  millify(rfp.price_per_range_start * units, { lowercase: true }) + " - " + millify(rfp.price_per_range_end * units, { lowercase: true }) 
                : millify(rfp.price_per_range_start * units, { lowercase: true })

  //get how many open proposals set for this rfp
  const openProposals = appInfo.allProposals?.filter(function (proposal) {
    return (
      proposal.rfp_id === rfp.id &&
      proposal.status !== "rejected" &&
      proposal.status !== "withdrawn"
    );
  });

  let numProposals = openProposals?.length ?? 0;

  useEffect(() => {
    //check to see if lead buyer
    if (user.uid === rfp.lead_buyer) {
      setIsLead(true);
    }
  }, [user, rfp]);

  //if rfp is withdrawn then disable actions
  useEffect(() => {
    if (rfp.status === "withdrawn") {
      setWithdrew(true);
    } else {
      setWithdrew(false);
    }
  }, [setWithdrew, rfp.status]);

  //only allow withdrawing or leaving if rfp is open
  useEffect(() => {
    if (rfp.status === "open") {
      if (isLead) {
        setCanWithdraw(true);
      }
      //can leave rfp if supporter, or in buyers asked or invited
      else if (
        rfp.supporters.includes(user.uid) ||
        rfp.buyers_asked.includes(user.uid) ||
        rfp.buyers_invited.includes(user.uid)
      ) {
        setCanLeave(true);
      }
    }
  }, [rfp, setCanWithdraw, isLead, setCanLeave, user.uid]);

  //when user agrees to continue with withdraw, execute the withdraw by checking state of withdraw permission
  useEffect(() => {
    if (withdrawPermission && !withdrew) {
      //update rfp and associated proposals
      withdrawRfp({
        rfpId: rfp.id,
      })
        .then(() => {
          //update and dispatch to current rfp
          let newRfp = { ...rfp };
          newRfp["status"] = "withdrawn";
          dispatch(addCurrentRfp(newRfp));

          toast({
            position: "top",
            description: "Request withdrawn!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setWithdrew(true);
          setWithdrawPermission(false);
          onCloseWithdraw();
        })
        .catch((error) =>
          toast({
            position: "top",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        );
    }
  }, [
    withdrawPermission,
    dispatch,
    onCloseWithdraw,
    rfp,
    toast,
    withdrew,
    setWithdrawPermission,
  ]);

  //when user agrees to continue with leave, execute the leave by checking state of leave permission
  useEffect(() => {
    if (leavePermission) {
      //update rfp and associated proposals
      supporterLeaveRfp({
        rfpId: rfp.id,
      })
        .then(() => {
          toast({
            position: "top",
            description: "Left request successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseLeave();
          setLeavePermission(false);
          navigate("/home");
        })
        .catch((error) =>
          toast({
            position: "top",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        );
    }
  }, [
    leavePermission,
    dispatch,
    onCloseLeave,
    rfp,
    toast,
    navigate,
    setLeavePermission,
  ]);

  return (
    <Flex
      direction={{
        default: "column",
        md: "row",
      }}
    >
      <Box
        w="100%"
        ml={{
          md: "5.5rem",
        }}
        px={{
          md: "3%",
        }}
      >
        <Card 
          deliveryDate={rfp?.delivery_date_end ? rfp?.delivery_date_end : rfp?.delivery_date_start}
          details={rfp.other_details}
          estimate={rfp.price_per_range_end * units}
          id={rfp.id}
          imageUrl={product?.image_url}
          imageName={product?.name}
          name={rfp.name}
          object={rfp}
          onLinkClick={addCurrentRfp(rfp)}
          onUpdateClick={""}
          price={price}
          product={product}
          productName={product.name}
          proposalCount={numProposals}
          statusColor={statusColor} 
          status={status}
          to={"/buyer/current-rfp"}
          total={total}
          units={units}
          type="request"
          user={user}
        />
      </Box>

      <ChatDrawer
        isOpen={isOpenChat}
        onClose={onCloseChat}
        title={"Chat with other buyers"}
        collectionName={"buyers_rfp_chat"}
        collectionDocId={rfp.id}
      />
      {canWithdraw && (
        <AlertPopup
          isOpen={isOpenWithdraw}
          onClose={onCloseWithdraw}
          alertHeader={"Withdraw Request"}
          alertBody={
            "Are you sure you want to withdraw this request? This is not reversible."
          }
          onSubmitAlert={setWithdrawPermission}
        />
      )}
      {canLeave && (
        <AlertPopup
          isOpen={isOpenLeave}
          onClose={onCloseLeave}
          alertHeader={"Leave Request"}
          alertBody={
            "Are you sure you want to leave this request? This is not reversible."
          }
          onSubmitAlert={setLeavePermission}
        />
      )}
      {isSharing && (
        <ShareRfpModal
          isOpen={isOpenShare}
          onClose={onCloseShare}
          product={product}
          rfp={rfp}
          setIsSharing={setIsSharing}
        />
      )}
    </Flex>
  );
};
