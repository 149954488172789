import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { LeadRFPActions } from "./leader/LeadRFPActions";
import { SupporterRFPActions } from "./supporter/SupporterRFPActions";
import { rfpStatusOptions } from "../../utilities/utilities";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { BuyerRFPInfoCard } from "./BuyerRFPInfoCard";

/*
Page displaying the RFP selected
Leader and Supporter have different pages, with different actions available
*/

export const BuyerCurrentRFP = () => {
  const user = useSelector(selectUser);
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;

  const product = useGetProduct(rfp);
  const statusColor = rfpStatusOptions[rfp.status][0] + ".500";
  const status = rfp.status;

  let isLead = false;

  //assign distinction if lead buyer or supporter
  if (user.uid === rfp.lead_buyer) {
    isLead = true;
  }

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "10.5rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Current Request
          </Heading>

          <Flex
            direction={{
              default: "column",
              md: "row",
            }}
            px={{
              default: "2",
              md: "0",
            }}
            py={{
              default: "2",
              md: "0",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
              borderBottom={{
                default: "4px solid",
                sm: "0",
              }}
              borderColor="gray.200"
              mb={{
                default: "1rem",
                sm: "0",
              }}
            >
              <BuyerRFPInfoCard
                product={product}
                statusColor={statusColor}
                status={status}
              />
            </Box>
            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 0,
                md: "1rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              <Heading
                as="h3"
                width="100%"
                mt={{
                  default: "8",
                  md: "0",
                }}
                mb={4}
                pb={0}
                fontSize="xl"
                textAlign="center"
              >
                Proposals
              </Heading>
              {isLead ? (
                <LeadRFPActions product={product} />
              ) : (
                <SupporterRFPActions product={product} />
              )}
            </Box>
          </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
