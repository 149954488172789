import { selectAppInfo } from "../store/features/appInfoSlice";
import { useSelector } from "react-redux";

export const useGetLeadBuyer = (rfp) => {
  const appInfo = useSelector(selectAppInfo);

  const lead_buyer = appInfo.LeadBuyersRfps?.filter(function (buyer) {
    return buyer.uid === rfp.lead_buyer;
  })[0];

  return lead_buyer;
};
