import { 
  Box,
  Flex, 
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { Card } from "../../../UI/Card"; 
import { addCurrentOrder } from "../../../store/features/sessionInfoSlice";
import { addIsUpdateProposal } from "../../../store/features/sessionInfoSlice";

export const OrderInfoCard = (props) => {
  const sessionInfo = useSelector(selectSessionInfo);
  const user = useSelector(selectUser);
  let order = sessionInfo.currentOrder;

  let product = props.product;

  const total = order.price_per * order.num_units;
  const price = order.price_per;
  const units = order.num_units;

  let numProposals = null;


  return (
    <Flex
      direction={{
        default: "column",
        md: "row",
      }}
    >
      <Box
        w="100%"
        ml={{
          md: "5.5rem",
        }}
        px={{
          md: "3%",
        }}
      >
        <Card 
            deliveryDate={order?.delivery_date_end ? order?.delivery_date_end : order?.delivery_date_start}
            details={order.other_details}
            estimate={order.price_per_range_end * units}
            id={order.id}
            imageUrl={product?.image_url}
            imageName={product?.name}
            name={order.name}
            object={order}
            onLinkClick={addCurrentOrder(order)}
            onUpdateClick={addIsUpdateProposal(true)}
            price={price}
            product={product}
            productName={product.name}
            proposalCount={numProposals}
            statusColor={props.statusColor} 
            status={props.status}
            to={"/buyer/current-order"}
            total={total}
            units={units}
            type="order"
            user={user}
          />
        </Box>
    </Flex>
  );
};
