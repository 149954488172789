import { Layout } from "../../UI/Layout";
import { PageLayout } from "../../UI/PageLayout";
import { selectAppInfo } from "../../store/features/appInfoSlice";
import {
  Box,
  Container,
  Flex,
  Image,
  Link,
  Skeleton,
  Heading,
  Stack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { captializeFirstLetter } from "../utilities/utilities";
import { selectSessionInfo } from "../../store/features/sessionInfoSlice";

export const StorefrontView = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  let vendor = sessionInfo.currentStorefront;

  let storedDescription = null;
  let storedImage = null;
  
  const appInfo = useSelector(selectAppInfo);
  const vendorProducts = [];

  if(vendor) {
    storedDescription = vendor["store_description"];
    storedImage = vendor["store_image"];
    vendor.products_offered.forEach((prod) => {
      const product = appInfo.productOptions.filter(function (po) {
        return po.id === prod;
      })[0];
      vendorProducts.push(captializeFirstLetter(product.name));
    });
    let allProducts = vendorProducts.join(", ");
    
    return (
      <Layout>
        <PageLayout>
          <Container 
            maxW="100%"
            pt={{
              md: 0,
            }}
            px={{
              md: 0,
            }}
          >
            <Heading
              variant="underline"
              ml={{
                md: "12rem",
              }}
              mb={{
                md: 6,
              }}
              pt={{
                md: 4,
              }}
            >
              <Text>Vendor Storefront</Text>
            </Heading>
            <Flex 
              direction={{
                default: "column",
                sm: "row",
              }}
              p={{
                default: "1rem",
                md: "0.5rem 2rem 2rem 13rem",
              }}
            >
              <Box>
                {storedImage && (
                  <Image
                    display="flex"
                    maxW={250}
                    src={storedImage}
                    alt={vendor.company_name}
                    draggable="false"
                    fallback={<Skeleton />}
                  />
                )}
              </Box>
              <Stack
                pt={{
                  default: 8,
                  md: 0,
                }}
                px={{
                  default: 0,
                  sm: 8,
                }}
              >
                <Text fontSize="md" fontWeight={"500"} mb={5}>
                  {vendor.company_name}
                </Text>
                {storedDescription && <Text>{storedDescription}</Text>}
                <Spacer />
                <Text>Products offered: {allProducts}</Text>
                <Spacer />
                <Box>
                  <Text fontSize="sm" fontWeight={"400"}>
                    {vendor.street}
                  </Text>
                  <Stack spacing="1" direction="row">
                    <Text fontSize="sm" fontWeight={"400"}>
                      {vendor.city},
                    </Text>
                    <Text fontSize="sm" fontWeight={"400"}>
                      {vendor.state}
                    </Text>
                    <Text fontSize="sm" fontWeight={"400"}>
                      {vendor.zipcode}
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Flex>
          </Container>
        </PageLayout>
      </Layout>
    );
  
  } else {
    return (
      <Layout>
        <PageLayout>
          <Container 
            maxW="100%"
            pt={{
              md: 0,
            }}
            px={{
              md: 0,
            }}
          >
            <Box>
              <Heading
                variant="underline"
                ml={{
                  md: "12rem",
                }}
                mb={{
                  md: 6,
                }}
                pt={{
                  md: 4,
                }}
              >
                <Text>Vendor Storefront</Text>
              </Heading>
            </Box>
            <Text fontSize="md" p={4}>
              There is no vendor stored. <Link href="/home">Please return to the home page.</Link>
            </Text>
          </Container>
        </PageLayout>
      </Layout>
    );
  }

};
