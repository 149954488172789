import { PaymentModal } from "./PaymentModal";
import { Box, Button, useDisclosure, Text, Icon } from "@chakra-ui/react";
import { useState, Fragment, useEffect } from "react";
import { getMyPaymentInfo } from "../../../store/firebase/FirebaseFunctions";
import { IoWalletOutline } from "react-icons/io5";
import { ProgressStat } from "../../../UI/ProgressStat";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector } from "react-redux";

export const PaymentActionComponent = (props) => {
  const user = useSelector(selectUser);

  const {
    isOpen: isOpenPayment,
    onOpen: onOpenPayment,
    onClose: onClosePayment,
  } = useDisclosure();

  let order = props.order;

  let progStatPaid = {
    type: "complete-payment",
    label: "Buyers paid",
    value: Number(order.num_payments_completed),
    limit: Number(order.num_payments_required),
  };
  const [hasPaid, setHasPaid] = useState(false);
  const [paymentData, setPaymentData] = useState();

  useEffect(() => {
    getMyPaymentInfo(order.id).then((docs) => {
      let payment_data = docs.data;
      setPaymentData(payment_data);
      //check to see if this buyer has already paid

      if (payment_data?.transactions?.buyer === user.uid) {
        setHasPaid(true);
      }
    });
  }, [order.id, user.uid]);

  return (
    <Fragment>
      <ProgressStat mt={4} {...progStatPaid} />
      {!hasPaid ? (
        <Box
          display="flex"
          alignItems="center"
          my={10}
          ml={8}
        >
          <Button variant="primary" onClick={onOpenPayment}>
            <Icon
              boxSize={5}
              as={IoWalletOutline}
              mr="0.5rem"
            />
            <Text as="span">
              Complete Payment
            </Text>
          </Button>
        </Box>
      ) : (
        <Text as="span">You have already completed payment</Text>
      )}
      <PaymentModal
        isOpen={isOpenPayment}
        onClose={onClosePayment}
        order_id={order.id}
        payment_id={paymentData?.payment_id}
        amount={paymentData?.per_charge}
        vendor_payee_id={paymentData?.vendor_payee_id}
        user_id={user.uid}
        setHasPaid={setHasPaid}
      />
    </Fragment>
  );
};
