import { Box, Heading, Text } from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { MenuGrid } from "../../platform/elements/MenuGrid";
import { CurrentOrderCard } from "../../platform/orders/CurrentOrderCard";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { selectUser } from "../../../store/features/userSlice";

export const VendorCurrentOrdersMenu = () => {
  const appInfo = useSelector(selectAppInfo);
  const user = useSelector(selectUser);

  //get orders that are current and being executed
  let currentOrders = appInfo.allOrders.filter(function (myo) {
    return myo.status === "current";
  });

  return (
    <Layout>
      <PageLayout>
        <Box 
          position="relative" 
          zIndex="1" 
          pl={{
            default: 0,
            md: "4rem"
          }}
          className="gridWrapper"
        >
          <Box>
            <Heading
              as="h2"
              variant="underline"
              display="flex"
              justifyContent="space-between"
              ml={{
                md: "8rem",
              }}
              mb={{
                md: 20,
              }}
            >
              <Text>Current Orders</Text>
            </Heading>
          </Box>
          {currentOrders.length === 0 ? (
            <Text p={10}>You do not have any current orders</Text>
          ) : (
            <MenuGrid>
              {currentOrders.map((order) => (
                <CurrentOrderCard key={order.id} order={order} user={user} />
              ))}
            </MenuGrid>
          )}
        </Box>
      </PageLayout>
    </Layout>
  );
};
