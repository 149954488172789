import {
  StackDivider,
  Stack,
  Text,
  Box,
  Heading,
  Container,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../store/features/appInfoSlice";
import { NotificationCard } from "./NotificationCard";
import { Layout } from "../../UI/Layout";
import { PageLayout } from "../../UI/PageLayout";

/* Notifications

order - when confirmed, or num delvieres updated
rfp - when buyer asks to join or joins, status changes, new proposal
proposal - when confirmed or rejected, 


Buyer
- Lead, when new proposal applied
- When order confirmed
- Lead, when new buyer asks to join or joins
- Lead, when order number of deliveries updated
- As supporter, RFP status changes

Vendor
- When proposal is confirmed or rejected
- When order number of deliveries expected
- When order is confirmed
 */

/*
show notifications from last 30 days with sections for today and earlier (before today)
notifiation page show notifications since last 30 days that have not been crossed out
if crossed out, don't show in last 5 notifications or last 30 days
*/

export const NotificationPage = () => {
  const appInfo = useSelector(selectAppInfo);

  let earlierNotifications = [];
  let todayNotifications = [];

  let openNotifications = appInfo.allOpenNotifications ?? [];
  // check to see if notifications are 24 hours old or older
  if (openNotifications) {
    const twentyFourHrInMs = 24 * 60 * 60 * 1000;
    const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;

    openNotifications.forEach((ntf) => {
      if (ntf.created_at > twentyFourHoursAgo) {
        todayNotifications.push(ntf);
      } else {
        earlierNotifications.push(ntf);
      }
    });
  }

  const loadNotifications = (ntfs, earlier) => {
    return (
      <Stack 
        spacing="5"
        maxW="50rem"
        pl={{
          default: "4",
          md: 4,
        }}
        pr={{
          default: "4",
          md: 4,
        }}
        py={{
          default: "5",
          md: "0",
        }}

      >
        <Heading size="md" mb={4}>
          {earlier ? "Earlier" : "Today"}
        </Heading>
        {ntfs.length > 0 ? (
          <Stack direction="column" divider={<StackDivider />}>
            {ntfs.map((notification, index) => (
              <NotificationCard
                key={index}
                notification={notification}
                earlier={earlier}
              />
            ))}
          </Stack>
        ) : (
          <Text fontSize="sm" pb={8}>
            No notifications
          </Text>
        )}
      </Stack>
    );
  };

  return (
    <Layout>
      <PageLayout>
        <Container maxW="100%" p={0}>
          <Box 
            position="relative" 
            zIndex="1" 
            pt={{
              default: 0,
              md: "0.5rem",
            }} 
            pl={{
              default: 0,
              md: 44,
            }} 
            pr={{
              default: 0,
              md: 0,
            }}
          >
            <Heading
              as="h2"
              variant="underline"
              pos="relative"
              top={{
                default: "0",
                md: "-0.5rem",
              }}
              pt={0}
              mt={0}
            >
              Notifications
            </Heading>

            {loadNotifications(todayNotifications, false)}
            {loadNotifications(earlierNotifications, true)}
          </Box>
        </Container>
      </PageLayout>
    </Layout>
  );
};
