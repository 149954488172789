import {
  Box,
  Container,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { selectUser } from "../../../store/features/userSlice";
import { useEffect, useState } from "react";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { OrderInfoCard } from "../../platform/orders/OrderInfoCard";
import { Fragment } from "react";
import { ProgressStat } from "../../../UI/ProgressStat";
import { useNavigate } from "react-router-dom";
import { ChatDrawer } from "../../chat/ChatDrawer";
import { PaymentActionComponent } from "../../platform/payments/PaymentActionComponent";
import { orderStatusOptions } from "../../utilities/utilities";

export const BuyerCurrentOrder = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  const order = sessionInfo.currentOrder;
  const user = useSelector(selectUser);
  const product = useGetProduct(order);
  const statusColor = orderStatusOptions[order.status][0] + ".500";
  const status = order.status;

  const navigate = useNavigate();

  const [isPaying, setIsPaying] = useState();

  useEffect(() => {
    //check to see if payments still need to be completed
    if (order.status === "paying") {
      setIsPaying(true);
    } else if (order.status === "current") {
      setIsPaying(false);
    }
  }, [order.status, setIsPaying]);

  const [numReceived, setnumReceived] = useState(order.num_units_received ?? 0);
  const numDelivered = order.num_units_delivered ?? 0;
  const total_units = order.num_units;

  let progStatDelivered = {
    label: "Units delivered",
    value: Number(numDelivered),
    limit: Number(total_units),
  };

  let progStatReceived = {
    label: "Units received",
    value: Number(numReceived),
    limit: Number(total_units),
  };

  useEffect(() => {
    //check to see if order is completed
    if (total_units === numReceived && total_units === numDelivered) {
      navigate("/buyer/completed-order");
    }
  }, [total_units, numDelivered, numReceived, navigate]);

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "10.5rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Current Order
          </Heading>
          
          <Flex
            direction={{
              default: "column",
              md: "row",
            }}
            px={{
              default: "2",
              md: "0",
            }}
            py={{
              default: "2",
              md: "0",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
              borderBottom={{
                default: "4px solid",
                sm: "0",
              }}
              borderColor="gray.200"
              mb={{
                default: "1rem",
                sm: "0",
              }}
            >
              <OrderInfoCard 
                product={product}
                statusColor={statusColor}
                status={status} 
              />
            </Box>
            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 0,
                md: "1rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              {!isPaying ? (
                <Fragment>
                  <ProgressStat mt={4} {...progStatDelivered} />
                  <ProgressStat mt={4} {...progStatReceived} />
                </Fragment>
              ) : (
                <PaymentActionComponent order={order} />
              )}
            </Box>
          </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
