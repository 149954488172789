import { Flex, Text, VStack } from "@chakra-ui/react";

export const ChatMessage = (props) => {
  function getMinutesSince() {
    //account for firebase pending timestamp write for new msg
    if (!props.message.created_at) {
      return "Now";
    }

    //get time stamp to display on msg
    //if msg is more than 30 mins then show time or day if greater than 1 day

    let timeNow = new Date();

    let timeMsg = props.message.created_at.toDate();

    let delta = timeNow - timeMsg;
    let minutes = Math.floor(delta / 1000 / 60);

    if (minutes > 30) {
      //if more than a day then return days ago
      if (minutes > 1440) {
        let days = Math.floor(minutes / 1440);
        return days + "d ago";
      } else {
        return timeMsg.getHours() + ":" + timeMsg.getMinutes();
      }
    } else {
      if (minutes < 2) {
        return "Now";
      } else {
        return minutes + "m ago";
      }
    }
  }

  let checkIfAuthor = false;
  if (props.uid === props.message.uid) {
    checkIfAuthor = true;
  }

  // function userColor() {
  //   //use uid of author to generate random color
  //   let firstChar = props.uid.charAt(0);
  //   //if number then return orange
  //   if (!isNaN(firstChar)) {
  //     return "orange.400";
  //   }
  //   // if letter then return purple if between a-m
  //   else if (firstChar.match(/[^a-m]/)) {
  //     return "purple.500";
  //   } else {
  //     return "red.500";
  //   }
  // }

  return (
    <Flex w="100%" justify={checkIfAuthor ? "flex-start" : "flex-end"}>
      <Flex
        position="relative"
        w="98%"
        bg={checkIfAuthor ? "gray.100" : "blue.200"}
        color={checkIfAuthor ? "black" : "white"}
        border="0"
        borderRadius="lg"
        my={2}
        p={2}
        fontSize="0.9rem"
        _before={{
          position: "absolute",
          bottom: "-10px",
          left: checkIfAuthor ? "10px" : "auto",
          right: checkIfAuthor ? "auto" : "10px",
          content: '""',
          width: "0",
          height: "0",
          borderLeft: checkIfAuthor ? "0px solid transparent" : "12px solid transparent",
          borderRight: checkIfAuthor ? "12px solid transparent" : "0px solid transparent",
          borderTop: checkIfAuthor
            ? "10px solid #f2f2f2"
            : "10px solid #00627c",
        }}
      >
        <VStack align="left" w="100%">
          <Text>{props.message.text}</Text>
          <Text
            align="right"
            fontSize="0.75rem"
            color={checkIfAuthor ? "gray.400" : "white"}
          >
            {!props.matchPrevious && !checkIfAuthor ? (
              <Text as="span" size="sm">
                {props.message.username}
              </Text>
            ) : (
              <Text as="span" size="sm">
                You
              </Text>
            )}
            <Text as="span"> • {getMinutesSince()}</Text>
          </Text>
        </VStack>
      </Flex>
    </Flex>
  );
};
