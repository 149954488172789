import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Stack,
  IconButton,
  Text,
  useDisclosure,
  Box,
  Icon,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { items as BuyerItems } from "./BuyerMenuData";
import { items as VendorItems } from "./VendorMenuData";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";

/*
For Menu on top left of page. 
Load menu items depending on if user is buyer or vendor
*/

export const NavMenu = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(selectUser);
  let menuItems;

  if (user.account_type === "buyer") {
    menuItems = BuyerItems;
  }
  if (user.account_type === "vendor") {
    menuItems = VendorItems;
  }

  function transitionMenuShape() {
    const navShape = document.getElementById("Main_Menu_Clip_Background");
  }

  return (
    <Fragment>
      <Box 
        textAlign={{
          md: "center",
        }}
      >
        <Menu>
          <MenuButton
            onClick={onOpen}
            as={IconButton}
            p={{
              default: 2,
              md: 0,
            }}
            background="transparent"
            aria-label="Open Menu"
            icon={<FiMenu />}
            fontSize={{
              default: "1.5rem",
              md: "2.3rem"
            }}
            color={{
              default: "gray.500",
              md: "yellow.500",
            }}
            _hover={{
              background: "blue.200",
              border: "blue.200",
            }}
          />

          <MenuList>
            <Stack>
              {menuItems.map((item, id) => (
                <MenuItem key={id}>
                  <Link variant="menu" href={item.href}>
                    <Stack spacing="4" direction="row" p="1">
                      <Icon as={item.icon} boxSize="6" color="accent" />
                      <Stack spacing="1">
                        <Text fontWeight="medium">{item.title}</Text>
                        <Text fontSize="xsm" color="gray.600">
                          {item.description}
                        </Text>
                      </Stack>
                    </Stack>
                  </Link>
                </MenuItem>
              ))}
            </Stack>
          </MenuList>
        </Menu>
      </Box>
    </Fragment>
  );
};
