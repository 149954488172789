import { selectAppInfo } from "../store/features/appInfoSlice";
import { useSelector } from "react-redux";

export const useGetRFP = (item) => {
  const appInfo = useSelector(selectAppInfo);

  const rfp = appInfo.allRfps?.filter(function (rfp) {
    return rfp.id === item.rfp_id;
  })[0];

  return rfp;
};
