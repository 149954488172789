import {
  ButtonGroup,
  Icon,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FiCheck,
  FiDollarSign,
  FiEdit3,
  FiEye,
  FiLogIn,
  FiMessageCircle,
  FiShare2,
} from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  addUpdateRfpObject,
  addCurrentRfp,
  addCreateNewRfp,
} from "../../../store/features/sessionInfoSlice";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import { Fragment } from "react";
import { ChatDrawer } from "../../chat/ChatDrawer";
import { AlertPopup } from "../../platform/elements/AlertPopup";
import { ShareRfpModal } from "../../buyer/rfp/ShareRfpModal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  withdrawRfp,
  supporterLeaveRfp,
  buyerRfpActions,
} from "../../../store/firebase/FirebaseFunctions";

/*
buyer: share, msg buyers (if leader or supporter))
vendor: submit proposal
*/

export const RFPCardButtons = (props) => {
  let account_type = props.account_type;
  let rfp = props.rfp;
  let product = props.product;
  let followOn = `/${account_type}/current-rfp`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getRoute = useLocation();
  const location = getRoute.pathname;
  const toast = useToast();
  const [asked, setAsked] = useState(false);

  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useDisclosure();

  const {
    isOpen: isOpenLeave,
    onOpen: onOpenLeave,
    onClose: onCloseLeave,
  } = useDisclosure();

  const [isLead, setIsLead] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState();
  const [canLeave, setCanLeave] = useState();
  const [leavePermission, setLeavePermission] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [withdrew, setWithdrew] = useState(false);
  const [withdrawPermission, setWithdrawPermission] = useState(false);

  const askToJoin = () => {
    buyerRfpActions({
      rfp_id: rfp.id,
      action: "askToJoin",
      buyer_candidate: props.user.uid,
    })
      .then(() => {
        //add buyer to supporter buyer ask list to session and storage rfp objects
        let buyersAsked = rfp.buyers_asked ?? [];
        let newBuyersAsked = [...buyersAsked];
        newBuyersAsked.push(props.user.uid);
        //create new rfp object
        const newRfp = { ...rfp };
        newRfp.buyers_asked = newBuyersAsked;
        dispatch(addCurrentRfp(newRfp));
        setAsked(true);
        toast({
          position: "top",
          description: "Successfully asked to join!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    //check to see if lead buyer
    if (props.user.uid === rfp.lead_buyer) {
      setIsLead(true);
    }
  }, [props.user, rfp]);

  //only allow withdrawing or leaving if rfp is open
  useEffect(() => {
    if (rfp.status === "open") {
      if (isLead) {
        setCanWithdraw(true);
      }
      //can leave rfp if supporter, or in buyers asked or invited
      else if (
        rfp.supporters.includes(props.user.uid) ||
        rfp.buyers_asked.includes(props.user.uid) ||
        rfp.buyers_invited.includes(props.user.uid)
      ) {
        setCanLeave(true);
      }
    }
  }, [rfp, setCanWithdraw, isLead, setCanLeave, props.user.uid]);

  //when user agrees to continue with withdraw, execute the withdraw by checking state of withdraw permission
  useEffect(() => {
    if (withdrawPermission && !withdrew) {
      //update rfp and associated proposals
      withdrawRfp({
        rfpId: rfp.id,
      })
        .then(() => {
          //update and dispatch to current rfp
          let newRfp = { ...rfp };
          newRfp["status"] = "withdrawn";
          dispatch(addCurrentRfp(newRfp));

          toast({
            position: "top",
            description: "Request withdrawn!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setWithdrew(true);
          setWithdrawPermission(false);
          onCloseWithdraw();
        })
        .catch((error) =>
          toast({
            position: "top",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        );
    }
  }, [
    withdrawPermission,
    dispatch,
    onCloseWithdraw,
    rfp,
    toast,
    withdrew,
    setWithdrawPermission,
  ]);

  //when user agrees to continue with leave, execute the leave by checking state of leave permission
  useEffect(() => {
    if (leavePermission) {
      //update rfp and associated proposals
      supporterLeaveRfp({
        rfpId: rfp.id,
      })
        .then(() => {
          toast({
            position: "top",
            description: "Left request successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseLeave();
          setLeavePermission(false);
          navigate("/home");
        })
        .catch((error) =>
          toast({
            position: "top",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        );
    }
  }, [
    leavePermission,
    dispatch,
    onCloseLeave,
    rfp,
    toast,
    navigate,
    setLeavePermission,
  ]);

  return (
    <ButtonGroup
      w="100%"
      justifyContent="space-around"
      my={4}
      pr={{
        default: "20px",
        md: "20px",
      }}
    >
      {account_type === "buyer" && (
        <Fragment>
          {isLead && (
            <Link
              variant="simpleIconLinkStacked"
              onClick={() => {
                dispatch(addUpdateRfpObject(rfp));
                dispatch(addCreateNewRfp(false));
                navigate(`/buyer/create-rfp`);
              }}
            >
              <Icon boxSize={5} as={FiEdit3} />
              <Text as="span">Update</Text>
            </Link>
          )}
          {canWithdraw && (
            <Link
              variant="simpleIconLinkStacked"
              onClick={() => {
                onOpenWithdraw();
              }}
            >
              <Icon boxSize={5} as={FiLogIn} />
              <Text fontSize="xsm" as="span">
                Withdraw
              </Text>
            </Link>
          )}
          {canLeave && (
            <Link
              variant="simpleIconLinkStacked"
              onClick={() => {
                onOpenLeave();
              }}
            >
              <Icon boxSize={5} as={FiLogIn} />
              <Text as="span">Leave</Text>
            </Link>
          )}
          {props.memberRfp ? (
            <Link
              variant="simpleIconLinkStacked"
              onClick={() => {
                setIsSharing(true);
                onOpenShare();
              }}
            >
              <Icon boxSize={5} as={FiShare2} />
              <Text as="span">Invite</Text>
            </Link>
          ) : asked ? (
            <Stack
              alignItems="center"
              padding={{
                default: "0.15rem",
                md: "0.5rem",
              }}
              fontSize={{
                default: "0.75rem",
                md: "0.85rem",
              }}
              textTransform="uppercase"
            >
              <Icon boxSize={5} as={FiCheck} />
              <Text>Request Pending</Text>
            </Stack>
          ) : (
            location !== "/buyer/current-rfp" && (
              <Link variant="simpleIconLinkStacked" onClick={askToJoin}>
                <Icon boxSize={5} as={FiShare2} />
                <Text as="span">Request to Join</Text>
              </Link>
            )
          )}

          {props.memberRfp && (
            <Link variant="simpleIconLinkStacked" onClick={onOpenChat}>
              <Icon boxSize={5} as={FiMessageCircle} />
              <Text as="span">Message</Text>
            </Link>
          )}

          {props.proposalCount > 0 && location !== "/buyer/current-rfp" && (
            <Link
              key={rfp.id}
              onClick={() => {
                dispatch(addCurrentRfp(rfp));
                navigate(followOn);
              }}
              variant="simpleIconLinkStacked"
            >
              <Icon boxSize={5} as={FiEye} />
              <Text as="span">
                {props.proposalCount > 1
                  ? "View " + props.proposalCount + " Proposals"
                  : "View Proposal"}
              </Text>
            </Link>
          )}
        </Fragment>
      )}

      {account_type === "vendor" && (
        <Fragment>
          <Link
            as={RouteLink}
            key={rfp.id}
            to={followOn}
            onClick={() => dispatch(addCurrentRfp(rfp))}
            // onClick={() => {
            //   dispatch(addIsUpdateProposal(false));
            //   navigate("/vendor/create-proposal");
            // }}
            variant="simpleIconLinkStacked"
          >
            <Icon boxSize={5} as={FiDollarSign} mr="0.5rem" />
            <Text as="span">Submit Proposal</Text>
          </Link>
        </Fragment>
      )}
      <ChatDrawer
        isOpen={isOpenChat}
        onClose={onCloseChat}
        title={"Chat with other buyers"}
        collectionName={"buyers_rfp_chat"}
        collectionDocId={rfp.id}
      />
      {isSharing && (
        <ShareRfpModal
          isOpen={isOpenShare}
          onClose={onCloseShare}
          product={product}
          rfp={rfp}
          setIsSharing={setIsSharing}
        />
      )}
      {canWithdraw && (
        <AlertPopup
          isOpen={isOpenWithdraw}
          onClose={onCloseWithdraw}
          alertHeader={"Withdraw Request"}
          alertBody={
            "Are you sure you want to withdraw this request? This is not reversible."
          }
          onSubmitAlert={setWithdrawPermission}
        />
      )}
      {canLeave && (
        <AlertPopup
          isOpen={isOpenLeave}
          onClose={onCloseLeave}
          alertHeader={"Leave Request"}
          alertBody={
            "Are you sure you want to leave this request? This is not reversible."
          }
          onSubmitAlert={setLeavePermission}
        />
      )}
      {isSharing && (
        <ShareRfpModal
          isOpen={isOpenShare}
          onClose={onCloseShare}
          product={product}
          rfp={rfp}
          setIsSharing={setIsSharing}
        />
      )}
    </ButtonGroup>
  );
};
