import {
  getFunctions,
  httpsCallable,
  //connectFunctionsEmulator,
} from "firebase/functions";
import { getApp } from "firebase/app";

const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5002);

export const getProducts = httpsCallable(functions, "getProducts");
export const getMyRfps = httpsCallable(functions, "getMyRfps");
export const getMyOrders = httpsCallable(functions, "getMyOrders");
export const getMyProposals = httpsCallable(functions, "getMyProposals");
export const getMyUserInfo = httpsCallable(functions, "getMyUserInfo");
export const getVendorsProposed = httpsCallable(
  functions,
  "getVendorsProposed"
);
export const getBuyersAsked = httpsCallable(functions, "getBuyersAsked");
export const inviteBuyerToRfp = httpsCallable(functions, "inviteBuyerToRfp");

export const supporterLeaveRfp = httpsCallable(functions, "supporterLeaveRfp");

export const setMyUserProfile = httpsCallable(functions, "setMyUserProfile");
export const addNewRfp = httpsCallable(functions, "addNewRfp");
export const updateRfp = httpsCallable(functions, "updateRfp");
export const addNewProposal = httpsCallable(functions, "addNewProposal");
export const updateProposal = httpsCallable(functions, "updateProposal");
export const updateProposalStatus = httpsCallable(
  functions,
  "updateProposalStatus"
);
export const updateOrderDeliveries = httpsCallable(
  functions,
  "updateOrderDeliveries"
);

export const getMyGivenRatings = httpsCallable(functions, "getMyGivenRatings");

export const buyerRfpActions = httpsCallable(functions, "buyerRfpActions");
export const addUserRating = httpsCallable(functions, "addUserRating");
export const checkUsernameExists = httpsCallable(
  functions,
  "checkUsernameExists"
);
export const completeRegistration = httpsCallable(
  functions,
  "completeRegistration"
);

export const createUser = httpsCallable(functions, "createUser");
export const withdrawRfp = httpsCallable(functions, "withdrawRfp");
export const sendEmail = httpsCallable(functions, "sendEmail");
export const getMyOpenNotifications = httpsCallable(
  functions,
  "getMyOpenNotifications"
);

export const closeNotification = httpsCallable(functions, "closeNotification");
export const getLeadBuyersRFPs = httpsCallable(functions, "getLeadBuyersRFPs");
export const submitProduct = httpsCallable(functions, "submitProduct");
export const getMyPaymentInfo = httpsCallable(functions, "getMyPaymentInfo");
export const updateOrderPaymentTransaction = httpsCallable(
  functions,
  "updateOrderPaymentTransaction"
);
