import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
  chakra,
  FormErrorMessage,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Footer } from "../../UI/Footer";
import { LoginFull } from "../../UI/Icons/LoginFull";
import { Fragment, useEffect } from "react";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { auth } from "../../store/firebase/Firebase";

export const ForgotPassword = () => {
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  let actionCodeSettings = { url: "https://buyer-demo.web.app/login" };

  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //handle error
  useEffect(() => {
    if (error) {
      toast({
        position: "top",
        description: "Email not found",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const onSubmit = async (data) => {
    // your login logic here

    const success = await sendPasswordResetEmail(
      data.email,
      actionCodeSettings
    );
    if (success) {
      toast({
        position: "top",
        description: `Check your email and spam folder to reset your password.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Fragment>
      <Box 
        as="section" 
        flex="1 1 auto"
        maxW="1800px"
        m="0 auto"
        pb={50}
        bg="white"
      >
        <Container
          maxW="100%"
          px="0"
          py={{
            default: "0",
            md: "0",
          }}
        >
          <Container
            maxW="100%"
            centerContent
            borderTop="0.75rem solid"
            borderColor="blue.300"
            overflow="hidden"
            p={0}
          >
            <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
              <LoginFull pos="relative" top="-1px" w="175%" />
            </Link>
          </Container>
          <Container 
            maxW="40rem"
            p={{
              default: 4,
              sm: 20,
            }} 
            pt={{
              sm: 10,
            }}
          >
            <Stack spacing="8">
              <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="6">
                  <Stack spacing="4" pt="4">
                    <FormControl id="email" isInvalid={errors.email} isRequired>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        type="text"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <Button variant="primary" isLoading={sending} type="submit">
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </chakra.form>
            </Stack>
          </Container>
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
};
