import { 
  Box, 
  Container, 
  Link, 
  Stack, 
  Text 
} from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box 
      as="footer"
      flex="0 0 auto"
      maxW="1800px"
      m="0 auto"
      bg="white"
      borderBottom="5px solid"
      borderColor="gray.500"
      >
      <Container
        centerContent
        role="contentinfo"
        py={{
          default: "12",
          md: "16",
        }}
      >
        <Stack
          spacing={{
            default: "4",
            md: "5",
          }}
        >
          <Text>
            <Link href="/privacy-policy" mx={2}>Privacy Policy</Link><Link href="/toc" mx={2}>Terms and Conditions</Link></Text>
          <Text fontSize="sm" color="on-accent-subtle">
            &copy; {new Date().getFullYear()} YeeHive, Inc. All rights reserved.
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};
