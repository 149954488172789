import {
  Box,
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spacer,
  Stack,
  useToast,
  FormErrorMessage,
  FormHelperText,
  Container,
} from "@chakra-ui/react";
import { Footer } from "../../UI/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../store/auth-context";
import { useForm } from "react-hook-form";
import { LoginFull } from "../../UI/Icons/LoginFull";
import { Fragment } from "react";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPassword = () => {
  const { resetPassword } = useAuth();
  const query = useQuery();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    try {
      await resetPassword(query.get("oobCode"), data.password);
      toast({
        position: "top",
        description: "Password has been reset. You can login now.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        position: "top",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Fragment>
      <Box 
        as="section" 
        flex="1 1 auto"
        maxW="1800px"
        m="0 auto"
        pb={50}
        bg="white"
      >
        <Container
          maxW="100%"
          px="0"
          py={{
            default: "0",
            md: "0",
          }}
        >
          <Container
            maxW="100%"
            centerContent
            borderTop="0.75rem solid"
            borderColor="blue.300"
            overflow="hidden"
            p={0}
          >
            <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
              <LoginFull pos="relative" top="-1px" w="175%" />
            </Link>
          </Container>
          <Container 
            maxW="40rem"
            p={{
              default: 4,
              sm: 20,
            }} 
            pt={{
              sm: 10,
            }}
          >
            <Stack spacing="8">
              <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="6">
                  <Stack spacing="4" pt="4">
                    <FormControl
                      id="password"
                      isInvalid={errors.password}
                      isRequired
                    >
                      <FormLabel>New password</FormLabel>
                      <Input
                        type="text"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Must be at least 8 characters long",
                          },
                          pattern: {
                            value:
                              /^(?=(.*[A-Za-z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
                            message:
                              "Password should contain at least 1 letter, 1 symbol, and 1 number",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.password && errors.password?.message}
                      </FormErrorMessage>
                      <FormHelperText>
                        Password must be at least 8 characters long
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      id="password_repeat"
                      isInvalid={errors.password_repeat}
                      isRequired
                    >
                      <FormLabel>Repeat Password</FormLabel>
                      <Input
                        type="text"
                        {...register("password_repeat", {
                          required: "Password is required",
                          validate: (value) =>
                            value === watch("password") ||
                            "The passwords must match",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.password_repeat && errors.password_repeat?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <Spacer />
                    <Button
                      variant="primary"
                      isLoading={isSubmitting}
                      type="submit"
                      m="2rem 3rem"
                      mt={6}
                    >
                      Reset Password
                    </Button>
                  </Stack>
                </Stack>
              </chakra.form>
            </Stack>
          </Container>
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
};
