import {
  FiHome,
  FiFileText,
  FiShoppingCart,
  FiArchive,
  FiSend,
} from "react-icons/fi";

export const items = [
  {
    title: "Home",
    description: "",
    href: "/home",
    icon: FiHome,
  },
  // {
  //   title: "Profile",
  //   description: "Edit your profile and settings",
  //   href: "/profile",
  //   icon: FiUser,
  // },
  {
    title: "Create Proposal",
    description: "Respond to RFPs with a proposal",
    href: "/vendor/create-proposal",
    icon: FiFileText,
  },
  {
    title: "Current Orders",
    description: "Track progress of orders being fulfilled",
    href: "/vendor/current-orders",
    icon: FiShoppingCart,
  },
  {
    title: "Completed Orders",
    description: "See archive of past orders",
    href: "/vendor/past-orders",
    icon: FiArchive,
  },
  {
    title: "Storefront",
    description: "Advertise your company & products",
    href: "/vendor/storefront",
    icon: FiSend,
  },
  // {
  //   title: "Settings",
  //   description: "Change your app settings",
  //   href: "/settings",
  //   icon: FiSettings,
  // },
];
