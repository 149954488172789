import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
} from "@chakra-ui/react";
import { ChatBox } from "./ChatBox";

export const ChatDrawer = (props) => {
  let isOpen = props.isOpen;
  let onClose = props.onClose;
  let title = props.title;
  let collectionName = props.collectionName;
  let collectionDocId = props.collectionDocId;

  return (
    <Drawer isOpen={isOpen} placement="right" size={"md"} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="lg">{title}</DrawerHeader>

        <DrawerBody>
          <ChatBox
            collectionDocId={collectionDocId}
            collectionName={collectionName}
          />
        </DrawerBody>

        <DrawerFooter>
          {/* Seems redundant */}
          {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
