import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  chakra,
  useToast,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Radio,
  RadioGroup,
  Select as ChakraSelect,
  Spacer,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import {
  addNewRfp,
  updateRfp,
  submitProduct,
  getProducts,
} from "../../../store/firebase/FirebaseFunctions";
import { UsaStateOptions } from "../../platform/profiles/ProfileData";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSessionInfo,
  addCurrentRfp,
  addRecreateRfpObject,
  addUpdateRfpObject,
} from "../../../store/features/sessionInfoSlice";
import { useState, useEffect, Fragment } from "react";
import { addProductOptions } from "../../../store/features/appInfoSlice";

export const CreateRFP = () => {
  const toast = useToast();
  const sessionInfo = useSelector(selectSessionInfo);
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const navigate = useNavigate();
  let today = new Date().toISOString().split("T")[0];
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const productType = watch("product_type", false);

  //fill with rfp from old recreated or updated rfp
  useEffect(() => {
    //pre-populate form
    //first check if updating rfp then check if recreating rfp
    if (sessionInfo.createNewRfp !== true) {
      let oldRfp;
      if (
        sessionInfo.updateRfpObject !== null &&
        sessionInfo.updateRfpObject !== undefined
      ) {
        oldRfp = sessionInfo.updateRfpObject;
        setIsUpdating(true);
      } else if (
        sessionInfo.recreateRfpObject !== null &&
        sessionInfo.recreateRfpObject !== undefined
      ) {
        oldRfp = sessionInfo.recreateRfpObject;
      }
      if (oldRfp !== null && oldRfp !== undefined) {
        setValue("name", oldRfp["name"]);
        setValue("product", oldRfp["product"]);
        setValue("product_name", oldRfp["product"]["name"]);
        setValue("num_units", oldRfp["num_units"]);
        setValue("city", oldRfp["city"]);
        setValue("state", oldRfp["state"]);
        setValue("zipcode", oldRfp["zipcode"]);
        setValue("price_per_range_start", oldRfp["price_per_range_start"]);
        setValue("price_per_range_end", oldRfp["price_per_range_end"]);
        if (isUpdating) {
          let start = new Date(oldRfp["delivery_date_start"]);

          let end = new Date(oldRfp["delivery_date_end"]);

          setValue(
            "delivery_date_start",
            start.toLocaleDateString("en-ZA").replace(/\//g, "-")
          );
          setValue(
            "delivery_date_end",
            end.toLocaleDateString("en-ZA").replace(/\//g, "-")
          );
        }
      }
    }
  }, [
    setValue,
    sessionInfo.recreateRfpObject,
    sessionInfo.updateRfpObject,
    sessionInfo.createNewRfp,
    setIsUpdating,
    isUpdating,
  ]);

  const onSubmit = async (data) => {
    // Add a new document with a generated id.
    setIsCreating(true);

    if (!isUpdating) {
      //if not updating, then create new proposal doc and add to rfp
      submitProduct(data["product_name"])
        .then((productId) => {
          data["product"] = productId.data;
          addNewRfp(data)
            .then((resultRfp) => {
              toast({
                position: "top",
                description: "RFP created and submitted!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              //refetch product options
              getProducts().then((docs) => {
                dispatch(addProductOptions(docs.data));
                dispatch(addRecreateRfpObject(null));
                dispatch(addCurrentRfp(resultRfp.data));
                navigate(`/buyer/current-rfp`);
              });
            })
            .catch(() => {
              setIsCreating(false);
              toast({
                position: "top",
                description: "Something went wrong. Please try again later",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        })
        .catch(() => {
          setIsCreating(false);
          toast({
            position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
    //if updating, then overwrite existing rfp
    else {
      let functionData = {
        rfpId: sessionInfo.updateRfpObject.id,
        rfpData: data,
      };
      updateRfp(functionData)
        .then((resultRfp) => {
          toast({
            position: "top",
            description: "Request updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch(addUpdateRfpObject(null));
          dispatch(addCurrentRfp(resultRfp.data));
          navigate("/buyer/current-rfp");
        })
        .catch(() => {
          setIsCreating(false);
          toast({
            position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const [priceRangeSelected, setpriceRangeSelected] = useState(false);
  const [dateRangeSelected, setdateRangeSelected] = useState(false);

  

  return (
    <Layout>
      <PageLayout>
        <Container maxW="100%" p={0}>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing="5"
              pt={{
                default: 0,
                md: "0.5rem",
              }}
              pl={{
                default: 0,
                md: 44,
              }}
              pr={{
                default: 0,
                md: 0,
              }}
            >
              <Heading
                as="h2"
                variant="underline"
                pos="relative"
                top={{
                  default: "0",
                  md: "-0.5rem",
                }}
                pt={0}
                mt={0}
              >
                {isUpdating ? "Update Request" : "Create Request"}
              </Heading>
              <Box>
                <Stack
                  spacing="5"
                  pl={{
                    default: "4",
                    md: 4,
                  }}
                  pr={{
                    default: "4",
                    md: 4,
                  }}
                  py={{
                    default: "5",
                    md: "0",
                  }}
                >
                  <FormControl id="name" isRequired>
                    <FormLabel>What do you need?</FormLabel>
                    {isUpdating ? (
                      <Input isDisabled type="text" {...register("name")} />
                    ) : (
                      <Input type="text" {...register("name")} />
                    )}
                  </FormControl>

                  <FormControl id="buyers_limit">
                    <FormLabel>Who can join?</FormLabel>
                    <ChakraSelect
                      {...register("buyers_limit")}
                      placeholder="Select"
                      size="md"
                      defaultValue={"ask"}
                    >
                      <option value={"ask"}>Anyone can ask to join</option>
                      <option value={"invite"}>By invitation only</option>
                      <option value={"zipcode"}>
                        Open to anyone in the same zipcode
                      </option>
                    </ChakraSelect>
                  </FormControl>

                  <Heading as="h3" size="sm" pt="2rem">
                    Where are you located?
                  </Heading>

                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="city" isRequired>
                      <FormLabel>City</FormLabel>
                      <Input type="text" {...register("city")} />
                    </FormControl>
                    <FormControl isRequired id="state">
                      <FormLabel>State / Province</FormLabel>

                      <ChakraSelect {...register("state")}>
                        {Object.entries(UsaStateOptions).map(([key, value]) => (
                          <option key={key}>{value}</option>
                        ))}
                      </ChakraSelect>
                    </FormControl>
                    <FormControl id="zipcode" isRequired>
                      <FormLabel>ZIP Code</FormLabel>
                      <Input
                        type="number"
                        {...register("zipcode", {
                          minLength: {
                            value: 5,
                            message: "Must be at least 5 digits long",
                          },
                        })}
                      />
                      {errors.zipcode && errors.zipcode?.message}
                    </FormControl>
                  </Stack>

                  <Flex pt={8}>
                    <Heading as="h3" size="sm" flex="0 0 300px" pt="0.1rem">
                      Do you need a Product or Service?
                    </Heading>
                    <FormControl isRequired id="product_type" px={8}>
                      <RadioGroup size="lg">
                        <Stack spacing={5} direction="row">
                          <Radio
                            {...register("product_type")}
                            value="Product"
                            colorScheme="blue"
                          >
                            Product
                          </Radio>
                          <Radio
                            {...register("product_type")}
                            value="Service"
                            colorScheme="blue"
                          >
                            Service
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Flex>

                  {productType && (
                    <Fragment>
                      <Stack
                        spacing="6"
                        direction={{
                          default: "column",
                          md: "row",
                        }}
                      >
                        <FormControl isRequired id="product_name">
                          <FormLabel>{productType} Category</FormLabel>
                          {isUpdating ? (
                            <Input
                              isDisabled
                              type="text"
                              {...register("product_name")}
                            />
                          ) : (
                            <Input
                              type="text"
                              {...register("product_name", {
                                minLength: {
                                  value: 3,
                                  message: "Must be at least 3 characters long",
                                },
                                maxLength: {
                                  value: 100,
                                  message:
                                    "Must be less than 25 characters long",
                                },
                              })}
                            />
                          )}

                          {errors.product_name && errors.product_name?.message}
                        </FormControl>
                        {productType === "Product" ? (
                          <FormControl
                            id="num_units"
                            isRequired
                            isInvalid={errors.num_units}
                          >
                            <FormLabel>Number of Units</FormLabel>

                            <NumberInput min={0}>
                              <NumberInputField
                                id="num_units"
                                {...register("num_units", {
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Amount must be greater than 0",
                                })}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <FormErrorMessage>
                              {errors.num_units && errors.num_units?.message}
                            </FormErrorMessage>
                          </FormControl>
                        ) : (
                          <FormControl id="num_units" hidden>
                            <NumberInput value={1}>
                              <NumberInputField
                                id="num_units"
                                {...register("num_units", {
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Amount must be greater than 0",
                                })}
                              />
                            </NumberInput>
                          </FormControl>
                        )}
                      </Stack>

                      <FormControl
                        id="other_details"
                        isInvalid={errors.other_details}
                      >
                        <FormLabel htmlFor="other_details">
                          Description
                        </FormLabel>
                        <Textarea
                          placeholder="Provide any other details"
                          type="text"
                          {...register("other_details", {
                            maxLength: {
                              value: 250,
                              message: "Must be less than 250 characters long",
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.other_details &&
                            errors.other_details?.message}
                        </FormErrorMessage>
                      </FormControl>

                      <Flex pt={8}>
                        <Stack flex="0 0 300px">
                          <Heading as="h3" size="sm" pt="0.1rem">
                            How much would you like to pay?
                          </Heading>
                          <Checkbox
                            onChange={(e) =>
                              setpriceRangeSelected(e.target.checked)
                            }
                          >
                            Select a price range
                          </Checkbox>
                        </Stack>
                        <Stack
                          flex="1 1 auto"
                          spacing="1"
                          direction={{
                            default: "column",
                            md: "row",
                          }}
                        >
                          <FormControl
                            id="price_per_range_start"
                            isRequired
                            isInvalid={errors.price_per_range_start}
                            flex="0 0 auto"
                            w="auto"
                            px={8}
                          >
                            <FormLabel>
                              {priceRangeSelected && "Starting"} Price per unit
                              ($)
                            </FormLabel>
                            <NumberInput
                              min={0}
                              precision={2}
                              step={0.01}
                              maxW={40}
                            >
                              <NumberInputField
                                id="price_per_range_start"
                                {...register("price_per_range_start", {
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Amount must be greater than 0",
                                })}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <FormErrorMessage>
                              {errors.price_per_range_start &&
                                errors.price_per_range_start?.message}
                            </FormErrorMessage>
                          </FormControl>
                          {priceRangeSelected && (
                            <FormControl
                              id="price_per_range_end"
                              isRequired
                              isInvalid={errors.price_per_range_end}
                              flex="0 0 auto"
                              w="auto"
                              px={8}
                            >
                              <FormLabel>Ending Price ($)</FormLabel>
                              <NumberInput
                                min={parseFloat(
                                  getValues()["price_per_range_start"]
                                )}
                                precision={2}
                                step={1.0}
                                maxW={40}
                              >
                                <NumberInputField
                                  id="price_per_range_end"
                                  {...register("price_per_range_end", {
                                    validate: (value) =>
                                      parseFloat(value) >=
                                        parseFloat(
                                          getValues()["price_per_range_start"]
                                        ) ||
                                      "Amount must be equal or greater than starting price",
                                  })}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                              <FormErrorMessage>
                                {errors.price_per_range_end &&
                                  errors.price_per_range_end?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Stack>
                      </Flex>

                      <Flex pt={8}>
                        <Stack flex="0 0 300px">
                          <Heading as="h3" size="sm" pt="0.1rem">
                            When do you need this?
                          </Heading>
                          <Checkbox
                            onChange={(e) =>
                              setdateRangeSelected(e.target.checked)
                            }
                          >
                            Select a date range
                          </Checkbox>
                        </Stack>

                        <Stack
                          spacing="1"
                          direction={{
                            default: "column",
                            md: "row",
                          }}
                        >
                          <FormControl
                            id="delivery_date_start"
                            isInvalid={errors.delivery_date_start}
                            isRequired
                            flex="0 0 auto"
                            w="auto"
                            px={8}
                          >
                            <FormLabel as="legend">
                              {dateRangeSelected && "Start"} Date
                            </FormLabel>
                            <Input
                              width="100"
                              type="date"
                              min={today}
                              {...register("delivery_date_start", {
                                required: "Pick a date today or later",
                              })}
                            />
                            <FormErrorMessage>
                              {errors.delivery_date_end &&
                                errors.delivery_date_end?.message}
                            </FormErrorMessage>
                          </FormControl>
                          {dateRangeSelected && (
                            <FormControl
                              id="delivery_date_end"
                              isInvalid={errors.delivery_date_end}
                              isRequired
                              flex="0 0 auto"
                              w="auto"
                              px={8}
                            >
                              <FormLabel as="legend">End Date</FormLabel>
                              <Input
                                width="100"
                                type="date"
                                min={getValues()["delivery_date_start"]}
                                {...register("delivery_date_end", {
                                  validate: (value) =>
                                    value >=
                                      getValues()["delivery_date_start"] ||
                                    "Amount must be greater than starting range",

                                  required: "Pick a date today or later",
                                })}
                              />
                              <FormErrorMessage>
                                {errors.delivery_date_end &&
                                  errors.delivery_date_end?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Stack>
                      </Flex>
                    </Fragment>
                  )}
                  <Divider />
                </Stack>
              </Box>
              <Spacer />
              <Flex justifyContent={"center"}>
                <Button
                  isDisabled={productType === null ? true : false}
                  variant="primary"
                  alignSelf="start"
                  isLoading={isCreating}
                  type="submit"
                >
                  {isUpdating ? "Update" : "Create"} your Request
                </Button>
              </Flex>
            </Stack>
          </chakra.form>
        </Container>
      </PageLayout>
    </Layout>
  );
};
