import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { firestore, auth } from "../../../store/firebase/Firebase";
import {
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  addUserEmail,
  addUsername,
} from "../../../store/features/userSlice";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  useUpdateEmail,
  useSignInWithEmailAndPassword,
  useUpdatePassword,
} from "react-firebase-hooks/auth";
import { DeleteAccountAlert } from "./DeleteAccountAlert.js";
import { checkUsernameExists } from "../../../store/firebase/FirebaseFunctions";

export const AuthChangeProviderPassword = () => {
  const [userDocRef, setUserDocRef] = useState();
  const dispatch = useDispatch();
  const [updateEmail, updatingEmail, errorEmail] = useUpdateEmail(auth);
  const [updatePassword, updatingPassword, passwordError] =
    useUpdatePassword(auth);
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
  const [updatingUsername, setUpdatingUsername] = useState(false);

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: OnCloseDelete,
  } = useDisclosure();

  const user = useSelector(selectUser);
  let uid = user.uid;

  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //See if user exists and if does then pre-populate with profile info
  useEffect(() => {
    const checkExisting = async () => {
      // Make the initial query
      const q = query(
        collection(firestore, "user_accounts"),
        where("uid", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        let profileData = doc.data();

        setUserDocRef(doc.ref);
        //dispatch to reducer
        dispatch(addUserEmail(profileData.email));
        dispatch(addUsername(profileData.username));
        //pre-populate form
        for (const [key, value] of Object.entries(profileData)) {
          setValue(key, value);
        }
      }
    };
    //if user logged in then get existing data
    if (uid !== null) {
      checkExisting();
    }
  }, [uid, setValue, dispatch]);

  const signIn = (currentPassword) => {
    try {
      signInWithEmailAndPassword(user.email, currentPassword);
    } catch (err) {
      toast({
position: "top",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onSubmitUsername = async (data) => {
    setUpdatingUsername(true);
    checkUsernameExists({ text: data["username"] })
      .then((result) => {
        if (result.data === true) {
          //if it does then set error
          setError("username", {
            type: "custom",
            message: "username already exists",
          });
          setUpdatingUsername(false);
        } else {
          data["updated_at"] = serverTimestamp();
          if (userDocRef) {
            updateDoc(userDocRef, data);
            //dispatch to profile
            dispatch(addUsername(data["username"]));
            setUpdatingUsername(false);
            toast({
position: "top",
              description: "Username updated!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      })
      .catch(() => {
        toast({
position: "top",
          description: "Something went wrong. Try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setUpdatingUsername(false);
      });
  };

  const onSubmitEmail = async (data) => {
    if (data["email"] !== user.email) {
      signIn(data["current_password"]);

      data["updated_at"] = serverTimestamp();
      if (userDocRef) {
        //reauthenticate user if changing email or password
        try {
          await updateEmail(data["email"]);
          await updateDoc(userDocRef, { email: data["email"] });
          dispatch(addUserEmail(data["email"]));
          toast({
position: "top",
            description: "Email updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (err) {
          toast({
position: "top",
            description: errorEmail,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
position: "top",
          description: "Something went wrong. Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
position: "top",
        description: "The new email cannot be the same as the old email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onSubmitPassword = async (data) => {
    signIn(data["current_password"]);
    data["updated_at"] = serverTimestamp();

    //reauthenticate user if changing email or password
    try {
      await updatePassword(data["new_password"]);

      toast({
position: "top",
        description: "Password updated!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
position: "top",
        description: passwordError.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onSubmitDelete = async (data) => {
    signIn(data["current_password"]);
    onOpenDelete();
  };

  return (
    <Container maxW="100%" p={0}>
      <chakra.form>
        <Stack
          spacing="5" 
          pt={{
            default: 0,
            md: "0.5rem",
          }} 
          pl={{
            default: 0,
            md: 44,
          }} 
          pr={{
            default: 0,
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            pos="relative"
            top={{
              default: "0",
              md: "-0.5rem",
            }}
            pt={0}
            mt={0}
          >
            Your Settings
          </Heading>
          <Box>
            <Stack
              spacing="5"
              pl={{
                default: "4",
                md: 4,
              }}
              pr={{
                default: "4",
                md: 4,
              }}
              py={{
                default: "5",
                md: "0",
              }}
            >
              <FormControl id="username" isInvalid={errors.username} mt={4}>
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  {...register("username", {
                    minLength: {
                      value: 5,
                      message: "Must be at least 5 characters long",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_]+$/i,
                      message: "Cannot contain symbols or spaces",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.username && errors.username?.message}
                </FormErrorMessage>
              </FormControl>
              <Box>
                <Button
                  variant="primary"
                  isLoading={updatingUsername}
                  type="submit"
                  onClick={handleSubmit(onSubmitUsername)}
                >
                  Update username
                </Button>
              </Box>

              <Divider p={3} />

              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="text"
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email?.message}
                </FormErrorMessage>
              </FormControl>

              <Box>
                <Button
                  variant="primary"
                  isLoading={updatingEmail}
                  type="submit"
                  onClick={handleSubmit(onSubmitEmail)}
                >
                  Update email
                </Button>
              </Box>

              <Divider p={3} />

              <FormControl id="new_password" isInvalid={errors.new_password}>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="text"
                  {...register("new_password", {
                    minLength: {
                      value: 8,
                      message: "Must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=(.*[A-Za-z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
                      message:
                        "Password should contain at least 1 letter, 1 symbol, and 1 number",
                    },
                    validate: (value) =>
                      watch("current_password") !== value ||
                      "The new password cannot be the same as the current password",
                  })}
                />
                <FormErrorMessage>
                  {errors.new_password && errors.new_password?.message}
                </FormErrorMessage>
                <FormHelperText>
                  Password must be at least 8 characters long
                </FormHelperText>
              </FormControl>
              <PasswordStrengthBar password={watch("new_password")} />
              <Box>
                <Button
                  variant="primary"
                  isLoading={updatingPassword}
                  type="submit"
                  onClick={handleSubmit(onSubmitPassword)}
                >
                  Update password
                </Button>
              </Box>

              <Divider p={3} />

              <Text fontWeight="medium">
                Click here to delete your account. This is not reversible.
              </Text>
              <Box>
                <Button
                  variant="primary"
                  bg="red.500"
                  type="submit"
                  onClick={handleSubmit(onSubmitDelete)}
                >
                  Delete Account
                </Button>
              </Box>

              <Divider p={3} />

              {/* This workflow to be redone and added in a confirm modal */}
              <Text fontSize="lg">
                Please enter your current password to change your email,
                password, or to delete your account
              </Text>
              <FormControl
                id="current_password"
                isInvalid={errors.current_password}
                isRequired
              >
                <FormLabel>Current Password</FormLabel>
                <Input
                  type="text"
                  {...register("current_password", {
                    required: "Password is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.current_password && errors.current_password?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </Box>
          <DeleteAccountAlert isOpen={isOpenDelete} onClose={OnCloseDelete} />
        </Stack>
      </chakra.form>
    </Container>
  );
};
