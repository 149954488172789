import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../../store/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteUser } from "react-firebase-hooks/auth";
import { auth } from "../../../store/firebase/Firebase";
import { setMyUserProfile } from "../../../store/firebase/FirebaseFunctions";
import { useState, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { Fragment, useRef } from "react";

/*
popup that asks if want to delete account
first check if has current orders, then contact us to delete
Otherwise delete, change status to delete and function withdraws/leaves proposals/rfps
*/

export const DeleteAccountAlert = (props) => {
  const [deleteUser, , errorDelete] = useDeleteUser(auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasCurrentOrder, setHasCurrentOrder] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const appInfo = useSelector(selectAppInfo);
  let myOrders = appInfo.allOrders;

  const cancelRef = useRef();

  //get orders that are current and being executed
  // let currentOrders = myOrders.filter(function (myo) {
  //   return myo.status === "current";
  // });

  //set order status
  useEffect(() => {
    let currentOrders = myOrders.filter(function (myo) {
      return myo.status === "current" || myo.status === "paying";
    });

    if (currentOrders.length > 1) {
      setHasCurrentOrder(true);
    }
  }, [setHasCurrentOrder, myOrders]);

  //handle errors
  useEffect(() => {
    if (errorDelete) {
      toast({
        position: "top",
        description:
          "Please enter your password to authenticate your account first.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [errorDelete, toast]);

  const finishDelete = async () => {
    const success = await deleteUser();
    if (success) {
      toast({
        position: "top",
        description: "Account deleted!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch(removeUser());
      navigate("/main");
    } else {
      setMyUserProfile({ status: "delete_failed" }).then(() => {});
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    let data = { status: "deleted", updated_at: serverTimestamp() };
    setMyUserProfile(data).then(() => {
      finishDelete();
    });
    setIsSubmitting(false);
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Delete Account</AlertDialogHeader>
        {hasCurrentOrder ? (
          <Fragment>
            <AlertDialogBody>
              You still have orders in progress. Wait to complete your orders to
              delete your account or contact us.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={props.onClose} mr={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </Fragment>
        ) : (
          <Fragment>
            <AlertDialogBody>
              Are you sure you want to delete your account? This is not
              reversible.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={props.onClose} ref={cancelRef} mr={3}>
                No
              </Button>
              <Button onClick={onSubmit} isLoading={isSubmitting}>
                Yes
              </Button>
            </AlertDialogFooter>
          </Fragment>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};
