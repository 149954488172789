import { StackDivider, Stack, Text, Link } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../store/features/appInfoSlice";
import { NotificationCard } from "./NotificationCard";

/* Notifications

order - when confirmed, or num delvieres updated
rfp - when buyer asks to join or joins, status changes, new proposal
proposal - when confirmed or rejected, 


Buyer
- Lead, when new proposal applied
- When order confirmed
- Lead, when new buyer asks to join or joins
- Lead, when order number of deliveries updated
- When RFP matching interest appears
- As supporter, RFP status changes

Vendor
- When RFP matching interest appears
- When proposal is confirmed or rejected
- When order number of deliveries expected
- When order is confirmed
 */

/*
show only recent 5 notifications, with symbol for any new after last sign in
notifiation page show notifications since last 30 days that have not been crossed out
if crossed out, don't show in last 5 notifications or last 30 days
*/

export const NotificationPopover = () => {
  const appInfo = useSelector(selectAppInfo);
  let openNotifications = appInfo.allOpenNotifications ?? [];

  if (openNotifications.length > 0) {
    //only show 5 most recent notifications
    let fiveNotifications = openNotifications.slice(0, 5);
    return (
      <Stack direction="column" divider={<StackDivider />}>
        {fiveNotifications.map((notification, index) => (
          <NotificationCard
            key={index}
            notification={notification}
            type="popver"
          />
        ))}
        <Link href="/notifications">
          <Text as="b" fontSize="sm">
            See All Notifications
          </Text>
        </Link>
      </Stack>
    );
  } else {
    return (
      <Text as="b" fontSize="sm">
        No open notifications
      </Text>
    );
  }
};
