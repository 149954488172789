import millify from "millify";
import {
  Box,
  Flex,
} from "@chakra-ui/react";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { Card } from "../../../UI/Card"; 

export const VendorRFPInfoCard = (props) => {
  const user = useSelector(selectUser);
  const sessionInfo = useSelector(selectSessionInfo);
  const appInfo = useSelector(selectAppInfo);
  const rfp = sessionInfo.currentRfp;
  const price = rfp.price_per_range_end ? "$" + (rfp.price_per_range_start + " - $" + rfp.price_per_range_end) : "$" + rfp.price_per_range_start;
  const units = rfp.num_units;
  const total = rfp.price_per_range_end ? millify(rfp.price_per_range_start * units, { lowercase: true }) + "-" + millify(rfp.price_per_range_end * units, { lowercase: true }) 
                : millify(rfp.price_per_range_start * units, { lowercase: true });

  let product = props.product;

  const openProposals = appInfo.allProposals?.filter(function (proposal) {
    return (
      proposal.rfp_id === rfp.id &&
      proposal.status !== "rejected" &&
      proposal.status !== "withdrawn"
    );
  });

  let numProposals = openProposals?.length ?? 0;

  return (
    <Flex
      direction={{
        default: "column",
        md: "row",
      }}
    >
      <Box
        w="100%"
        ml={{
          md: "5.5rem",
        }}
        px={{
          md: "3%",
        }}
      >
        <Card 
          deliveryDate={rfp?.delivery_date_end ? rfp?.delivery_date_end : rfp?.delivery_date_start}
          details={rfp.other_details}
          estimate={rfp.price_per_range_end * units}
          id={rfp.id}
          imageUrl={product?.image_url}
          imageName={product?.name}
          name={rfp.name}
          object={rfp}
          onLinkClick={null}
          onUpdateClick={""}
          price={price}
          product={product}
          productName={product.name}
          proposalCount={numProposals}
          statusColor={props.statusColor} 
          status={props.status}
          to={"/" + user.account_type + "/current-rfp"}
          total={total}
          units={units}
          type="request"
          user={user}
        />
      </Box>
    </Flex>
  );
};
