import { 
  Box, 
  Flex, 
  Heading, 
  Icon, 
  Link, 
  Text 
} from "@chakra-ui/react";
import { MenuGrid } from "../platform/elements/MenuGrid";
import { RFPMenuCard } from "../platform/rfps/RFPMenuCard";
import { OrderMenuCard } from "../platform/orders/OrderMenuCard";
import { RFPMenu } from "../platform/rfps/RFPMenu";
import { selectAppInfo } from "../../store/features/appInfoSlice";
import {
  addCreateNewRfp,
  selectSessionInfo,
} from "../../store/features/sessionInfoSlice";
import { selectUser } from "../../store/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { FiPlus } from "react-icons/fi";
import { Layout } from "../../UI/Layout";
import { PageLayout } from "../../UI/PageLayout";
import { useNavigate } from "react-router-dom";

/*
Home page for buyer.
First show leader requests, the supporter requests
Show requests that have been recommended through register link. When clicking on that link, reset and only show once
Show requests with proposals first, then those with none
Later show requests with recent updates first (TBD)
*/

export const BuyerHome = () => {
  const appInfo = useSelector(selectAppInfo);
  const user = useSelector(selectUser);
  const dispatch = useDispatch(selectSessionInfo);
  const navigate = useNavigate();

  //load and filter rfps by distinction
  const rfpsAsLead =
    appInfo.allRfps?.filter(function (rfp) {
      return rfp.lead_buyer === user.uid;
    }) ?? [];

  const rfpsAsSupporter =
    appInfo.allRfps?.filter(function (rfp) {
      return rfp.supporters?.includes(user.uid);
    }) ?? [];

  //find rfps where invited
  const rfpsInvited =
    appInfo.allRfps?.filter(function (rfp) {
      return rfp.buyers_invited?.includes(user.uid);
    }) ?? [];

  //find rfps that not joined yet
  let joinedRfpIds = [];

  rfpsAsLead?.forEach((rfp) => {
    joinedRfpIds.push(rfp.id);
  });
  rfpsAsSupporter?.forEach((rfp) => {
    joinedRfpIds.push(rfp.id);
  });
  rfpsInvited?.forEach((rfp) => {
    joinedRfpIds.push(rfp.id);
  });

  const rfpsOpenNotJoined =
    appInfo.allRfps?.filter(function (rfp) {
      return !joinedRfpIds.includes(rfp.id);
    }) ?? [];

  // get all orders
  const myOrders = appInfo.allOrders.filter(function (order) {
    return order.lead_buyer === user.uid;
  });

  // get orders that are current and being executed
  let currentOrders = myOrders.filter(function (myo) {
    return myo.status === "current" || myo.status === "paying";
  });

  //get orders that are completed
  // let completedOrders = myOrders.filter(function (myo) {
  //   return myo.status === "completed";
  // });

  let cleanedRFPs = rfpsAsLead.filter(function(objFromRfp) {
    return !currentOrders.find(function(objFromOrders) {
      return objFromRfp.id === objFromOrders.rfp_id
    })
  })

  const showCurrentRFPs = () => {
    if (cleanedRFPs?.length > 0 || currentOrders?.length > 0) {
      return (
        <MenuGrid>
          {currentOrders?.map((order, index) => (
            <OrderMenuCard key={index} order={order} />
          ))}
          {cleanedRFPs?.map((rfp, index) => (
            <RFPMenuCard key={index} rfp={rfp} />
          ))}
          {rfpsAsSupporter?.map((rfp, index) => (
            <RFPMenuCard key={index} rfp={rfp} />
          ))}
        </MenuGrid>
      );
    }
  };

  const findRequests = () => {
    if (rfpsOpenNotJoined?.length === 0 && rfpsOpenNotJoined?.length === 0) {
      return (
        <Fragment>
          <Heading as="h2" variant="underline" mt={8}>
            Find Requests
          </Heading>
          <Flex direction="column" align="center" justify="center">
            <Text fontSize="lg" my="2rem">
              We could not find any active requests right now. Check back later.
            </Text>
          </Flex>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Heading as="h2" variant="underline" mt={8}>
            Find a Request
          </Heading>
          <RFPMenu rfps={rfpsOpenNotJoined} />
        </Fragment>
      );
    }
  };

  const invitedRequests = () => {
    return (
      <Box>
        <Heading as="h2" variant="underline" mt={8}>
          Invited Request
        </Heading>
        <RFPMenu rfps={rfpsInvited} />
      </Box>
    );
  };

  const showMyRequests = () => {
    if (rfpsAsLead?.length === 0 && rfpsAsSupporter?.length === 0) {
      return (
        <Fragment>
          <Heading
            as="h2"
            variant="underline"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={{
              default: "wrap",
              md: "nowrap",
            }}
            ml={{
              md: "6.5rem",
            }}
          >
            My Requests
          </Heading>
          <Flex direction="column" align="center" justify="center">
            <Text fontSize="lg" my="2rem">
              You have not made any requests yet.
            </Text>
            <Link
              variant="button"
              onClick={() => {
                dispatch(addCreateNewRfp(true));
                navigate("/buyer/create-rfp");
              }}
            >
              <Icon mr="0.5rem" as={FiPlus} />
              Create a Request
            </Link>
          </Flex>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Heading
            as="h2"
            variant="underline"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={{
              default: "wrap",
              md: "nowrap",
            }}
            ml={{
              md: "6.5rem",
            }}
          >
            <Text>My Requests</Text>
            <Link
              m={{
                default: "1rem 0",
                md: "0",
              }}
              variant="button"
              onClick={() => {
                dispatch(addCreateNewRfp(true));
                navigate("/buyer/create-rfp");
              }}
            >
              <Icon mr="0.5rem" as={FiPlus} />
              Create New Request
            </Link>
          </Heading>
          {showCurrentRFPs()}
        </Fragment>
      );
    }
  };

  return (
    <Layout>
      <PageLayout>
        <Box
          position="relative"
          zIndex="1"
          pl={{
            default: 0,
            md: "4rem",
          }}
          className="gridWrapper"
        >
          {showMyRequests()}
          {rfpsInvited?.length > 0 && invitedRequests()}
          {findRequests()}
        </Box>
      </PageLayout>
    </Layout>
  );
};
