import AppRouter from "./AppRouter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductOptions,
  addAllRfps,
  addAllProposals,
  addVendorsProposed,
  addAllOrders,
  addBuyersAsked,
  addRatingsGiven,
  addAllOpenNotifications,
  addLeadBuyersRfps,
} from "./store/features/appInfoSlice";
import { LoadingStart } from "./UI/LoadingStart";
import { selectUser, addUser } from "./store/features/userSlice";
import { auth } from "./store/firebase/Firebase";
import { onAuthStateChanged } from "@firebase/auth";
import {
  getProducts,
  getMyOrders,
  getMyProposals,
  getMyRfps,
  getMyUserInfo,
  getVendorsProposed,
  getBuyersAsked,
  getMyGivenRatings,
  getMyOpenNotifications,
  getLeadBuyersRFPs,
} from "./store/firebase/FirebaseFunctions";
import sortBy from "lodash/sortBy";
import { store } from "./store/store";

/*
When site opens, check if user is authenticated
Download user info, rfps, proposals and products (gatekeeping done by nodejs function per user)
Loading loading page and approuter
*/

function App() {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // check at page load if a user is authenticated
  useEffect(() => {
    onAuthStateChanged(auth, (userAuth) => {
      if (!userAuth) {
        store.dispatch({ type: "RESET" });
      } else {
        //get user info
        getMyUserInfo().then((docs) => {
          dispatch(addUser(docs.data));
        });
      }
    });
  }, [dispatch]);

  //load all info for user
  useEffect(() => {
    if (user !== null && user?.uid !== null) {
      setIsLoading(true);

      //get product options
      getProducts().then((docs) => {
        dispatch(addProductOptions(docs.data));
      });

      getMyGivenRatings().then((docs) => {
        dispatch(addRatingsGiven(docs.data));
      });

      getMyRfps().then((rfpDocs) => {
        dispatch(addAllRfps(rfpDocs.data));
        let rfpIds = [];
        let buyersAskedIds = [];
        let leadBuyersIds = [];
        rfpDocs.data.forEach((rfp) => {
          //get rfp ids
          rfpIds.push(rfp.id);
          //get lead buyers
          leadBuyersIds.push(rfp.lead_buyer);
          //get buyers that asked to join rfps
          if (rfp.buyers_asked?.length > 0) {
            buyersAskedIds.push(...rfp.buyers_asked);
          }
        });

        getLeadBuyersRFPs([...new Set(leadBuyersIds)]).then(
          (leadBuyersDocs) => {
            dispatch(addLeadBuyersRfps(leadBuyersDocs.data));
          }
        );

        getBuyersAsked([...new Set(buyersAskedIds)]).then((buyersAskedDocs) => {
          dispatch(addBuyersAsked(buyersAskedDocs.data));
        });

        getMyProposals(rfpIds).then((proposalDocs) => {
          dispatch(addAllProposals(proposalDocs.data));
          //get vendors in proposal
          let proposalVendorsIds = [];
          proposalDocs.data.forEach((proposal) => {
            proposalVendorsIds.push(proposal.vendor);
          });

          getVendorsProposed([...new Set(proposalVendorsIds)]).then(
            (vendorDocs) => {
              dispatch(addVendorsProposed(vendorDocs.data));
            }
          );
        });
      });

      getMyOrders().then((docs) => {
        dispatch(addAllOrders(docs.data));
      });
      getMyOpenNotifications().then((docs) => {
        //set created at to date object
        let notifications = [];
        docs.data.forEach((doc) => {
          let ntf = doc;
          ntf["created_at"] = new Date(ntf.created_at._seconds * 1000);
          notifications.push(ntf);
        });
        //organize by date, with most recent first
        notifications = sortBy(notifications, function (obj) {
          return obj.created_at;
        }).reverse();
        dispatch(addAllOpenNotifications(notifications));
      });

      setIsLoading(false);
    }
  }, [user, dispatch]);

  if (isLoading) {
    return <LoadingStart />;
  } else {
    return <AppRouter />;
  }
}

export default App;
