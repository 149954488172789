import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FiCheckCircle,
  FiXCircle,
} from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { selectAppInfo } from "../../../../store/features/appInfoSlice";
import { useState } from "react";
import { selectSessionInfo } from "../../../../store/features/sessionInfoSlice";
import { addCurrentRfp } from "../../../../store/features/sessionInfoSlice";
import { buyerRfpActions } from "../../../../store/firebase/FirebaseFunctions";
import { AlertPopup } from "../../../platform/elements/AlertPopup";

export const ApproveBuyersTabActions = () => {
  const appInfo = useSelector(selectAppInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;

  const buyersAsked = rfp.buyers_asked ?? [];
  const buyersRejected = rfp.buyers_rejected ?? [];
  const supporters = rfp.supporters ?? [];

  const [myBuyers, setMyBuyers] = useState(getAskedBuyers());
  const [modalData, setModalData] = useState();
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    isOpen: isOpenAccept,
    onOpen: onOpenAccept,
    onClose: onCloseAccept,
  } = useDisclosure();

  //get buyers that asked to join
  function getAskedBuyers() {
    const myBuyers = appInfo.BuyersAsked.filter(function (buyer) {
      return buyersAsked.includes(buyer.uid);
    });
    return myBuyers;
  }
  const rejectBuyer = () => {
    //update rfp doc
    buyerRfpActions({
      rfp_id: rfp.id,
      action: "rejectBuyer",
      buyer_candidate: modalData.uid,
    })
      .then(() => {
        //remove from list of buyers asked and add to list of buyers rejected
        let newBuyersAsked = buyersAsked.filter(
          (buyerId) => buyerId !== modalData.uid
        );
        let newBuyersRejected = [...buyersRejected];
        newBuyersRejected.push(modalData.uid);

        let newRfp = { ...rfp };
        newRfp.buyers_rejected = newBuyersRejected;
        newRfp.buyers_asked = newBuyersAsked;
        setMyBuyers(newBuyersAsked);
        dispatch(addCurrentRfp(newRfp));
        toast({
          position: "top",
          description: `${modalData.username} rejected!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    onCloseReject();
  };

  const acceptBuyer = () => {
    //update rfp doc
    buyerRfpActions({
      rfp_id: rfp.id,
      action: "acceptBuyer",
      buyer_candidate: modalData.uid,
    })
      .then(() => {
        //add to list of buyers and remove from asked

        let newBuyersAsked = buyersAsked.filter(
          (buyerId) => buyerId !== modalData.uid
        );
        let newSupporters = [...supporters];
        newSupporters.push(modalData.uid);

        let newRfp = { ...rfp };
        newRfp.supporters = newSupporters;
        newRfp.buyers_asked = newBuyersAsked;

        setMyBuyers(newBuyersAsked);
        dispatch(addCurrentRfp(newRfp));
        toast({
          position: "top",
          description: `${modalData.username} accepted!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    onCloseAccept();
  };

  return (
    <Container 
      maxW="100%" 
      mx="auto" 
      p={0}
    >
      {myBuyers.length > 0 ? (
        <Stack spacing="5" flex="1">
          {myBuyers && (
            <List listStyleType="none">
              {myBuyers.map((buyer) =>
                buyer ? (
                  <ListItem
                    key={buyer.uid}
                    position="relative"
                    my={1}
                    px={0}
                    pb={4}
                    pr={4}
                    borderBottom="2px solid"
                    borderColor="gray.200"
                  >
                    <Flex
                      direction={{
                        default: "column",
                        md: "row",
                      }}
                      flexWrap={{
                        md: "wrap",
                      }}
                    >
                      <Stack 
                        order={{
                          default: "0",
                          md: "1",
                        }}
                        shouldWrapChildren 
                        spacing="2" 
                        px={{
                          default: 0,
                          md: 2,
                        }} 
                        flex="1"
                      >
                        <Box>
                          <Text fontSize="lg" fontWeight="bold">
                            {buyer.username}
                          </Text>
  
                          <Text>
                            {buyer.city}
                            {", "}
                            {buyer.state} {buyer.zipcode}
                          </Text>
                        </Box>
                      </Stack>
                      <Flex 
                        order={{
                          default: "2",
                          md: "2",
                        }}
                        justifyContent={{
                          default: "space-between",
                          md: "space-between",
                        }}
                        mt={0} 
                        gap={4}
                      >
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          color="green"
                          onClick={() => {
                            setModalData(buyer);
                            onOpenAccept();
                          }}
                        >
                          <Icon boxSize={5} as={FiCheckCircle} />
                          <Text fontSize="xsm">Accept</Text>
                        </Button>
  
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          color="red.500"
                          onClick={() => {
                            setModalData(buyer);
                            onOpenReject();
                          }}
                        >
                          <Icon boxSize={5} as={FiXCircle} />
                          <Text fontSize="xsm">Reject</Text>
                        </Button>
  
                      </Flex>
                    </Flex>
                  </ListItem>
                ) : null
              )}
            </List>
          )}
        </Stack>
      ) : (
        <Box p={10}>
          <Text fontSize="lg">You have no pending invites.</Text>
        </Box>
      )}
      <AlertPopup
        isOpen={isOpenReject}
        onClose={onCloseReject}
        alertHeader={"Reject Buyer"}
        alertBody={"Are you sure you want to reject this buyer?"}
        onSubmitAlert={rejectBuyer}
      />
      <AlertPopup
        isOpen={isOpenAccept}
        onClose={onCloseAccept}
        alertHeader={"Accet Buyer"}
        alertBody={"Are you sure you want to accept this buyer?"}
        onSubmitAlert={acceptBuyer}
      />
    </Container>
  );
};
