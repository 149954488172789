import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import appInfoReducer from "./features/appInfoSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import sessionInfoReducer from "./features/sessionInfoSlice";

const persistConfig = {
  key: "root",
  storage,
};

const allReducer = combineReducers({
  user: userReducer,
  appInfo: appInfoReducer,
  sessionInfo: sessionInfoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    // for all keys defined in your persistConfig(s)
    return allReducer(undefined, action);
  }
  return allReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
