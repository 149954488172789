import {
  FiHome,
  FiFileText,
  FiArchive,
  FiSend,
  FiShoppingCart,
} from "react-icons/fi";

export const items = [
  {
    title: "Home",
    description: "",
    href: "/home",
    icon: FiHome,
  },
  {
    title: "Create Request",
    description: "Lead the purchase and negotiation",
    href: "/buyer/create-rfp",
    icon: FiFileText,
  },
  {
    title: "Current Orders",
    description: "Check the progress of current orders",
    href: "/buyer/current-orders",
    icon: FiShoppingCart,
  },
  {
    title: "Completed Orders",
    description: "See archive of past orders",
    href: "/buyer/past-orders",
    icon: FiArchive,
  },
  {
    title: "Invite",
    description: "Invite other buyers and vendors to join",
    href: "#",
    icon: FiSend,
  },
];
