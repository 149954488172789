import { createContext, useContext } from "react";
import { auth } from "./firebase/Firebase";
import { confirmPasswordReset, onAuthStateChanged } from "@firebase/auth";

const AuthContext = createContext({
  currentUser: null,

  resetPassword: () => Promise,
});

/*
Auth function provider for registering, login, logout with Google/Firebase auth
*/

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  const value = {
    resetPassword,
    onAuthStateChanged,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
