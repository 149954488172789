import { createSlice } from "@reduxjs/toolkit";

export const sessionInfoSlice = createSlice({
  name: "sessionInfo",
  initialState: {
    createNewRfp: null,
    currentRfp: null,
    updateRfpObject: null,
    currentProposal: null,
    isUpdateProposal: false,
    currentOrder: null,
    recreateRfpObject: null,
    currentStorefront: null,
  },
  reducers: {
    addCreateNewRfp: (state, action) => {
      state.createNewRfp = action.payload;
    },
    addCurrentRfp: (state, action) => {
      state.currentRfp = action.payload;
    },
    addUpdateRfpObject: (state, action) => {
      state.updateRfpObject = action.payload;
    },
    addRecreateRfpObject: (state, action) => {
      state.recreateRfpObject = action.payload;
    },
    addCurrentProposal: (state, action) => {
      state.currentProposal = action.payload;
    },
    addIsUpdateProposal: (state, action) => {
      state.isUpdateProposal = action.payload;
    },
    addCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
    addCurrentStorefront: (state, action) => {
      state.currentStorefront = action.payload;
    },
  },
});

export const {
  addCreateNewRfp,
  addCurrentRfp,
  addUpdateRfpObject,
  addCurrentProposal,
  addIsUpdateProposal,
  addCurrentOrder,
  addRecreateRfpObject,
  addCurrentStorefront,
} = sessionInfoSlice.actions;

// selectors
export const selectSessionInfo = (state) => state.sessionInfo;

export default sessionInfoSlice.reducer;
