import { 
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { LeadProposalTabActions } from "./LeadProposalTabActions";
import { ApproveBuyersTabActions } from "./ApproveBuyersTabActions";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../../../store/features/appInfoSlice";
import { selectSessionInfo } from "../../../../store/features/sessionInfoSlice";

export const LeadRFPActions = (props) => {
  const appInfo = useSelector(selectAppInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;
  const buyersAsked = rfp.buyers_asked ?? [];
  let product = props.product;

  const myBuyers = appInfo.BuyersAsked.filter(function (buyer) {
    return buyersAsked.includes(buyer.uid);
  });

  let numBuyers = myBuyers?.length > 0;


  return (
    <Tabs size="md" variant="enclosed">
      <TabList borderBottom="1px solid" borderColor="gray.200">
        <Tab 
          _selected={{
            color: "blue.500",
            fontWeight: "bold",
            borderBottom: "2px solid",
            borderColor: "blue.500",
          }} 
          border="0"
        >Review Proposals</Tab>
        <Tab
          position="relative"
          _selected={{
            color: "blue.500",
            fontWeight: "bold",
            borderBottom: "2px solid",
            borderColor: "blue.500",
          }} 
          border="0"
        >
          Pending Invites 
          {numBuyers && (
            <Text 
              as="span"
              position="absolute"
              top="-0.25rem"
              right="-0.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="red.500"
              borderRadius="1rem"
              color="white"
              fontSize="sm"
              fontWeight="normal"
              px={2}
            >
              {numBuyers}
            </Text>
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p="0">
          <LeadProposalTabActions product={product} />
        </TabPanel>
        <TabPanel p="0">
          <ApproveBuyersTabActions />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
