import { 
  Box,
  Flex,
  Icon,
  Stack, 
  Text 
} from "@chakra-ui/react";
import {
  FiCheckCircle,
  FiXCircle,
  FiPackage,
  FiStar,
} from "react-icons/fi";

export const ProgressStat = (props) => {
  const { type, label, value, limit, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Box
        ml={{
          default: "1rem",
          md: "2rem",
        }}
        px={{
          default: "4",
          md: "6",
        }}
        py={{
          default: "5",
          md: "6",
        }}
        borderLeft="1px solid"
        borderColor="gray.300"
      >
        <Box
          bg="white"
          transform="translateX(-3.1rem)"
        >
          <Flex alignItems="center" gap={5}>
            <Icon 
              as={
                type === "complete-payment" ? FiPackage 
                : type === "paid" ? FiStar 
                : value === limit ? FiCheckCircle 
                : FiXCircle
              } 
              color={
                type === "complete-payment" ? "green.500" 
                : type === "paid" ? "green.500" 
                : value === limit ? "green.500" 
                : "red.500"
              } 
              boxSize={12} 
            />
            {limit > 1 ? (
              <Stack>
                <Text fontSize="sm">{label}</Text>
                <Stack direction="row" justify="center">
                  <Text fontSize="xl" fontWeight="bold">
                    {value}{" "}
                    <Text as="span" aria-hidden fontSize="md" fontWeight="semibold">
                      / {limit}
                    </Text>
                  </Text>
                  <Box srOnly>out of {limit}</Box>
                </Stack>
              </Stack>
            ) : (
              value === limit ? (
                  <Text fontSize="lg">
                    {type === "delivery" && "You have confirmed delivery of this order."}
                    {type === "receipt" && "The buyer has confirmed delivery."}
                    {type === "paid" && "This order has been paid in full."}
                  </Text>
              ) : (
                <Text fontSize="lg">
                  {type === "delivery" && "You have not completed your delivery of this order."}
                  {type === "receipt" && "The buyer has not confirmed receipt of this order."}
                  {type === "paid" && "This order has been paid in full."}
                  {type === "complete-payment" && "Complete your payment to process your purchase."}
                </Text>
              )
            )}
          </Flex>
          {/* <Box>
            Receipt row : The buyer has confirmed receipt of x number of items in this order or nah
          </Box>
          <Box>
            Paid row : Buyer has paid in full
          </Box> */}
        </Box>
      </Box>
      {/* <Progress
        hasStripe
        value={(value / limit) * 100}
        size="sm"
        borderRadius="none"
      /> */}
    </Box>
  );
};
