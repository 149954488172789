import { Fragment } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { updateOrderPaymentTransaction } from "../../../store/firebase/FirebaseFunctions";
import { useToast } from "@chakra-ui/react";

// Custom component to wrap the PayPalButtons and handle currency changes
export const PayPalButtonWrapper = (props) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const toast = useToast();
  let showSpinner = true;
  let amount = props.amount;
  let currency = "USD";
  let style = { layout: "vertical" };

  /*   useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]); */

  return (
    <Fragment>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        //Called on button click to set up a one-time payment.
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((transactionId) => {
              // Your code here after create the order
              return transactionId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            // Called when finalizing the transaction. Often used to inform the buyer that the transaction is complete.
            data["buyer"] = props.user_id;
            data["currency"] = currency;
            data["amount"] = amount;
            data["payee"] = props.vendor_payee_id;

            try {
              updateOrderPaymentTransaction({
                transaction_data: data,
                payment_id: props.payment_id,
                order_id: props.order_id,
              }).then((result) => {
                if (result) {
                  toast({
                    position: "top",
                    description: "Payment submitted!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  props.setHasPaid(true);
                }
              });
            } catch {
              toast({
                position: "top",
                description: "Something went wrong. Please try again later",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            props.onCloseModal();
          });
        }}
      />
    </Fragment>
  );
};
