import { Text } from "@chakra-ui/react";
import {
    captializeFirstLetter,
} from "../../utilities/utilities";

export const ProductCategoryName = (props) => {
  return (
    props.name && (
      <Text
          color="gray.500"
          fontSize="xsm"
          fontWeight="extrabold"
          textTransform="uppercase"
          letterSpacing="0.1rem"
          >
          {captializeFirstLetter(props.name)}
      </Text>
    )
  );
};
