import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Divider,
} from "@chakra-ui/react";

export const DetailsModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={6}>{props.body}</ModalBody>
        <Divider my={2} />
        <ModalFooter>
          <Button variant="primary" mr={3} onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
