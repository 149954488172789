import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

import { Fragment, useRef, useState } from "react";

/*
popup that asks if want to withdraw rfp or proposal
if accepted then continue action
*/

export const AlertPopup = (props) => {
  const cancelRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{props.alertHeader}</AlertDialogHeader>

        <Fragment>
          <AlertDialogBody>{props.alertBody}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={props.onClose} ref={cancelRef} mr={3}>
              No
            </Button>
            <Button
              onClick={() => {
                setIsSubmitting(true);
                props.onSubmitAlert(true);
              }}
              isLoading={isSubmitting}
            >
              {" "}
              Yes
            </Button>
          </AlertDialogFooter>
        </Fragment>
      </AlertDialogContent>
    </AlertDialog>
  );
};
