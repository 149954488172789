import { selectAppInfo } from "../store/features/appInfoSlice";
import { useSelector } from "react-redux";

export const useGetVendor = (proposal) => {
  const appInfo = useSelector(selectAppInfo);

  const vendor = appInfo.VendorsProposed?.filter(function (ven) {
    return ven.uid === proposal.vendor;
  })[0];

  return vendor;
};
