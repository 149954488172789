import {
  Box,
  Button,
  ButtonGroup,
  Container, 
  Divider,
  Flex, 
  Icon,
  Link,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FiCheckCircle,
  FiEdit3,
  FiHeart,
  FiLogOut,
  FiMessageCircle,
} from "react-icons/fi";
import { FaCrown } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { Fragment } from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tags } from "../components/platform/elements/Tags";
import { ProductCategoryName } from "../components/platform/elements/ProductCategoryName";
import { CardImage } from "../components/platform/elements/CardImage";
import { Amounts } from "../components/platform/elements/Amounts";
import { Dates } from "../components/platform/elements/Dates";
import { ChatDrawer } from "../components/chat/ChatDrawer";
import { RFPCardButtons } from "../components/platform/rfps/RFPCardButtons";
import { useLocation } from "react-router-dom";
import { useGetLeadBuyer } from "../hooks/useGetLeadBuyer"; 
import { AlertPopup } from "../components/platform/elements/AlertPopup";
import { updateProposalStatus } from "../store/firebase/FirebaseFunctions";
import { DeliveryPopoverForm } from "../components/platform/orders/DeliveryPopoverForm";
import {
  addCurrentOrder,
  addCurrentProposal,
} from "../store/features/sessionInfoSlice";
import { useNavigate } from "react-router-dom";


export const Card = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();
  const [canUpdate, setCanUpdate] = useState(false);
  const [isNegotiating, setIsNegotiating] = useState(false);
  const [withdrew, setWithdrew] = useState(false);
  const [canConfirm, setCanConfirm] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [isPaying, setIsPaying] = useState();
  const lead = useGetLeadBuyer(props.object);
  const supporterCount = props.object ? props.object.supporters : "";
  const [numReceived, setnumReceived] = useState(props.object.num_units_received ?? 0);
  
  const supportCountString = () => {
    if(supporterCount && supporterCount.length > 0) {
      let other = "other";
      if (supporterCount.length > 1) {
        other = "others";
      }
      return "and " + supporterCount.length + other;
    } else {
      return "";
    }
  }

  const getRoute = useLocation();
  const location = getRoute.pathname;

  useEffect(() => {
    if (props.status === "open" || props.status === "negotiating") {
      setCanUpdate(true);
    }
  }, [props.object, setCanUpdate]);

  //assign icon and distinction if buyer
  let distinctionIcon = "";
  let distinctionText = "";
  let memberRfp = false;
  let followOn = `/${props.user.account_type}/current-rfp`;

  if (props.user.account_type === "buyer") {
    if (props.object.lead_buyer === props.user.uid) {
      distinctionIcon = <Icon as={FaCrown} mr={1} />;
    }
    if (props.object.lead_buyer === props.user.uid) {
      distinctionText = " Leader";
      memberRfp = true;
    } else if (props.object?.supporters?.includes(props.user.uid)) {
      distinctionIcon = <Icon as={FiHeart} mr={1} />
      distinctionText = " Supporter";
      memberRfp = true;
    }
  }

  useEffect(() => {
    //check to see if lead buyer
    if (props.user.uid === props.object.lead_buyer) {
      setIsLead(true);
    }
  }, [props.user, props.object]);
  
  useEffect(() => {
    if (props.user.account_type === "vendor") {
      if (props.object.status === "withdrawn") {
        setWithdrew(true);
      } else {
        setWithdrew(false);
      }
      if (props.object.status === "open" || props.object.status === "negotiating") {
        setCanUpdate(true);
      }
      if (props.object.status === "accepted") {
        setCanConfirm(true);
      }
    }
  }, [setWithdrew, props.object, setCanUpdate, setCanConfirm]);

  useEffect(() => {
    //check to see if payments still need to be completed
    if (props.object.status === "paying") {
      setIsPaying(true);
    } else if (props.object.status === "current") {
      setIsPaying(false);
    }
  }, [props.object.status, setIsPaying]);

  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();

  const {
    isOpen: isOpenChatBuyers,
    onOpen: onOpenChatBuyers,
    onClose: onCloseChatBuyers,
  } = useDisclosure();

  const {
    isOpen: isOpenChatVendor,
    onOpen: onOpenChatVendor,
    onClose: onCloseChatVendor,
  } = useDisclosure();

  const {
    onOpen: onOpenDeliveryUpdate,
    onClose: onCloseDeliveryUpdate,
    isOpen: isOpenDeliveryUpdate,
  } = useDisclosure();

  const confirmProposal = async () => {
    //create order doc
    updateProposalStatus({
      newStatus: "confirmed",
      proposalId: props.object.id,
    })
      .then((orderDoc) => {
        dispatch(addCurrentOrder(orderDoc.data));
        toast({
          position: "top",
          description: "Proposal confirmed!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        navigate(`/vendor/current-order`);
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  const withdrawProposal = () => {
    //update proposal and rfp
    updateProposalStatus({
      newStatus: "withdrawn",
      proposalId: props.object.id,
    })
      .then(() => {
        //update and dispatch to current proposal
        let newProposal = { ...props.object };
        newProposal["status"] = "withdrawn";
        dispatch(addCurrentProposal(newProposal));
        setWithdrew(true);
        toast({
          position: "top",
          description: "Proposal withdrawn!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  return (
    <Flex
      direction="column"
      p={0}
      border="1px solid"
      borderBottomWidth="3px"
      borderRightWidth="2px"
      borderColor="gray.300"
      className="card"
    >
      <div className="top-left-angle-white"></div>

      <Tags 
        icon={distinctionIcon} 
        id={props.id}
        followOn={props.to}
        onLinkClick={props.onLinkClick}
        proposalCount={props.proposalCount} 
        status={props.status} 
        statusColor={props.statusColor} 
        text={distinctionText}
        type={props.type}
      /> 

      <Container maxW="100%" mb="0.5rem">
        <Box>
          <ProductCategoryName name={props.productName} />
        </Box>
        {location !== "/" + props.user.account_type + "/current-rfp" ? (
          <Link
            as={RouterLink}
            key={props.id}
            to={props.to}
            onClick={() => dispatch(props.onLinkClick)}
            display="inline-block"
            fontSize="lg" 
            fontWeight="bold" 
          >
          {props.name}
        </Link>
        ) : (
          <Text
            display="inline-block"
            fontSize="lg" 
            fontWeight="bold"
          >
            {props.name}
          </Text>
        )}
        {props.user.account_type === "vendor" && lead && (
          <Text fontSize="sm" color="gray.600">requested by <strong>{lead?.username}</strong> {supportCountString()}</Text>
        )}
      </Container>
      <Divider />

      <Flex flex="0 0 auto" position="relative" py={4}>
        <Box flex="0 1 40%" pl={4}>
          <CardImage url={props.imageUrl} name={props.imageName} />
        </Box>
        
        <VStack flex="1 1 auto" alignItems="flex-end" pr={4}>
          <Amounts type={props.type} total={props.total} units={props.units} estimate={props.estimate} price={props.price} user={props.user.account_type}/>
          <Dates type={props.type} deliveryDate={props.deliveryDate} />
        </VStack>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        flex="1 1 auto"
      >

        {props.details && (
          <Fragment>
            <Text 
              fontSize="sm" 
              noOfLines={2} 
              my={2}
              px={4}
              pt={2}
              pb={2}
            >
              {props.details}
            </Text>
          </Fragment>
        )}
        
        <Divider />
        {props.type !== "order" ? (
          <Fragment>
            {props.user.account_type === "vendor" ? (
              <ButtonGroup
                w="100%"
                justifyContent="space-around"
                my={4}
                pr={{
                  default: "20px",
                  md: "20px",
                }}
              >
                {!withdrew && (
                  <Fragment>
                    <Link
                      variant="simpleIconLinkStacked"
                      onClick={() => {
                        setIsNegotiating(true);
                        onOpenChat();
                      }}
                    >
                      <Icon boxSize={5} as={FiMessageCircle} />
                      <Text as="span">Negotiate w/Buyer</Text>
                    </Link>
                    
                    {canUpdate && location !== "/vendor/current-rfp" && location !== "/vendor/current-proposal" && (
                      <Link
                        key={props.id}
                        as={RouterLink}
                        to={props.to}
                        onClick={() => {
                          dispatch(props.onUpdateClick);
                        }}
                        variant="simpleIconLinkStacked"
                      >
                        <Icon boxSize={5} as={FiEdit3} />
                        <Text as="span">{props.proposalCount === 0 ? "Submit Proposal" : "View Proposal"}</Text>
                      </Link>
                    )}
                    
                    {props.proposalCount !== 0 && (
                      <Link
                        variant="simpleIconLinkStacked"
                        my={{
                          default: "0.25rem",
                          md: "1rem",
                        }}
                        onClick={onOpenWithdraw}
                      >
                        <Icon boxSize={5} as={FiLogOut} mb="0.5rem" />
                        <Text as="span">Withdraw</Text>
                      </Link>
                    )}
                    
                    {canConfirm && (
                      <Link
                        variant="simpleIconLinkStacked"
                        onClick={onOpenConfirm}
                      >
                        <Icon boxSize={5} as={FiCheckCircle} />
                        <Text as="span">Confirm</Text>
                      </Link>
                    )}
                  </Fragment>
                )}
              </ButtonGroup>
            ): (
              <RFPCardButtons
                account_type={props.user.account_type}
                rfp={props.object}
                memberRfp={memberRfp}
                product={props.product}
                proposalCount={props.proposalCount}
                user={props.user}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <ButtonGroup
              w="100%"
              justifyContent="space-around"
              my={4}
              pr={{
                default: "20px",
                md: "20px",
              }}
            >
              <Button 
                variant="stacked" 
                onClick={onOpenChatBuyers}
              >
                <Icon 
                  boxSize={5} 
                  as={FiMessageCircle} 
                />
                <Text as="span">Chat w/ Buyers</Text>
              </Button>
              {isLead && (
                <Fragment>
                  <Button 
                    variant="stacked" 
                    onClick={onOpenChatVendor}
                  >
                    <Icon
                      boxSize={5}
                      as={FiMessageCircle}
                    />
                    <Text as="span">Chat w/ Vendor</Text>
                  </Button>
                  {!isPaying && (
                    <DeliveryPopoverForm
                      isOpen={isOpenDeliveryUpdate}
                      onOpen={onOpenDeliveryUpdate}
                      onClose={onCloseDeliveryUpdate}
                      setNumUnits={setnumReceived}
                      orderField={"received"}
                      order={props.object}
                    />
                  )}
                </Fragment>
              )}
            </ButtonGroup>
          </Fragment>
        )}
        

        
        {isNegotiating && (
          <ChatDrawer
            isOpen={isOpenChat}
            onClose={onCloseChat}
            title={"Negotiate with the buyer"}
            collectionName={"buyer_vendor_proposal_chat"}
            collectionDocId={props.id}
          />
        )}
      </Flex>   
      <AlertPopup
        isOpen={isOpenWithdraw}
        onClose={onCloseWithdraw}
        alertHeader={"Withdraw Request"}
        alertBody={
          "Are you sure you want to withdraw this request? This is not reversible."
        }
        onSubmitAlert={withdrawProposal}
      />
      <AlertPopup
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        alertHeader={"Confirm Proposal"}
        alertBody={
          "Are you sure you want to confirm this proposal? This is not reversible."
        }
        onSubmitAlert={confirmProposal}
      />
      <ChatDrawer
        isOpen={isOpenChatVendor}
        onClose={onCloseChatVendor}
        title={"Chat with the vendor"}
        collectionName={"buyer_vendor_order_chat"}
        collectionDocId={props.object.id}
      />
      <ChatDrawer
        isOpen={isOpenChatBuyers}
        onClose={onCloseChatBuyers}
        title={"Chat with other buyers"}
        collectionName={"buyers_order_chat"}
        collectionDocId={props.object.id}
      />
      <div className="bottom-right-angle-white"></div>
    </Flex>
  );
};
