import { Flex, Icon, Link, Text } from "@chakra-ui/react";
import { ProposalMenuCard } from "./ProposalMenuCard";
import { MenuGrid } from "../../platform/elements/MenuGrid";
import { FiPlus } from "react-icons/fi";

/*
show proposal card menu to vendor
*/

export const ProposalMenu = (props) => {
  if (props.proposals.length > 0) {
    return (
      <MenuGrid>
        {props.proposals.map((proposal, index) => (
          <ProposalMenuCard key={index} proposal={proposal} />
        ))}
      </MenuGrid>
    );
  } else {
    return (
      <Flex direction="column" align="center" justify="center">
        <Text
          fontSize="lg"
          my="2rem"
        >{`You have not submitted any proposals yet`}</Text>
        <Link variant="button" href="/vendor/create-proposal">
          <Icon mr="0.5rem" as={FiPlus} />
          Create Proposal
        </Link>
      </Flex>
    );
  }
};
