import { selectAppInfo } from "../store/features/appInfoSlice";
import { useSelector } from "react-redux";

export const useGetProduct = (item) => {
  const appInfo = useSelector(selectAppInfo);

  const product = appInfo.productOptions?.filter(function (po) {
    return po.id === item.product;
  })[0];

  return product;
};
