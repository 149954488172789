import {
  Box,
  Button,
  Container,
  Heading,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../UI/Footer";
import { Fragment } from "react";
import { LoginFull } from "../../UI/Icons/LoginFull";

/*
Home page when not logged in
*/
const Main = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box 
        as="section" 
        flex="1 1 auto"
        maxW="1800px"
        m="0 auto"
        pb={50}
        bg="white"
      >
        <Container
          maxW="100%"
          px="0"
          py={{
            default: "0",
            md: "0",
          }}
        >
          <Container
            maxW="100%"
            centerContent
            borderTop="0.75rem solid"
            borderColor="blue.300"
            overflow="hidden"
          >
            <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
              <LoginFull />
            </Link>
          </Container>
          <Stack
            px={4}
            spacing={{
              default: "8",
              md: "10",
            }}
          >
            <Stack
              spacing={{
                default: "4",
                md: "5",
              }}
              align="center"
            >
              <Text size="sm" fontWeight="bold" m={6} color="gray.500" textTransform="uppercase">Demo</Text>
              <Heading
                align="center"
                size={useBreakpointValue({
                  default: "md",
                  md: "md",
                })}
              >
                Increase your buying power
              </Heading>

              <Text 
                maxW="lg" 
                textAlign="center" 
                fontSize={{
                  default: "sm",
                  md: "md",
                }}
              >
                Easily coordinate purchases with your friends to get discounts
                from vendors and save money! 
              </Text>
            </Stack>
            <Stack
              spacing={{
                default: "6",
                sm: "8"
              }}
              direction={{
                default: "column",
                sm: "row",
              }}
              justify="center"
            >
              <Button
                variant="primary"
                size="lg"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={() => navigate("/register")}
              >
                Register
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
};

export default Main;
