import { 
    Flex,
    Icon,
    Text,
} from "@chakra-ui/react";
import { FiCalendar } from "react-icons/fi";
import { getDateString } from "../../utilities/utilities";

export const Dates = (props) => {
  return (
    <Flex w="100%" justifyContent="space-between" textAlign="right">
        <Flex direction="column" flex="1 1 auto" pl={4}>
            <Text as="span" fontSize="sm" color="gray.500">
                {props.type === "order" && "Expected Delivery Date:" }
                {props.type === "proposal" && "Needed By:" }
                {props.type === "request" && "Needed By:" }
            </Text>{" "}
            <Text as="span" fontSize="md" fontWeight="bold">
                {getDateString(props.deliveryDate)}
                {/* Get current date to point out past due */}
            </Text>
        </Flex>
    </Flex>
  );
};
