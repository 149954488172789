import {
  Box,
  ButtonGroup,
  Container,
  Flex,
  Icon,
  IconButton,
  Link,
  Text,
  useDisclosure,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Popover,
} from "@chakra-ui/react";
import classNames from "classnames";
import { FiBell, FiShoppingBag, FiChevronLeft } from "react-icons/fi";
import { LogoWithText } from "../Icons/Logo_with_Text";
import { NavMenu } from "../Menu/NavMenu";
import { ProfileMenu } from "../Menu/ProfileMenu";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";
import { useLocation } from "react-router-dom";
import "./menu.css";
import { NotificationPopover } from "../../components/notifications/NotificationPopover";

export const NavBar = () => {
  const user = useSelector(selectUser);
  const getRoute = useLocation();
  const location = getRoute.pathname;

  const { isOpen, onClose } = useDisclosure();

  let logoUrl = "/home";
  let account_type = user.account_type;
  let account_name = "";
  
  if(account_type === "vendor") {
    account_name = user.company_name;
  } else if(account_type === "buyer") {
    account_name = user.first_name + " " + user.last_name;
  }

  const menuButton = () => {
    if (account_type === "vendor") {
      return (
        <Box>
          <Link
            color={{
              default: "blue.500",
            }}
            m={{
              default: "0 0.85rem 0 0.85rem",
              md: "0 3rem 0 0",
            }} 
            variant="simpleIconLink"
            href="/vendor/storefront"
          >
            <Icon mr="0.5rem" as={FiShoppingBag} />
            Storefront
          </Link>
        </Box>
      );
    }
  };

  const NotificationButton = () => {
    //wrapped Popover with Box to get rid of padding warning
    return (
      <Box>
        <Popover>
          <PopoverTrigger>
            <IconButton
              mt={{
                default: "0.1rem",
                md: "0",
              }}
              icon={<FiBell fontSize="1.2rem" />}
              background="transparent"
              aria-label="View Notifications"
              _hover= {{
                background: "gray.100"
              }}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />

            <PopoverBody>
              <NotificationPopover />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );
  };

  const pageSpecificNavigation = () => {
    if (location === "/home" && account_type === "buyer") {
      return (
        <Flex
          align="center"
          justifyContent="flex-end"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p={{
            default: "0",
            md: "0.6rem 0.4rem 0.8rem",
          }}
          minH={{
            md: "43px"
          }}
        >
          <div className="top-left-angle-gray"></div>
          {menuButton()}
          <Text
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, {" "}<Text as="span" fontWeight="bold">{account_name}</Text></Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    } else if (location === "/profile" && account_type === "buyer") {
      return (
        <Flex
          align="center"
          justifyContent="space-between"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p="0.6rem 0.4rem 0.8rem"
          minH="43px"
        >
          <div className="top-left-angle-gray"></div>
          <Link 
            color={{
              default: "blue.500",
            }}
            mr={{
              default: "0",
              md: "3rem",
            }} 
            variant="simpleIconLink" 
            href="/home"
          >
            <Icon w="1.5rem" h="1.5rem" as={FiChevronLeft} /> Back to Requests
          </Link>
          <Text
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, {" "}<Text as="span" fontWeight="bold">{account_name}</Text></Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    } else if (account_type === "buyer") {
      return (
        <Flex
          align="center"
          justifyContent="space-between"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p="0.6rem 0.4rem 0.8rem"
          minH="43px"
        >
          <div className="top-left-angle-gray"></div>
          <Link 
            color={{
              default: "blue.500",
            }}
            m={{
              default: "0 0 0 0.5rem",
              md: "0 3rem 0 0",
            }} 
            variant="simpleIconLink" 
            href="/home"
          >
            <Icon w="1.5rem" h="1.5rem" as={FiChevronLeft} /> Back to Requests
          </Link>
          <Text
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, {" "}<Text as="span" fontWeight="bold">{account_name}</Text></Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    }

    if (location === "/home" && account_type === "vendor") {
      return (
        <Flex
          justifyContent="space-between"
          align="center"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p="0.6rem 0.4rem 0.8rem"
        >
          <div className="top-left-angle-gray"></div>
          {menuButton()}
          <Text 
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, <Text as="span" fontWeight="bold">{account_name}</Text></Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    } else if (location === "/profile" && account_type === "vendor") {
      return (
        <Flex
          justifyContent="space-between"
          align="center"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p="0.6rem 0.4rem 0.8rem"
          minH="43px"
        >
          <div className="top-left-angle-gray"></div>
          <Link
            color={{
              default: "blue.500",
            }}
            mr={{
              default: 0,
              md: "3rem",
            }}
            ml={{
              default: 4,
              md: 0,
            }}
            variant="simpleIconLink"
            href="/vendor/home"
          >
            <Icon w="1.5rem" h="1.5rem" as={FiChevronLeft} /> Back to Home
          </Link>
          {menuButton()}
          <Text
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, {" "}<Text as="span" fontWeight="bold">{account_name}</Text></Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    } else if (account_type === "vendor") {
      return (
        <Flex
          justifyContent="space-between"
          align="center"
          position="relative"
          bg={{
            base:"transparent",
            md:"gray.100",
          }}
          w="100%"
          p="0.6rem 0.4rem 0.8rem"
          minH="43px"
        >
          <div className="top-left-angle-gray"></div>
          <Link
            color={{
              default: "blue.500",
            }}
            mr={{
              default: 0,
              md: "3rem",
            }}
            ml={{
              default: 4,
              md: 0,
            }}
            variant="simpleIconLink"
            href="/vendor/home"
          >
            <Icon w="1.5rem" h="1.5rem" as={FiChevronLeft} /> Back to Home
          </Link>
          {menuButton()}
          <Text
            display={{
              default: "none",
              md: "block",
            }}
            color={{
              default: "blue.500",
            }}
          >Hello, {" "}
            <Text as="span" fontWeight="bold">{account_name}</Text>
          </Text>
          <div className="top-right-angle-gray"></div>
        </Flex>
      );
    }
  };

  // const isDesktop = useBreakpointValue({
  //   default: false,
  //   lg: true,
  // });

  return (
    <Box as="section">
      <Box as="nav">
        <Container 
          maxW="100%"
          p={{
            default: 0,
          }}
        >
          <Flex 
            flexWrap={{
              default: "wrap",
              md: "nowrap",
            }}
            pos="relative"
          >
            <Flex 
              order={{
                default: "0",
                md: "0",
              }} 
              flex="0 0 auto" 
              pos="relative" 
              zIndex="3">
              <div
                id="Main_Menu_Clip_Background"
                className={classNames(
                  "menu_clip_background",
                  isOpen ? "isOpen" : "isClosed"
                )}
              ></div>
              <Flex 
                direction={{
                  default: "row",
                  md: "column",
                }}
                ml={{
                  default: 0,
                  md: 4,
                }}
              >
                <Link 
                  pos="relative" 
                  zIndex="1"
                  href={logoUrl} 
                  pt={{
                    default: 0,
                    md: 2,
                  }} 
                >
                  <LogoWithText />
                </Link>
                <NavMenu isOpen={isOpen} onClose={onClose} />
              </Flex>
            </Flex>

            {/* This needs to toggle based on route */}
            <Flex
              order={{
                default: "2",
                md: "1",
              }}
              flex="1 1 100%"
              align="flex-start"
              ml={{
                default: 0,
                md: "5rem",
              }}
            >
              {pageSpecificNavigation()}
            </Flex>

            <ButtonGroup
              display="flex"
              flexDirection={{
                default: "row",
                md: "column",
              }}
              flex={{
                default: "1 1 auto",
                md: "0 0 auto"
              }}
              order={{
                default: "1",
                md: "2",
              }}
              justifyContent={{
                default: "flex-end",
                md: "flex-start",
              }}
              m={{
                default: "0.2rem 0.2rem 0 0",
                md: "0.75rem 1.6rem 0 2rem",
              }}
              spacing="0"
            >
              <ProfileMenu />
              <NotificationButton />
            </ButtonGroup>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
