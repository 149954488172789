import { MenuGrid } from "../elements/MenuGrid";
import { RFPMenuCard } from "./RFPMenuCard";

export const RFPMenu = (props) => {
  if (props.rfps?.length > 0) {
    return (
      <MenuGrid>
        {props.rfps?.map((rfp, index) => (
          <RFPMenuCard key={index} rfp={rfp} />
        ))}
      </MenuGrid>
    );
  }
};
