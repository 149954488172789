import {
  Box, 
  Divider, 
  Text, 
  VStack, 
} from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { OrderInfoCard } from "../../platform/orders/OrderInfoCard";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { ProgressStat } from "../../../UI/ProgressStat";
import { Fragment } from "react";

export const VendorCompletedOrder = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  const order = sessionInfo.currentOrder;

  const product = useGetProduct(order);

  const numDelivered = order.num_units_delivered;

  const numReceived = order.num_units_received;
  const total_units = order.num_units;

  let progStatDelivered = {
    label: "Units delivered",
    value: numDelivered,
    limit: total_units,
  };

  let progStatReceived = {
    label: "Units received",
    value: numReceived,
    limit: total_units,
  };

  const OrderActions = () => {
    return (
      <Fragment>
        <VStack flex="1 1 50%" align="center">
          <ProgressStat mt={4} {...progStatDelivered} />
          <ProgressStat mt={4} {...progStatReceived} />
        </VStack>
      </Fragment>
    );
  };

  return (
    <Layout>
      <PageLayout>
      <Box 
          position="relative" 
          zIndex="1" 
          pt={{
            default: 0,
            md: 16,
          }} 
          pl={{
            default: 0,
            md: 44,
          }} 
          pr={{
            default: 0,
            md: 0,
          }}
        >
          <OrderInfoCard product={product} />
          <Divider p={2} />
          <Text mt={2} fontSize="2xl" color="green.500" align="center">
            Order is complete!
          </Text>
          <OrderActions />
        </Box>
      </PageLayout>
    </Layout>
  );
};
