import {
  Box,
  Button,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
  Text,
  useToast,
} from "@chakra-ui/react";
import { selectUser } from "../../../../store/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { SupporterProposalTabActions } from "./SupporterProposalTabActions";
import { useEffect, useState } from "react";
import { addCurrentRfp } from "../../../../store/features/sessionInfoSlice";
import { selectSessionInfo } from "../../../../store/features/sessionInfoSlice";
import { FiUserPlus } from "react-icons/fi";
import { buyerRfpActions } from "../../../../store/firebase/FirebaseFunctions";

/*
determines status of supporter
provides actions to support to ask to join, respond to invitation
supporter can also leave RFP
*/

export const SupporterRFPActions = (props) => {
  const user = useSelector(selectUser);
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;
  let product = props.product;

  const toast = useToast();
  const dispatch = useDispatch();

  const [isSupporter, setIsSupporter] = useState(false);
  const [asked, setAsked] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [wrongZipcode, setWrongZipcode] = useState(false);

  useEffect(() => {
    //check to see if supporter
    let buyersAsked = rfp.buyers_asked ?? [];
    let supporters = rfp.supporters ?? [];
    let buyersInvited = rfp.buyers_invited ?? [];

    if (supporters.includes(user.uid)) {
      setIsSupporter(true);
    } else {
      //check to see if invited
      if (buyersInvited.includes(user.uid)) {
        //check to see if in same zipcode
        if (user?.zipcode === rfp.zipcode) {
          setIsInvited(true);
        } else {
          setWrongZipcode(true);
        }
      }
      //check to see if already asked
      if (buyersAsked.includes(user.uid)) {
        setAsked(true);
      }
    }
  }, [user, rfp]);

  const askToJoin = () => {
    buyerRfpActions({
      rfp_id: rfp.id,
      action: "askToJoin",
      buyer_candidate: user.uid,
    })
      .then(() => {
        //add buyer to supporter buyer ask list to session and storage rfp objects
        let buyersAsked = rfp.buyers_asked ?? [];
        let newBuyersAsked = [...buyersAsked];
        newBuyersAsked.push(user.uid);
        //create new rfp object
        const newRfp = { ...rfp };
        newRfp.buyers_asked = newBuyersAsked;
        dispatch(addCurrentRfp(newRfp));
        setAsked(true);
        toast({
          position: "top",
          description: "Successfully asked to join!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const invitedToJoin = () => {
    buyerRfpActions({
      rfp_id: rfp.id,
      action: "acceptInvitation",
      buyer_candidate: user.uid,
    })
      .then(() => {
        //add buyer to supporter buyer list to session and storage rfp objects
        let supporters = rfp.supporters ?? [];
        let newSupporters = [...supporters];
        newSupporters.push(user.uid);
        //remove from invited list
        let buyersInvited = rfp.buyers_invited ?? [];
        let newBuyersInvited = buyersInvited.filter(
          (buyerId) => buyerId !== user.uid
        );

        //create new rfp object
        const newRfp = { ...rfp };
        newRfp.buyers_invited = newBuyersInvited;
        newRfp.supporters = newSupporters;
        setIsInvited(false);
        setIsSupporter(true);
        dispatch(addCurrentRfp(newRfp));
        toast({
          position: "top",
          description: "Successfully joined!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  //supporter buyers - can chat with lead buyer and all lead buyers
  //can invite others if open to anyone
  if (isSupporter) {
    return (
      <Tabs size="md" variant="enclosed">
        <TabList borderBottom="1px solid" borderColor="gray.200">
          <Tab
            _selected={{
              color: "blue.500",
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderColor: "blue.500",
            }} 
            border="0"
          >Review Proposals</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0">
            <SupporterProposalTabActions product={product} rfp={rfp} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }
  //if not supporter and has to try to join
  //if already asked
  else if (asked) {
    return (
      <Box p={5}>
        <Text fontSize="lg" my={6}>
          Waiting for approval from the Lead Buyer
        </Text>
        <Text>
          You will be listed as a supporter for this request, as soon as the Leader gives you the nod of approval.
        </Text>
      </Box>
    );
  }
  //if have not already asked, then see if invited
  else if (isInvited) {
    return (
      <Box p={5}>
        <Button
          alignSelf={"center"}
          variant="primary"
          size={"md"}
          onClick={invitedToJoin}
          maxW="md"
          my={6}
        >
          Join Request
        </Button>
      </Box>
    );
  }
  //if not invited, then have to ask and get approval
  else {
    //if wrong zipcode then don't give option to ask to join
    return (
      <Box p={5} align="center">
        {wrongZipcode ? (
          <Text>
            Sorry, you are not in the right zipcode to join this request. Click
            'Leave' on the left to remove this request.
          </Text>
        ) : (
          <Button
            leftIcon={<FiUserPlus />}
            alignSelf={"center"}
            variant="primary"
            size={"md"}
            onClick={askToJoin}
            maxW="md"
            my={6}
          >
            Ask to Join Request
          </Button>
        )}
      </Box>
    );
  }
};
