import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ReactStars from "react-rating-stars-component";
import { addUserRating } from "../../store/firebase/FirebaseFunctions";
import { Button, VStack, Text } from "@chakra-ui/react";
import { useState, Fragment } from "react";

export const RateUser = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [ratingScore, setRatingScore] = useState(0);

  const ratingChanged = (newRating) => {
    setIsSubmitting(true);
    let data = {
      rating_score: newRating,
      user_rated: props.ratingInfo["userid_receiver"],
      user_giving: props.ratingInfo["userid_giver"],
      order_id: props.ratingInfo["order_id"],
    };

    setRatingScore(newRating);
    addUserRating(data);
    setHasSubmitted(true);
  };

  const hasOldRating = () => {
    return (
      <Fragment>
        <ReactStars
          value={props.ratingInfo.old_rating}
          edit={false}
          count={5}
          size={50}
          activeColor="#6495ED"
          color="#DCDCDC"
        />

        <Text>Thank you for submitting your rating!</Text>
        <Button
          size="md"
          bgColor={"blue.500"}
          variant="primary"
          alignSelf={"center"}
          width={200}
          onClick={props.onClose}
        >
          Close
        </Button>
      </Fragment>
    );
  };

  const notYetRated = () => {
    return (
      <Fragment>
        <ReactStars
          value={ratingScore}
          edit={!hasSubmitted}
          count={5}
          onChange={ratingChanged}
          size={50}
          activeColor="#6495ED"
          color="#DCDCDC"
        />
        {hasSubmitted ? (
          <Fragment>
            <Text>Thank you for submitting your rating!</Text>
            <Button
              size="md"
              bgColor={"blue.500"}
              variant="primary"
              alignSelf={"center"}
              width={200}
              onClick={props.onClose}
            >
              Close
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Text>
              Individual ratings are confidential and your information will not
              be provided to the user
            </Text>
            <Button
              size="md"
              bgColor={"blue.500"}
              variant="primary"
              alignSelf={"center"}
              width={200}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rate {props.ratingInfo?.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6}>
            {props.ratingInfo?.old_rating ? hasOldRating() : notYetRated()}
          </VStack>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
