import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select as ChakraSelect,
  Stack,
  Text,
  Textarea,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState, Fragment } from "react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useForm } from "react-hook-form";
import { UsaStateOptions } from "./ProfileData";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  addUser,
  addVendorStoreImage,
} from "../../../store/features/userSlice";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { setMyUserProfile } from "../../../store/firebase/FirebaseFunctions";
import { captializeFirstLetter, sortByField } from "../../utilities/utilities";
import ReactStars from "react-rating-stars-component";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../store/firebase/Firebase";

export const VendorProfile = () => {
  const [defaultProducts, setDefaultProducts] = useState([]);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageProgress, setImageProgress] = useState(0);

  const user = useSelector(selectUser);
  const appInfo = useSelector(selectAppInfo);
  let sortedProductOptions = sortByField(appInfo.productOptions, "name");

  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //See if user exists and if does then pre-populate with profile info
  useEffect(() => {
    //pre-populate form
    if (user !== null) {
      for (const [key, value] of Object.entries(user)) {
        setValue(key, value);
        if (key === "products_offered") {
          setDefaultProducts(value);
        }
      }
    }
  }, [setValue, dispatch, user]);

  //to submit and upload image file to firebase
  const imageFormHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadImageFile(file);
  };

  const uploadImageFile = (file) => {
    if (!file) return;

    if (file.size > 3e6) {
      toast({
        position: "top",
        description: "File is too big. It must be less than 3mb in size",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let extension = file.name.split(".").pop();
    let filename = user.uid + "_store_image." + extension;

    const sotrageRef = ref(storage, `vendor_store_images/${filename}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setImageProgress(prog);
      },
      (error) => {
        toast({
          position: "top",
          description: "Upload failed. Try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //dispatch
          setMyUserProfile({ store_image: downloadURL }).then(() => {
            //dispatch to profile
            dispatch(addVendorStoreImage(downloadURL));
            toast({
              position: "top",
              description: "Profile updated!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      }
    );
  };

  const onSubmitUpdate = async (data) => {
    //send to firestore
    setIsSubmitting(true);
    setMyUserProfile(data)
      .then((userData) => {
        //dispatch to profile
        dispatch(addUser(userData.data));
        toast({
          position: "top",
          description: "Profile updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
        toast({
          position: "top",
          description: "Something went wrong. Please try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleChange = (data) => {
    setDefaultProducts(data);
  };

  const ratingStars = () => {
    if (user.rating !== null) {
      return (
        <Fragment>
          <Text as="em" mt={1} fontSize={"md"}>
            Current rating:{" "}
          </Text>
          <ReactStars
            value={Number(user.rating)}
            edit={false}
            count={5}
            size={20}
            activeColor="#6495ED"
            color="#DCDCDC"
          />
        </Fragment>
      );
    }
  };

  return (
    <Layout>
      <PageLayout>
        <Container maxW="100%" p={0}>
          <Stack
            spacing="5"
            pt={{
              default: 0,
              md: "0.5rem",
            }}
            pl={{
              default: 0,
              md: 44,
            }}
            pr={{
              default: 0,
              md: 0,
            }}
          >
            <chakra.form onSubmit={handleSubmit(onSubmitUpdate)}>
              <Heading
                as="h2"
                variant="underline"
                pos="relative"
                top={{
                  default: "0",
                  md: "-0.5rem",
                }}
                pt={0}
                mt={0}
              >
                Your Profile
              </Heading>
              <Box>
                <Stack
                  spacing="5"
                  pl={{
                    default: "4",
                    md: 4,
                  }}
                  pr={{
                    default: "4",
                    md: 4,
                  }}
                  py={{
                    default: "5",
                    md: "0",
                  }}
                >
                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                    p={2}
                  >
                    {ratingStars()}
                  </Stack>

                  <FormControl id="company_name" isRequired mt={4}>
                    <FormLabel>Company</FormLabel>
                    <Input type="text" {...register("company_name")} />
                  </FormControl>

                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="first_name" isRequired>
                      <FormLabel>First Name (Company Representative)</FormLabel>
                      <Input type="text" {...register("first_name")} />
                    </FormControl>
                    <FormControl id="last_name" isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input type="text" {...register("last_name")} />
                    </FormControl>
                  </Stack>

                  <FormControl id="street" isRequired>
                    <FormLabel>Street (Company Address)</FormLabel>
                    <Input type="text" {...register("street")} />
                  </FormControl>

                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="city" isRequired>
                      <FormLabel>City</FormLabel>
                      <Input type="text" {...register("city")} />
                    </FormControl>
                    <FormControl isRequired id="state">
                      <FormLabel>State / Province</FormLabel>

                      <ChakraSelect {...register("state")}>
                        {Object.entries(UsaStateOptions).map(([key, value]) => (
                          <option key={key}>{value}</option>
                        ))}
                      </ChakraSelect>
                    </FormControl>
                    <FormControl id="zipcode" isRequired>
                      <FormLabel>ZIP Code</FormLabel>
                      <Input
                        type="number"
                        {...register("zipcode", {
                          minLength: {
                            value: 5,
                            message: "Must be at least 5 digits long",
                          },
                        })}
                      />
                      {errors.zipcode && errors.zipcode?.message}
                    </FormControl>
                  </Stack>

                  <Heading as="h3" size="md" pt="1rem">
                    Products Offered
                  </Heading>

                  <FormControl id="products_offered">
                    <CheckboxGroup
                      colorScheme="blue"
                      size="lg"
                      value={defaultProducts}
                      onChange={handleChange}
                    >
                      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                        {Object.entries(sortedProductOptions).map(
                          ([key, value]) => (
                            <GridItem key={key}>
                              <Checkbox
                                key={key}
                                value={value.id}
                                ml={0}
                                mr={8}
                                size="md"
                                {...register("products_offered")}
                              >
                                {captializeFirstLetter(value.name)}
                              </Checkbox>
                            </GridItem>
                          )
                        )}
                      </Grid>
                    </CheckboxGroup>
                  </FormControl>

                  <FormControl id="store_description" my={4}>
                    <FormLabel my={4}>Store Description</FormLabel>
                    <Textarea {...register("store_description")} />
                  </FormControl>
                </Stack>
              </Box>

              <Flex justifyContent="center" mt={6}>
                <Button
                  variant="primary"
                  alignSelf="start"
                  isLoading={isSubmitting}
                  type="submit"
                  mt={2}
                >
                  Save Profile
                </Button>
              </Flex>
            </chakra.form>

            <Divider />

            <chakra.form onSubmit={imageFormHandler}>
              <Box
                pl={{
                  default: "4",
                  md: 4,
                }}
                pr={{
                  default: "4",
                  md: 4,
                }}
                py={{
                  default: "5",
                  md: "0",
                }}
              >
                <Flex alignItems="center" mt={4}>
                  {/* <Box>
                      Thumbnail Image here
                    </Box> */}
                  <Box>
                    <Heading as="h3" size="md">
                      Profile Image
                    </Heading>
                    <Text fontSize="sm">
                      Only upload jpeg, jpg, png, webp, heic, or gif files | 3mb
                      max
                    </Text>
                  </Box>
                </Flex>

                {/* <Flex>
                    {...register("store_image") && (
                      <Image 
                        src={...register("store_image")} 
                        alt={...register("company_name") + "Profile Thumbnail"} 
                      />
                    )}
                  </Flex> */}

                <Stack
                  spacing="6"
                  direction={{
                    default: "column",
                    sm: "row",
                  }}
                  alignItems={{
                    sm: "center",
                  }}
                  mt={8}
                  p={2}
                >
                  <Input
                    variant="file"
                    type="file"
                    accept=".jpg,.jpeg,.png,.gif,.heic,.webp"
                    p={0}
                    borderRadius="0"
                    w="auto"
                    pt="0.3rem"
                  />

                  <Button type="submit" variant="primary" size="md" mt={4}>
                    Upload image
                  </Button>
                </Stack>

                <Text py={2}>Upload progress: {imageProgress}%</Text>
              </Box>
            </chakra.form>
          </Stack>
        </Container>
      </PageLayout>
    </Layout>
  );
};
