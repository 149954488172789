import {
  Box,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { addIsUpdateProposal } from "../../../store/features/sessionInfoSlice";
import { useGetRFP } from "../../../hooks/useGetRFP";
import { ChatDrawer } from "../../chat/ChatDrawer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { updateProposalStatus } from "../../../store/firebase/FirebaseFunctions";
import { proposalStatusOptions } from "../../utilities/utilities";
import {
  addCurrentOrder,
  addCurrentProposal,
} from "../../../store/features/sessionInfoSlice";
import { AlertPopup } from "../../platform/elements/AlertPopup";
import { Card } from "../../../UI/Card"; 

/*
Shows RFP info, proposal info, and provides action to negotiate, update, withdraw, or confirm
*/

export const ProposalInfoCard = (props) => {
  const [canUpdate, setCanUpdate] = useState(false);
  const [canConfirm, setCanConfirm] = useState(false);
  const [withdrew, setWithdrew] = useState(false);
  const [isNegotiating, setIsNegotiating] = useState(false);

  const sessionInfo = useSelector(selectSessionInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector(selectUser);

  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();

  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useDisclosure();

  let proposal = sessionInfo.currentProposal;
  let product = props.product;

  const rfp = useGetRFP(proposal);

  const price = Math.trunc(proposal.price_per);
  const units = rfp.num_units;
  const rfpEstimate = rfp?.price_per_range_end
    ? rfp?.price_per_range_end * units
    : rfp?.price_per_range_start * units;
  const proposalTotal = proposal.price_per * units;
  const statusColor = proposalStatusOptions[proposal.status][0];

  useEffect(() => {
    if (proposal.status === "withdrawn") {
      setWithdrew(true);
    } else {
      setWithdrew(false);
    }
    if (proposal.status === "open" || proposal.status === "negotiating") {
      setCanUpdate(true);
    }
    if (proposal.status === "accepted") {
      setCanConfirm(true);
    }
  }, [setWithdrew, proposal, setCanUpdate, setCanConfirm]);

  const confirmProposal = async () => {
    //create order doc
    updateProposalStatus({
      newStatus: "confirmed",
      proposalId: proposal.id,
    })
      .then((orderDoc) => {
        dispatch(addCurrentOrder(orderDoc.data));
        toast({
          position: "top",
          description: "Proposal confirmed!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        navigate(`/vendor/current-order`);
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  const withdrawProposal = () => {
    //update proposal and rfp
    updateProposalStatus({
      newStatus: "withdrawn",
      proposalId: proposal.id,
    })
      .then(() => {
        //update and dispatch to current proposal
        let newProposal = { ...proposal };
        newProposal["status"] = "withdrawn";
        dispatch(addCurrentProposal(newProposal));
        setWithdrew(true);
        toast({
          position: "top",
          description: "Proposal withdrawn!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  return (
    <Flex
      direction={{
        default: "column",
        md: "row",
      }}
    >
      <Box
        w="100%"
        ml={{
          md: "5.5rem",
        }}
        px={{
          md: "3%",
        }}
      >
        <Card 
          deliveryDate={rfp?.delivery_date_end}
          details={rfp.other_details}
          estimate={rfpEstimate}
          id={proposal.id}
          imageUrl={product?.image_url}
          imageName={product?.name}
          name={rfp.name}
          object={proposal}
          onLinkClick={addCurrentProposal(proposal)}
          onUpdateClick={addIsUpdateProposal(true)}
          price={price}
          product={product}
          productName={product.name}
          statusColor={statusColor} 
          status={proposal.status}
          to={"/vendor/current-proposal"}
          total={proposalTotal}
          type="proposal"
          units={units}
          user={user}
        />
      </Box>

      {isNegotiating && (
        <ChatDrawer
          isOpen={isOpenChat}
          onClose={onCloseChat}
          title={"Negotiate with the buyer"}
          collectionName={"buyer_vendor_proposal_chat"}
          collectionDocId={proposal.id}
        />
      )}
      <AlertPopup
        isOpen={isOpenWithdraw}
        onClose={onCloseWithdraw}
        alertHeader={"Withdraw Request"}
        alertBody={
          "Are you sure you want to withdraw this request? This is not reversible."
        }
        onSubmitAlert={withdrawProposal}
      />
      <AlertPopup
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        alertHeader={"Confirm Proposal"}
        alertBody={
          "Are you sure you want to confirm this proposal? This is not reversible."
        }
        onSubmitAlert={confirmProposal}
      />
    </Flex>
  );
};
