import {
  Box,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FiUser, FiXCircle, FiChevronDown } from "react-icons/fi";
import { items as BuyerItems } from "./BuyerProfileMenuData";
import { items as VendorItems } from "./VendorProfileMenuData";
import { Logout } from "../../components/auth/Logout";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";

/*
For Menu on top left of page. 
Load menu items depending on if user is buyer or vendor
*/

export const ProfileMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(selectUser);
  let menuItems;
  let storedImage = user["store_image"] ?? null;

  if (user.account_type === "buyer") {
    menuItems = BuyerItems;
  }
  if (user.account_type === "vendor") {
    menuItems = VendorItems;
  }

  return (
    <Fragment>
      <Box
        order={{
          default: "1",
          md: "0",
        }}
      >
        <Menu>
        {!storedImage ? (
          <MenuButton
            as={IconButton}
            position="relative"
            background="transparent"
            aria-label="Open Profile Menu"
            icon={<FiUser fontSize="1.3rem" />}
            m={{
              default: "0 1px 0 0",
              md: "0 0 1rem 0",
            }}
            _hover= {{
              background: "gray.100"
            }}
            />
            ) : (
            <MenuButton
              position="relative"
              background="transparent"
              aria-label="Open Profile Menu"
              mb={{
                default: "0",
                md: "1rem",
              }}
              _hover= {{
                background: "gray.100"
              }}
            >
              <Image
                display="flex" 
                src={storedImage}
                alt="Profile Image"
                draggable="false"
                w="40px"
                h="auto"
                overflow="hidden"
                clipPath="polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)"
              />
              <Icon 
                position="absolute"
                bottom="-14px"
                left="0"
                right="0"
                margin="0 auto"
                as={FiChevronDown} 
              />
            </MenuButton>
          )}
          

          <MenuList zIndex="5">
            <Stack>
              {menuItems && (
                menuItems.map((item, id) => (
                    <MenuItem key={id}>
                      <Link variant="menu" href={item.href}>
                        <Stack spacing="4" direction="row" p="1">
                          <Icon as={item.icon} boxSize="6" color="accent" />
                          <Stack spacing="1">
                            <Text fontWeight="medium">{item.title}</Text>
                            <Text fontSize="xsm" color="gray.600">
                              {item.description}
                            </Text>
                          </Stack>
                        </Stack>
                      </Link>
                    </MenuItem>
                ))
              )}
              
              <MenuDivider />
              <MenuItem key={"logout"} onClick={() => onOpen()}>
                <Stack spacing="4" direction="row" p="1">
                  <Icon as={FiXCircle} boxSize="6" color="accent" />
                  <Stack spacing="1">
                    <Text fontWeight="medium">Logout</Text>
                  </Stack>
                </Stack>
              </MenuItem>
            </Stack>
          </MenuList>
        </Menu>
      </Box>
      <Logout isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
};
