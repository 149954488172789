import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  Skeleton,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  FiEye,
  FiClipboard,
  FiSearch,
  FiCalendar,
  FiDollarSign,
} from "react-icons/fi";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { useNavigate } from "react-router-dom";
import {
  captializeFirstLetter,
  getDateString,
} from "../../utilities/utilities";
import { addRecreateRfpObject } from "../../../store/features/sessionInfoSlice";
import { useDispatch } from "react-redux";
import { addCurrentOrder } from "../../../store/features/sessionInfoSlice";
import { Link as RouteLink } from "react-router-dom";
var pluralize = require("pluralize");

export const CurrentOrderCard = (props) => {
  const order = props.order;
  const product = useGetProduct(order);
  const dispatch = useDispatch();
  const user = props.user;
  const navigate = useNavigate();
  let productName = captializeFirstLetter(product.name);
  let unitText = pluralize("Unit", order.num_units);

  const total = order.price_per * order.num_units;

  //let start_date = order.delivery_date_start;
  let end_date = order.delivery_date_end;

  const recreateRfp = () => {
    let oldRfp = {
      name: order.name,
      product: order.product,
      num_units: order.num_units,
      city: order.city,
      state: order.state,
      zipcode: order.zipcode,
      price_per_range_start: order.price_per,
      price_per_range_end: order.price_per,
    };
    dispatch(addRecreateRfpObject(oldRfp));
    navigate("/buyer/create-rfp");
  };

  let total_units = order.num_units;
  let num_sofar;
  if (user.account_type === "buyer") {
    num_sofar = order.num_units_received;
  } else {
    num_sofar = order.num_units_delivered;
  }

  return (
    <Flex
      maxW="40rem"
      direction="column"
      p={0}
      border="1px solid"
      borderTopWidth="3px"
      borderColor="gray.300"
      className="card"
    >
      <div className="top-left-angle-white"></div>
      <Flex flex="0 0 auto" p="0.7rem 1rem">
        <Tag
          as="div"
          flex="0 0 auto"
          bg="transparent"
          borderColor="magenta.500"
          color="magenta.500"
          size="sm"
        >
          Order Pending
        </Tag>
      </Flex>
      <Box flex="0 0 auto" position="relative" maxH="10rem" overflow="hidden">
        <AspectRatio ratio={4 / 3}>
          {/* <Link
            to={`/${user.account_type}/current-order`}
            onClick={() => dispatch(addCurrentOrder(order))}
          > */}
          <Image
            src={product?.image_url}
            alt={product?.name}
            draggable="false"
            fallback={<Skeleton />}
          />
          {/* </Link> */}
        </AspectRatio>
      </Box>

      <Flex direction="column" p={0} flex="1 1 auto">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          flex="1 1 auto"
          p={4}
          pb={2}
        >
          <Link
            as={RouteLink}
            to={`/${user.account_type}/current-order`}
            onClick={() => dispatch(addCurrentOrder(order))}
          >
            <Text fontSize="lg" fontWeight="bold">
              {order.name}
            </Text>
          </Link>
          {user.account_type === "buyer" && (
            <Text fontSize="sm">{order.company_name}</Text>
          )}

          <Text fontSize="sm" mb="0.5rem">
            {order.city}
            {", "}
            {order.state} {order.zipcode}
          </Text>

          <Flex alignItems="center">
            <Container p={0} flex="1 1 50%">
              <Text fontSize="sm" color="gray.500">
                Confirmed Price:
              </Text>
              <Text fontSize="xxl" fontWeight="extrabold" lineHeight="2.5rem">
                <Text as="span" fontSize="md" position="relative" top="-1rem">
                  {" "}
                  ${" "}
                </Text>
                {total}
              </Text>
            </Container>

            <Container p={0} flex="1 1 50%">
              <Text>
                {order.num_units} {productName} {unitText}
                {/* <Text as="span" fontSize="sm">
                  Units
                </Text> */}
              </Text>
              <Text>
                ${order.price_per}{" "}
                <Text as="span" fontSize="sm">
                  per unit
                </Text>
              </Text>
            </Container>
            <Flex align="center" pr={6}>
              <Icon as={FiDollarSign} fontSize="lg" color="gray.500" />
            </Flex>
          </Flex>

          <Divider />

          <Flex py={2}>
            <Flex direction="column" flex="1 1 100%">
              <Text as="span" fontSize="sm" color="gray.500">
                Deadline{" "}
              </Text>
              <Text as="span" fontSize="md" fontWeight="bold">
                {getDateString(end_date)}
              </Text>
            </Flex>
            <Flex align="center" pr={6}>
              <Icon as={FiCalendar} fontSize="lg" color="gray.500" />
            </Flex>
            {/* <Flex direction="column" flex="1 1 50%">
              <Text as="span" fontSize="sm">
                Proposed delivery date{" "}
              </Text>
              <Text as="span" fontSize="md" fontWeight="bold">
                {formatDate(proposal.delivery_date_end)}{" "}
              </Text>
            </Flex> */}
          </Flex>

          <Divider />

          <Box my={4}>
            <Text as="span" fontSize="sm" color="gray.500">
              Status{" "}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              {num_sofar} / {total_units}{" "}
            </Text>
            <Text as="span" fontSize="sm" aria-hidden>
              {user.account_type === "vendor" ? "Delivered" : "Received"}
            </Text>
            <Box srOnly>
              {num_sofar} out of {total_units}
            </Box>
          </Box>

          <ButtonGroup
            w="100%"
            justifyContent="space-between"
            pr={{
              default: "20px",
              md: "20px",
            }}
          >
            <Link
              as={RouteLink}
              to={`/${user.account_type}/current-order`}
              onClick={() => dispatch(addCurrentOrder(order))}
              variant="simpleIconLinkStacked"
            >
              <Icon boxSize={5} as={FiEye} />
              <Text as="span">View Order</Text>
            </Link>
            {user.uid === order.vendor ? (
              <Button
                variant="stacked"
                isDisabled={true}
                padding={{
                  default: "0.15rem",
                  md: "0.5rem",
                }}
                my={{
                  md: "0.5rem",
                }}
              >
                <Icon boxSize={5} as={FiSearch} />
                <Text as="span">Find Similar RFP</Text>
              </Button>
            ) : (
              <Button
                variant="stacked"
                onClick={recreateRfp}
                padding={{
                  default: "0.15rem",
                  md: "0.5rem",
                }}
                my={{
                  md: "0.5rem",
                }}
              >
                <Icon boxSize={5} as={FiClipboard} />
                <Text as="span">Create Similar RFP</Text>
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </Flex>
      <div className="bottom-right-angle-white"></div>
    </Flex>
  );
};
