import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const LoginFull = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    height="auto"
    width="auto"
    maxW="60rem"
    viewBox="0 0 726 235"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
        <polygon fill="#003B4F" points="472.499939,129.6631927 472.499939,0.3368071 248.500061,0.3368071 248.500061,129.6631927 360.5,194.3263855 	"/>
        <g>
            <g>
                <polygon fill="#FFCE00" points="377.8190613,51.1249657 374.4094238,49.4738426 361.1137695,56.6917038 347.7489014,49.4738426 344.4066162,51.243576 344.4066162,55.0842896 357.7724304,63.017189 357.7724304,76.80336 361.1137695,78.4739075 364.4553528,76.80336 364.4553528,63.017189 377.8190613,55.0842896 			"/>
                <g>
                    <polygon fill="#FFCE00" points="387.2325745,41.9848938 363.4299011,28.5980263 363.4205627,18.9824123 361.0992737,18 358.7758789,18.9861488 358.7861633,28.6029358 334.9659424,42.0017319 339.8113098,44.6834602 361.1142578,32.7009392 382.3909607,44.6696625 				"/>
                    <polygon fill="#FFCE00" points="336.6844482,51.6613235 331.9719543,48.8923607 331.9719543,73.8764801 323.6292725,78.6259308 323.9211731,81.130127 325.9264526,82.6638412 334.3093872,77.8900757 356.8943481,90.5939331 356.8983154,85.1897812 336.6844482,73.8201218 				"/>
                    <polygon fill="#FFCE00" points="390.2558289,73.3125305 390.2558289,48.9349251 385.5428467,51.6854172 385.5428467,73.8201218 365.2459412,85.2370377 365.2536621,90.6388397 388.4488831,77.5902023 397.28302,81.3754883 399.1028137,79.629631 399.1130981,77.1064911 				"/>
                </g>
            </g>
            <g>
                <path fill="#FFCE00" d="M291.7818604,108.4273529h8.216156l-6.9100342,11.6129379V128.75h-7.3156128v-8.7097015l-6.9100342-11.6129379h8.2164001l2.3512268,5.1096649C289.4299622,113.5370255,291.7818604,108.4273605,291.7818604,108.4273529z"/>
                <path fill="#FFCE00" d="M307.8656616,128.75v-20.3226471h13.5576172v5.4874344h-6.2705383v1.9159164h5.6614685v5.3129196h-5.6614685v1.916153h6.4160156v5.690239h-13.7030945V128.75z"/>
                <path fill="#FFCE00" d="M331.20755,128.75v-20.3226471h13.5574036v5.4874344h-6.2710266v1.9159164h5.6612244v5.3129196h-5.6612244v1.916153h6.4162598v5.690239H331.20755V128.75z"/>
                <path fill="#FFCE00" d="M354.5487366,128.75v-20.3226471h7.3743286v6.8225555h4.2673645v-6.8225555h7.2870789V128.75h-7.2870789v-7.1418381h-4.2673645V128.75H354.5487366z"/>
                <path fill="#FFCE00" d="M383.4063721,128.75v-20.3226471h7.2583008V128.75H383.4063721z"/>
                <path fill="#FFCE00" d="M412.8447571,108.4273529h7.664856L412.7582092,128.75h-6.4167175l-7.7803955-20.3226471h7.6938477l3.3095398,11.2354126L412.8447571,108.4273529z"/>
                <path fill="#FFCE00" d="M428.4350281,128.75v-20.3226471h13.5576172v5.4874344h-6.2705383v1.9159164h5.6611938v5.3129196h-5.6611938v1.916153h6.4155579v5.690239h-13.7026367V128.75z"/>
            </g>
        </g>
        <rect fill="#b3b7b7" x="294.5846252" y="116.500061" transform="matrix(0.8614317 -0.5078735 0.5078735 0.8614317 13.3271837 282.8460999)" width="460.83078" height="0.9998798"/>
        <rect fill="#b3b7b7" x="200.500061" y="-113.4153824" transform="matrix(0.5077786 -0.8614876 0.8614876 0.5077786 -1.8575472 230.7489166)" width="0.9998798" height="460.83078"/>
    </g>
  </chakra.svg>
);
