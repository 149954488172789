import {
  Stack,
  Container,
} from "@chakra-ui/react";

export function PageLayout(props) {
  return (
    <Container
      maxW="100%"
      pr={{
        base: 0,
        md: 20,
      }}
      pl={{
        base: 0,
        md: 0,
      }}
      className="pageLayout_Container"
    >
      <Stack
        spacing="5"
        px={{
          base: "0",
          md: "0",
        }}
        py={{
          base: "0",
          md: "0",
        }}
        className="pageLayout_Stack"
      >
        {props.children}
      </Stack>
    </Container>
  );
}
