import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Link,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { LoginFull } from "../../UI/Icons/LoginFull";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Footer } from "../../UI/Footer";
import { Fragment, useEffect } from "react";
import {
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
  useAuthState,
} from "react-firebase-hooks/auth";
import { auth } from "../../store/firebase/Firebase";
import { GoogleIcon } from "../../UI/Icons/ProviderIcons";
import { useDispatch } from "react-redux";
import {
  getMyUserInfo,
  createUser,
} from "../../store/firebase/FirebaseFunctions";
import { addUser } from "../../store/features/userSlice";

export const Login = () => {
  const [signInWithEmailAndPassword, userEmail, loadingEmail, errorEmail] =
    useSignInWithEmailAndPassword(auth);
  const [userAuth] = useAuthState(auth);
  const [signInWithGoogle, userGoogle, loadingGoogle, errorGoogle] =
    useSignInWithGoogle(auth);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm({ mode: "onChange" });

  //handle errors
  useEffect(() => {
    if (errorEmail || errorGoogle) {
      let errorMsg;
      if (errorEmail) {
        errorMsg = errorEmail.message;
      } else if (errorGoogle) {
        errorMsg = errorGoogle.message;
      }
      toast({
        position: "top",
        description: errorMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [errorEmail, errorGoogle, toast]);

  //after logging in
  useEffect(() => {
    let loggedIn = false;
    if (userAuth) {
      loggedIn = true;
    }
    if (userGoogle || userEmail) {
      loggedIn = true;
    }
    if (loggedIn) {
      //check to see if user exists
      getMyUserInfo()
        .then((docs) => {
          dispatch(addUser(docs.data));
          toast({
            position: "top",
            description: "Logged in!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // navigate("/home");
        })
        .catch((error) => {
          //google user signed in without registrating so send to registration flow
          //create user and send to complete registration
          let user = userGoogle;
          let userData = {
            uid: user.user.uid,
            email: user.user.email,
            emailVerified: user.user.emailVerified,
            providerId: user.user.providerData[0].providerId,
          };

          createUser(userData).then((result) => {
            const newUser = result.data.newData;
            if (newUser) {
              dispatch(addUser(newUser));
              toast({
                position: "top",
                description: "Account created!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              navigate("/complete-register");
            }
          });
        });
    }
  }, [userAuth, userEmail, userGoogle, dispatch, toast, navigate]);

  const onSubmit = async (data) => {
    signInWithEmailAndPassword(data["email"], data["password"]);
  };

  return (
    <Fragment>
      <Box 
        as="section" 
        flex="1 1 auto"
        maxW="1800px"
        m="0 auto"
        pb={50}
        bg="white"
      >
        <Container
          maxW="100%"
          px="0"
          py={{
            default: "0",
            md: "0",
          }}
        >
          <Container
            maxW="100%"
            centerContent
            borderTop="0.75rem solid"
            borderColor="blue.300"
            overflow="hidden"
            p={0}
          >
            <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
              <LoginFull pos="relative" top="-1px" w="175%" />
            </Link>
          </Container>
          <Container 
            maxW="40rem"
            p={{
              default: 4,
              sm: 20,
            }} 
            pt={{
              sm: 10,
            }}
          >
            <Stack spacing="8">
              <Stack spacing="6"></Stack>
              <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <FormControl id="email" isInvalid={errors.email} isRequired>
                      <FormLabel 
                        fontSize={{
                          default: "md",
                          md: "lg",
                        }}
                      >Email address</FormLabel>
                      <Input
                        type="text"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      id="password"
                      isInvalid={errors.password}
                      isRequired
                    >
                      <FormLabel 
                        fontSize={{
                          default: "md",
                          md: "lg",
                        }}
                      >Password</FormLabel>
                      <Input
                        type="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.password && errors.password?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <HStack justify="space-between">
                    <Checkbox defaultChecked>Remember me</Checkbox>
                    <Link href="/forgot-password">Forgot password?</Link>
                  </HStack>
                  <Stack spacing="4">
                    <Button
                      variant="primary"
                      isLoading={loadingEmail}
                      type="submit"
                      m="2rem 3rem"
                    >
                      Sign in
                    </Button>

                    <Text align="center" fontSize="sm" pb={4}>
                      OR
                    </Text>

                    <Button
                      border="1px"
                      variant="secondary"
                      leftIcon={<GoogleIcon boxSize="5" />}
                      iconSpacing="3"
                      isLoading={loadingGoogle}
                      onClick={() => {
                        signInWithGoogle();
                      }}
                    >
                      Sign in with Google
                    </Button>
                  </Stack>
                </Stack>
              </chakra.form>
              <HStack spacing="1" justify="center">
                <Text>Don't have an account?</Text>
                <Link href="/register">Sign up</Link>
              </HStack>
            </Stack>
          </Container>
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
};
