import millify from "millify";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  chakra,
  useToast,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { useEffect } from "react";
import { addCurrentProposal } from "../../../store/features/sessionInfoSlice";
import { captializeFirstLetter } from "../../utilities/utilities";
import { addIsUpdateProposal } from "../../../store/features/sessionInfoSlice";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { rfpStatusOptions } from "../../utilities/utilities";
import {
  addNewProposal,
  updateProposal,
} from "../../../store/firebase/FirebaseFunctions";
import { useState } from "react";
import { Card } from "../../../UI/Card"; 

export const CreateProposal = () => {
  const toast = useToast();
  const user = useSelector(selectUser);
  const sessionInfo = useSelector(selectSessionInfo);
  const [isProposing, setIsProposing] = useState(false);
  const appInfo = useSelector(selectAppInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rfp = sessionInfo.currentRfp;
  const proposal = sessionInfo.currentProposal;
  let existing = rfp;
  let existing_price_value = rfp.price_per_range_start;

  //if updating proposal then populate with proposal values, otherwise rfp values
  if (sessionInfo.isUpdateProposal) {
    existing = sessionInfo.currentProposal;
    existing_price_value = existing.price_per;
  }

  const product = useGetProduct(existing);
  const price = rfp.price_per_range_end ? "$" + (rfp.price_per_range_start + " - $" + rfp.price_per_range_end) : "$" + rfp.price_per_range_start;
  const total = rfp.price_per_range_end ? 
                  millify(rfp.price_per_range_start * rfp.num_units, { lowercase: true }) + "-" + millify(rfp.price_per_range_end * rfp.num_units, { lowercase: true }) 
                : millify(rfp.price_per_range_start * rfp.num_units, { lowercase: true });
  const statusColor = rfpStatusOptions[rfp.status][0];
  const openProposals = appInfo.allProposals?.filter(function (proposal) {
    return (
      proposal.rfp_id === rfp.id &&
      proposal.status !== "rejected" &&
      proposal.status !== "withdrawn"
    );
  });

  let numProposals = openProposals?.length ?? 0;

  let today = new Date().toISOString().split("T")[0];
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //fill with rfp and known company info from profile
  useEffect(() => {
    //pre-populate form
    setValue("company_name", user["company_name"]);
    setValue("first_name", user["first_name"]);
    setValue("last_name", user["last_name"]);
    setValue("num_units", existing.num_units);
    setValue("price_per", existing_price_value);

    let today = new Date().toISOString().split("T")[0];
    if (today < existing.delivery_date_start) {
      setValue("delivery_date_start", existing.delivery_date_start);
    } else {
      setValue("delivery_date_start", today);
    }
    setValue("delivery_date_end", existing.delivery_date_end);
  }, [setValue, user, existing, existing_price_value]);

  const onSubmit = async (data) => {
    setIsProposing(true);
    data["rfp_id"] = rfp.id;
    data["product"] = product.id;

    //if not updating, then create new proposal doc and add to rfp
    if (!sessionInfo.isUpdateProposal) {
      // Add a new document with a generated id.
      addNewProposal(data)
        .then((resultProposal) => {
          toast({
            position: "top",
            description: "Proposal created and submitted!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch(addCurrentProposal(resultProposal.data));
          navigate("/vendor/current-proposal");
        })
        .catch(() => {
          setIsProposing(false);
          toast({
            position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else if (sessionInfo.isUpdateProposal) {
      //if updating then overwrite existing proposal
      let functionData = { proposalId: proposal.id, proposalData: data };
      updateProposal(functionData)
        .then((resultProposal) => {
          toast({
            position: "top",
            description: "Proposal created and submitted!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch(addIsUpdateProposal(false));
          dispatch(addCurrentProposal(resultProposal.data));
          navigate("/vendor/current-proposal");
        })
        .catch(() => {
          setIsProposing(false);
          toast({
            position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "10.5rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Proposal Form
          </Heading>

          <Flex
            direction={{
              default: "column",
              md: "row",
            }}
            px={{
              default: "2",
              md: "0",
            }}
            py={{
              default: "2",
              md: "0",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
              borderBottom={{
                default: "4px solid",
                sm: "0",
              }}
              borderColor="gray.200"
              mb={{
                default: "1rem",
                sm: "0",
              }}
            >
              <Flex
                direction={{
                  default: "column",
                  md: "row",
                }}
              >
                <Box
                  w="100%"
                  ml={{
                    md: "5.5rem",
                  }}
                  px={{
                    md: "3%",
                  }}
                >
                  <Card 
                    deliveryDate={rfp?.delivery_date_end ? rfp?.delivery_date_end : rfp?.delivery_date_start}
                    details={rfp.other_details}
                    estimate={rfp.price_per_range_end * rfp.num_units}
                    id={rfp.id}
                    imageUrl={product?.image_url}
                    imageName={product?.name}
                    name={rfp.name}
                    object={rfp}
                    onLinkClick={null}
                    onUpdateClick={""}
                    price={price}
                    product={product}
                    productName={product.name}
                    proposalCount={numProposals}
                    statusColor={statusColor} 
                    status={rfp.status}
                    to={"/" + user.account_type + "/current-rfp"}
                    total={total}
                    units={rfp.num_units}
                    type="request"
                    user={user}
                  />
                </Box>
              </Flex>
            </Box>
            
            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 0,
                md: "1rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              <Stack
                spacing="5"
                pl={{
                  default: "4",
                  md: 4,
                }}
                pr={{
                  default: "4",
                  md: 4,
                }}
                py={{
                  default: "5",
                  md: "0",
                }}
              >
                <chakra.form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="company_name" isRequired>
                    {/* <FormLabel>Company Name</FormLabel> */}
                    <Input
                      isDisabled
                      type="hidden"
                      {...register("company_name")}
                    />
                  </FormControl>

                  <Stack
                    spacing="6"
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="first_name" isRequired>
                      {/* <FormLabel>First Name</FormLabel> */}
                      <Input type="hidden" {...register("first_name")} />
                    </FormControl>
                    <FormControl id="last_name" isRequired>
                      {/* <FormLabel>Last Name</FormLabel> */}
                      <Input type="hidden" {...register("last_name")} />
                    </FormControl>
                  </Stack>

                  <Heading as="h3" size="md" pt="1rem" pb="1rem">
                    Enter your proposal details:
                  </Heading>

                  <Text fontSize="lg" mb={2}>
                    Price Quote
                  </Text>

                  <Flex
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                    alignItems="flex-end"
                    gap={8}
                  >
                    <FormControl
                      id="price_per"
                      isRequired
                      isInvalid={errors.price_per}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      flex="0 1 auto"
                      w="auto"
                    >
                      <Flex alignItems="center" gap={2}>
                        <FormLabel m={0} flex="0 0 auto" fontSize="lg">$</FormLabel>
                        <NumberInput min={0} precision={2} step={0.01} maxW={40} flex="1 1 auto">
                          <NumberInputField
                            id="price_per"
                            {...register("price_per", {
                              validate: (value) =>
                                parseFloat(value) > 0 ||
                                "Amount must be greater than 0",
                            })}
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Text as="span" flex="0 0 auto" fontSize="sm" color="gray.500">per unit</Text>
                      </Flex>
                      <FormErrorMessage>
                        {errors.price_per && errors.price_per?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <Text as="div" flex="0 1 auto" m="0" align="center" fontSize="xxl" fontWeight="light" lineHeight="2.5rem" color="gray.700">X</Text>

                    <FormControl
                      id="num_units"
                      isRequired
                      isInvalid={errors.num_units}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      flex="0 1 auto"
                      w="auto"
                    >
                      <Flex alignItems="center" gap={2}>
                        <FormLabel m={0} flex="0 0 auto" fontSize="sm" color="gray.500">Number of units</FormLabel>
                        <NumberInput min={0} maxW={40} flex="1 1 auto">
                          <NumberInputField
                            id="num_units"
                            {...register("num_units", {
                              validate: (value) =>
                                parseFloat(value) > 0 ||
                                "Amount must be greater than 0",
                            })}
                          />

                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                      <FormErrorMessage>
                        {errors.num_units && errors.num_units?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>

                  <Box mt={8} mb={2}>
                    <Text fontSize="lg">
                      Delivery Date
                    </Text>
                    <Text fontSize="sm">
                      Set the same date for start and end if you don't want to
                      set a range.
                    </Text>
                  </Box>

                  <Flex
                    direction={{
                      default: "column",
                      md: "row",
                    }}
                    mb={8}
                  >
                    <FormControl
                      id="delivery_date_start"
                      isInvalid={errors.delivery_date_start}
                      isRequired
                    >
                      <FormLabel as="legend" fontSize="sm" color="gray.500">Start Date</FormLabel>
                      <Input
                        width="100"
                        type="date"
                        min={today}
                        {...register("delivery_date_start", {
                          required: "Pick a date today or later",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.delivery_date_end &&
                          errors.delivery_date_end?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="delivery_date_end"
                      isInvalid={errors.delivery_date_end}
                      isRequired
                    >
                      <FormLabel as="legend" fontSize="sm" color="gray.500">End Date</FormLabel>
                      <Input
                        width="100"
                        type="date"
                        min={watch("delivery_date_start")}
                        {...register("delivery_date_end", {
                          validate: (value) =>
                            value >= watch("delivery_date_start") ||
                            "Amount must be greater than starting range",

                          required: "Pick a date today or later",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.delivery_date_end &&
                          errors.delivery_date_end?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  
                  <FormControl
                    id="other_details"
                    isInvalid={errors.other_details}
                    mb={8}
                  >
                    <FormLabel htmlFor="other_details" fontSize="sm" color="gray.500">Other Details</FormLabel>
                    <Textarea
                      placeholder="Provide any other details"
                      type="text"
                      {...register("other_details")}
                    />
                    <FormErrorMessage>
                      {errors.other_details && errors.other_details?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Divider mb={8} />

                  <Flex justifyContent={"center"}>
                    <Button
                      variant="primary"
                      alignSelf="start"
                      isLoading={isProposing}
                      type="submit"
                    >
                      Propose
                    </Button>
                  </Flex>
                </chakra.form>
              </Stack>

            </Box>
            </Flex>
        </Container>
      </PageLayout>
    </Layout>
  );
};
