import { getMenuItemTimestamp, orderStatusOptions } from "../../utilities/utilities";
import { selectUser } from "../../../store/features/userSlice";
import { useSelector } from "react-redux";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { useGetRFP } from "../../../hooks/useGetRFP";
import { addCurrentOrder } from "../../../store/features/sessionInfoSlice";
import { addIsUpdateProposal } from "../../../store/features/sessionInfoSlice";
import { Card } from "../../../UI/Card";

export const OrderMenuCard = (props) => {
  const user = useSelector(selectUser);
  const order = props.order;


  const product = useGetProduct(order);
  const rfp = useGetRFP(order);
  
  const price = "$" + order.price_per;
  
  const statusColor = orderStatusOptions[order.status][0];
  const units = order.num_units;
  const rfpEstimate = rfp?.price_per_range_end ? (rfp.price_per_range_end * units) : "" ;
  const timestamp = getMenuItemTimestamp(order);
  const total = (order.price_per * units).toLocaleString("en-US");


  return (
    <Card 
      deliveryDate={order?.delivery_date_end ? order?.delivery_date_end : order?.delivery_date_start}
      details={order.other_details}
      estimate={rfpEstimate}
      id={order.id}
      imageName={product?.name}
      imageUrl={product?.image_url}
      name={order.name}
      object={order}
      onLinkClick={addCurrentOrder(order)}
      onUpdateClick={addIsUpdateProposal(true)}
      price={price}
      product={product}
      productName={product?.name}
      status={order.status}
      statusColor={statusColor} 
      to={"/buyer/current-order"}
      total={total}
      type="order"
      units={units}
      user={user}
    />
  );
};
