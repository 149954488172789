import { Text, SimpleGrid, Divider, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  getDateString,
  captializeFirstLetter,
} from "../../utilities/utilities";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { addCurrentStorefront } from "../../../store/features/sessionInfoSlice";
import { useGetVendor } from "../../../hooks/useGetVendor";
var pluralize = require("pluralize");

export const ProposalInfoPopup = (props) => {
  let proposal = props.proposal;
  let product = props.product;

  const dispatch = useDispatch();

  const vendor = useGetVendor(proposal);

  /*   let start_date = getDateString(proposal.delivery_date_start);
  let end_date = getDateString(proposal.delivery_date_end);
 */
  let productName = captializeFirstLetter(product.name);
  let unitText = pluralize("Unit", proposal.num_units);

  return (
    <SimpleGrid columns={1} spacing="4px" p={2}>
      <Text fontSize="lg" fontWeight="extrabold" mb={1}>
        Vendor{" "}
      </Text>
      <Text fontSize="md" fontWeight="bold">
        {proposal.company_name}
      </Text>

      <Text>{vendor.street}</Text>
      <Text>
        {vendor.city}, {vendor.state} {vendor.zipcode}
      </Text>
      <Link
        as={RouterLink}
        isExternal
        onClick={() => dispatch(addCurrentStorefront(vendor))}
        to="/buyer/vendor-storefront"
        mt={2}
      >
        See storefront <ExternalLinkIcon mx="2px" />
      </Link>
      <Divider my={2} />
      <Text fontSize="lg" fontWeight="extrabold">
        Proposal
      </Text>
      <Text>
        Delivery between {getDateString(proposal.delivery_date_start)} {" - "}{" "}
        {getDateString(proposal.delivery_date_end)}
      </Text>
      <Text>
        {proposal.num_units} {productName} {unitText}
      </Text>
      <Text>
        ${proposal.price_per} per unit for {proposal.num_units}{" "}
        <Text as="span" fontSize="sm">
          unit(s)
        </Text>
      </Text>
      <Text mt={3}>{proposal.other_details}</Text>
    </SimpleGrid>
  );
};
