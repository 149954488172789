import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";
import { BuyerHome } from "../buyer/BuyerHome";
import { VendorHome } from "../vendor/home/VendorHome";
import { CompleteProfileModal } from "./profiles/CompleteProfileModal";
import { useDisclosure } from "@chakra-ui/react";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const user = useSelector(selectUser);

  const {
    isOpen: isOpenCompleteProfile,
    onOpen: onOpenCompleteProfile,
    onClose: onCloseCompleteProfile,
  } = useDisclosure();

  //add to fix infinite loop error with login to home
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) return navigate("/login");
  }, [user, navigate]);

  //only show once on load
  useEffect(() => {
    //check to see if most of profile has been completed
    if (
      !user?.first_name ||
      !user?.last_name ||
      !user?.street ||
      !user?.city ||
      !user?.state ||
      !user?.zipcode
    ) {
      onOpenCompleteProfile();
    }
    // eslint-disable-next-line
  }, []);

  const TypeReturn = () => {
    if (user.account_type === "buyer") {
      return <BuyerHome />;
    }
    if (user.account_type === "vendor") {
      return <VendorHome />;
    }
  };

  return (
    <Fragment>
      {TypeReturn()}
      <CompleteProfileModal
        isOpen={isOpenCompleteProfile}
        onClose={onCloseCompleteProfile}
      />
    </Fragment>
  );
};
