import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { BuyerProfile } from "./BuyerProfile";
import { VendorProfile } from "./VendorProfile";

export const Profile = () => {
  const user = useSelector(selectUser);

  if (user.account_type === "buyer") {
    return <BuyerProfile />;
  }
  if (user.account_type === "vendor") {
    return <VendorProfile />;
  }
};
