import millify from "millify";
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Icon,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { selectAppInfo } from "../../../../store/features/appInfoSlice";
import { selectSessionInfo } from "../../../../store/features/sessionInfoSlice";
import { getDateString } from "../../../utilities/utilities";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ProposalInfoPopup } from "../../../platform/proposals/ProposalInfoPopup";
import { proposalStatusOptions } from "../../../utilities/utilities";
import { updateProposalStatus } from "../../../../store/firebase/FirebaseFunctions";
import {
  FiCheckCircle,
  FiXCircle,
  FiMessageCircle,
  FiCalendar,
  FiDollarSign,
} from "react-icons/fi";
import { ChatDrawer } from "../../../chat/ChatDrawer";
import { AlertPopup } from "../../../platform/elements/AlertPopup";
import { DetailsModal } from "../../../platform/elements/DetailsModal";

export const LeadProposalTabActions = (props) => {
  const appInfo = useSelector(selectAppInfo);
  const allProposals = appInfo.allProposals;
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;
  const rfpEstimate = rfp?.price_per_range_end
    ? rfp?.price_per_range_end * rfp.num_units
    : rfp?.price_per_range_start * rfp.num_units;

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    isOpen: isOpenAccept,
    onOpen: onOpenAccept,
    onClose: onCloseAccept,
  } = useDisclosure();

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();

  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();

  const [myProposals, setMyProposals] = useState(getRelevantProposals());

  const [modalData, setModalData] = useState();
  const [acceptedProposals, setAcceptedProposals] = useState(
    getAcceptedProposals()
  );
  const [isNegotiating, setIsNegotiating] = useState(false);
  const toast = useToast();

  //get proposals for this rfp
  function getRelevantProposals() {
    const relevantProposals = allProposals.filter(function (proposal) {
      return proposal.rfp_id === rfp.id;
    });
    return relevantProposals;
  }

  function getAcceptedProposals() {
    const acceptedProposals = allProposals.filter(function (proposal) {
      return proposal.rfp_id === rfp.id && proposal.status === "accepted";
    });
    return acceptedProposals;
  }

  const proposalToRFPDifference = (pricePer, numUnits, rfpEstimate) => {
    let differenceAmount = "";
    let differenceTotal = (pricePer * numUnits) - rfpEstimate;
    if(differenceTotal === 0) {
      differenceAmount = "";
    } else {
      if (differenceTotal > 0) {
        differenceAmount = "$" + differenceTotal;
      } else {
        differenceAmount = "$" + Math.abs(differenceTotal);
      }
    }
    return differenceAmount;
  };

  const determineDiff = (pricePer, numUnits, rfpEstimate) => {
    let difference = "";
    let differenceTotal = (pricePer * numUnits) - rfpEstimate;
    if(differenceTotal === 0) {
      difference = "Matches estimate";
    } else {
      if (differenceTotal > 0) {
        difference = "over estimate";
      } else {
        difference = "under estimate";
      }
    }
    return difference;
  };

  const getDifferenceColor = (pricePer, numUnits, rfpEstimate) => {
    let differenceColor = "";
    let differenceTotal = pricePer * numUnits - rfpEstimate;
    if (differenceTotal > 0) {
      differenceColor = "red.500";
    } else {
      differenceColor = "green";
    }
    return differenceColor;
  };

  const rejectProposal = () => {
    //update proposal doc
    updateProposalStatus({
      newStatus: "rejected",
      proposalId: modalData.id,
    })
      .then(() => {
        let newArray = myProposals.filter(
          (proposal) => proposal.id !== modalData.id
        );
        setMyProposals(newArray);
        toast({
          position: "top",
          description: "Proposal rejected!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onCloseReject();
      })

      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  const acceptProposal = () => {
    //update proposal doc
    updateProposalStatus({
      newStatus: "accepted",
      proposalId: modalData.id,
    })
      .then(() => {
        let newProposal = { ...modalData };
        newProposal["status"] = "accepted";
        setAcceptedProposals([newProposal]);
        toast({
          position: "top",
          description: "Proposal accepted! Just need the vendor to confirm!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) =>
        toast({
          position: "top",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );

    onCloseAccept();
  };

  const allProposalsStack = () => {
    return myProposals.length > 0 ? (
      <Stack spacing="5" flex="1" p={5}>
        {myProposals && (
          <List listStyleType="none">
            <Stack spacing="3" width="full">
              {myProposals.map((proposal) =>
                proposal ? (
                  <ListItem
                    key={proposal.id}
                    position="relative"
                    my={1}
                    px={0}
                    pb={4}
                    borderBottom="2px solid"
                    borderColor="gray.200"
                  >
                    <Flex
                      direction={{
                        default: "column",
                        md: "row",
                      }}
                      flexWrap={{
                        md: "wrap",
                      }}
                    >
                      {/* Need to add this when we get vendor avatars */}
                      {/* <Box>
                          <Stack alignItems="center">
                            <Box
                              w={16}
                              h={16}
                              bg="blue.100"
                              clipPath="polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)"
                              className="vendor_avatar"
                            ></Box>
                            <Text fontSize="sm">RATING</Text>
                            <Text>[count] / 10</Text>
                          </Stack>
                        </Box> */}
                      <Stack 
                        order={{
                          default: "0",
                          md: "1",
                        }}
                        minWidth="20rem"
                        shouldWrapChildren 
                        spacing="2" 
                        px={{
                          default: 0,
                          md: 2,
                        }} 
                        flex="1"
                      >
                        <Box>
                          <Text fontSize="lg" fontWeight="bold">
                            {proposal.company_name}
                          </Text>
                          <Text>
                            Delivery between{" "}
                            {getDateString(proposal.delivery_date_start)} {" - "}{" "}
                            {getDateString(proposal.delivery_date_end)}
                          </Text>
                        </Box>
                      </Stack>
                      <Flex 
                        order={{
                          default: "2",
                          md: "2",
                        }}
                        justifyContent={{
                          default: "space-around",
                          md: "space-between",
                        }}
                        mt={6} 
                        gap={4}
                      >
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          color="green"
                          onClick={() => {
                            setModalData(proposal);
                            onOpenAccept();
                          }}
                        >
                          <Icon boxSize={5} as={FiCheckCircle} />
                          <Text fontSize="xsm">Accept</Text>
                        </Button>
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          color="red.500"
                          onClick={() => {
                            setModalData(proposal);
                            onOpenReject();
                          }}
                        >
                          <Icon boxSize={5} as={FiXCircle} />
                          <Text fontSize="xsm">Reject</Text>
                        </Button>
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          color="blue.300"
                          onClick={() => {
                            setModalData(proposal);
                            setIsNegotiating(true);
                            onOpenChat();
                          }}
                        >
                          <Icon boxSize={5} as={FiMessageCircle} />
                          <Text fontSize="xsm">Negotiate</Text>
                        </Button>

                      </Flex>
                      <Box 
                        order={{
                          default: "1",
                          md: "0",
                        }}
                        mt={{
                          default: 2,
                          md: 0,
                        }}
                        pr={{
                          md: 8,
                        }}
                        textAlign={{
                          default: "left",
                          md: "left",
                        }}
                        >
                        <Text fontSize="sm" color="gray.500">Proposed Amount:</Text>
                        <Flex
                          direction={{
                            default: "row",
                            md: "column",
                          }}
                        >
                          <Text 
                            fontSize={{
                              default: "xl",
                              md: "xxl",
                              }}
                            fontWeight="extrabold"
                            lineHeight="2rem"
                            pt={1}
                          >
                            <Text
                              as="span"
                              fontSize="md"
                              pos="relative"
                              top="-1rem"
                            >
                              {" "}
                              ${" "}
                            </Text>
                            { millify(proposal.price_per * proposal.num_units, { lowercase: true })}
                          </Text>
                          {proposal.num_units > 1 && (
                            <Text fontWeight="extrabold">
                                {proposal.num_units}{" "}
                                <Text
                                    as="span"
                                    fontSize={{
                                        default: "xsm",
                                        md: "sm",
                                    }}
                                    fontWeight="normal"
                                >
                                    {proposal.num_units > 1 ? "Units" : "Unit" } @ {" "}
                                </Text>
                                {proposal.price_per}{" "}
                                <Text
                                    as="span"
                                    fontSize={{
                                        default: "xsm",
                                        md: "sm",
                                    }}
                                    fontWeight="normal"
                                >
                                    per unit
                                </Text>
                            </Text>
                          )}
                          <Box
                            mt={{
                              default: 1,
                              md: 6,
                            }}
                            ml={{
                              default: 6,
                              md: 0,
                            }}
                          >
                            <Badge
                              bg={getDifferenceColor(
                                proposal.price_per,
                                proposal.num_units,
                                rfpEstimate
                              )}
                              color="white"
                            >
                              {proposalToRFPDifference(
                                proposal.price_per,
                                proposal.num_units,
                                rfpEstimate
                              )}
                              {" "}
                              {determineDiff(
                                proposal.price_per,
                                proposal.num_units,
                                rfpEstimate,
                              )}
                            </Badge>
                            {/* <Text>
                              <Text
                                as="span"
                                fontSize="lg"
                                fontWeight="900"
                                color={getDifferenceColor(
                                  proposal.price_per,
                                  proposal.num_units,
                                  rfpEstimate
                                )}
                              >
                                {proposalToRFPDifference(
                                  proposal.price_per,
                                  proposal.num_units,
                                  rfpEstimate
                                )}
                              </Text>
                            </Text>
                            <Text
                              fontSize="sm"
                              mt="0"
                              color={getDifferenceColor(
                                proposal.price_per,
                                proposal.num_units,
                                rfpEstimate
                              )}
                            >
                              {determineDiff(
                                proposal.price_per,
                                proposal.num_units,
                                rfpEstimate,
                              )}
                            </Text> */}
                          </Box>
                        </Flex>

                        {/* Not sure which I like better yet */}

                        {/* <Badge 
                            mt={1} 
                            bg={
                              getDifferenceColor(
                                proposal.price_per,
                                proposal.num_units,
                                rfp.price_per_range_end
                              )
                            } 
                            color="white">
                              ${" "}
                              {proposalToRFPDifference(
                                proposal.price_per,
                                proposal.num_units,
                                rfp.price_per_range_end
                              )}
                              {" "}
                              { 
                              determineDiff(
                                proposal.price_per,
                                proposal.num_units,
                                rfp.price_per_range_end
                              )}{" "} estimate
                          </Badge> */}
                      </Box>
                    </Flex>
                  </ListItem>
                ) : null
              )}
            </Stack>
          </List>
        )}
      </Stack>
    ) : (
      <Box p={10}>
        <Text fontSize="lg">There are no proposals to review.</Text>
      </Box>
    );
  };

  const acceptedProposalCard = () => {
    return (
      <Stack spacing="5" flex="1" p={5}>
        {acceptedProposals && (
          <List listStyleType="none" my={0}>
            {acceptedProposals.map((proposal) =>
              proposal ? (
                <ListItem
                  key={proposal.id}
                  position="relative"
                  mb={6}
                  px={0}
                  pt={1}
                  pb={6}
                  borderBottom="2px dotted"
                  borderColor="gray.200"
                >
                  <Box>
                    <Badge
                      bg={proposalStatusOptions[proposal.status][0]}
                      color={proposalStatusOptions[proposal.status][1]}
                      size="lg"
                      mb={2}
                    >
                      {proposal.status}
                    </Badge>
                    <HStack justify="space-between">
                      <Text fontSize="lg" fontWeight="bold">
                        {proposal.company_name}
                      </Text>
                    </HStack>

                    <Flex align="center" mb="0.5rem">
                      <Container maxW="none" p={0} flex="1 1 auto" m={0}>
                        <Text fontSize="sm" color="gray.500">
                          Proposed Amount:
                        </Text>
                        <Text
                          fontSize="xxl"
                          fontWeight="extrabold"
                          lineHeight="2.5rem"
                        >
                          <Text
                            as="span"
                            fontSize="md"
                            position="relative"
                            top="-1rem"
                          >
                            {" "}
                            ${" "}
                          </Text>
                          { millify(proposal.price_per * proposal.num_units, { lowercase: true })}
                        </Text>
                        
                        {proposal.num_units > 1 && (
                          <Text fontWeight="extrabold">
                              {proposal.num_units}{" "}
                              <Text
                                  as="span"
                                  fontSize={{
                                      default: "xsm",
                                      md: "sm",
                                  }}
                                  fontWeight="normal"
                              >
                                  {proposal.num_units > 1 ? "Units" : "Unit" } @ {" "}
                              </Text>
                              {proposal.price_per}{" "}
                              <Text
                                  as="span"
                                  fontSize={{
                                      default: "xsm",
                                      md: "sm",
                                  }}
                                  fontWeight="normal"
                              >
                                  per unit
                              </Text>
                          </Text>
                        )}
                      </Container>
                      
                      <Flex align="center" pr={6}>
                        <Icon
                          as={FiDollarSign}
                          fontSize="lg"
                          color="gray.500"
                        />
                      </Flex>
                    </Flex>

                    <Divider />

                    <Flex py={2}>
                      <Box flex="1 1 auto">
                        <Text fontSize="sm" color="gray.500">
                          Proposed delivery between{" "}
                        </Text>
                        <Text as="span" fontSize="md">
                          {getDateString(proposal.delivery_date_start)} {" - "}{" "}
                          {getDateString(proposal.delivery_date_end)}
                        </Text>
                      </Box>
                      <Flex align="center" pr={6}>
                        <Icon as={FiCalendar} fontSize="lg" color="gray.500" />
                      </Flex>
                    </Flex>

                    <Divider />

                    <Text fontSize="md" fontWeight="bold" my={4}>
                      Waiting for vendor confirmation to start order
                    </Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setModalData(proposal);
                        onOpenDetails();
                      }}
                    >
                      View Details
                    </Button>
                  </Box>
                </ListItem>
              ) : null
            )}
          </List>
        )}
      </Stack>
    );
  };


  return (
    <Container 
      maxW="100%" 
      mx="auto" 
      p={0}
    >
      {acceptedProposals.length > 0
        ? acceptedProposalCard()
        : allProposalsStack()}
      {isNegotiating && modalData && (
        <ChatDrawer
          isOpen={isOpenChat}
          onClose={onCloseChat}
          title={"Negotiate with the vendor"}
          collectionName={"buyer_vendor_proposal_chat"}
          collectionDocId={modalData.id}
        />
      )}
      <AlertPopup
        isOpen={isOpenReject}
        onClose={onCloseReject}
        alertHeader={"Reject Buyer"}
        alertBody={"Are you sure you want to reject this proposal?"}
        onSubmitAlert={rejectProposal}
      />
      <AlertPopup
        isOpen={isOpenAccept}
        onClose={onCloseAccept}
        alertHeader={"Accept Proposal"}
        alertBody={
          "Are you sure you want to accept this proposal and close this request?"
        }
        onSubmitAlert={acceptProposal}
      />
      <DetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        body={
          <ProposalInfoPopup proposal={modalData} product={props.product} />
        }
      />
    </Container>
  );
};
