import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";

import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ColorModeScript, ChakraProvider } from "@chakra-ui/react";
import myTheme from "./UI/myTheme";
import { Provider } from "react-redux";
import AuthContextProvider from "./store/auth-context";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

/*
authcontextprovider manages all the auth - registration, login, logout
browserrouter manages the routing as described in AppRouter.js
PersistGate manages the redux store persisting
ChakraProvider manages the theme
*/

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthContextProvider>
          <ChakraProvider theme={myTheme}>
            <ColorModeScript
              initialColorMode={myTheme.config.initialColorMode}
            />
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
                components: "buttons",
                currency: "USD",
              }}
            >
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </PayPalScriptProvider>
          </ChakraProvider>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
