import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { OrderInfoCard } from "../../platform/orders/OrderInfoCard";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { ProgressStat } from "../../../UI/ProgressStat";
import { Fragment } from "react";
import { Divider, SimpleGrid, Text } from "@chakra-ui/react";

export const BuyerCompletedOrder = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  const order = sessionInfo.currentOrder;

  const product = useGetProduct(order);

  const numDelivered = order.num_units_delivered;

  const numReceived = order.num_units_received;
  const total_units = order.num_units;

  let progStatDelivered = {
    label: "Units delivered",
    value: numDelivered,
    limit: total_units,
  };

  let progStatReceived = {
    label: "Units received",
    value: numReceived,
    limit: total_units,
  };

  const OrderActions = () => {
    return (
      <Fragment>
        <SimpleGrid columns={[1, null, 2]} spacing={3} mt={4}>
          <ProgressStat mt={4} {...progStatDelivered} />
          <ProgressStat mt={4} {...progStatReceived} />
        </SimpleGrid>
      </Fragment>
    );
  };

  return (
    <Layout>
      <PageLayout header={order.name} subHeader={"Completed Order"}>
        <SimpleGrid columns={1} spacing="4px">
          <OrderInfoCard product={product} />
          <Divider p={2} />
          <Text mt={2} fontSize="2xl" color="green.600" align="center">
            Order is complete!
          </Text>
          <OrderActions />
        </SimpleGrid>
      </PageLayout>
    </Layout>
  );
};
