import { 
    AspectRatio,
    Image,
    Skeleton,
} from "@chakra-ui/react";

export const CardImage = (props) => {
  return (
    <AspectRatio ratio={3 / 3} flex="1 1 auto">
        <Image
            src={props.url}
            alt={props.name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius="20px"
        />
    </AspectRatio>
  );
};
