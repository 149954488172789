import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { AuthChangeProviderPassword } from "./AuthChangeProviderPassword";
import { AuthChangeProviderThirdParty } from "./AuthChangeProviderThirdParty";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";

export const Settings = () => {
  const user = useSelector(selectUser);
  let providerPassword = false;

  if (user.providerId === "password") {
    providerPassword = true;
  }

  return (
    <Layout>
      <PageLayout>
        {providerPassword ? (
          <AuthChangeProviderPassword />
          ) : (
          <AuthChangeProviderThirdParty />
          )
        }
      </PageLayout>
    </Layout>
  );
};
