import { 
    Flex,
    Icon,
    Link,
    Tag,
} from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const GetTagStatus = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    if(props.status === "completed") {
        return (
            <Tag
                flex="0 0 auto"
                size="sm"
                bg="transparent"
                borderColor="gray.400"
                color="gray.400"
            >
                Completed
            </Tag>
        )
    } else if(props.status === "paying") {
        return (
            <Link
                color="white"
                textDecoration="none"
                key={props.id}
                onClick={() => {
                    dispatch(props.onLinkClick);
                    navigate(props.followOn);
                }}
            >
                <Tag
                    flex="0 0 auto"
                    size="sm"
                    bg="green.500"
                    borderColor="green.300"
                    color="white"
                >
                    Awaiting Payment
                    <Icon boxSize="4" as={FiArrowRight} />
                </Tag>
            </Link>
        )
    } else if((props.status === "open" || props.status === "confirmed") && props.proposalCount > 0) {
        return (
            <Link
                color="white"
                textDecoration="none"
                key={props.id}
                onClick={() => {
                    dispatch(props.onLinkClick);
                    navigate(props.followOn);
                }}
            >
                <Tag 
                    size="sm"
                    bg="red.500"
                    borderColor="red.500"
                    color="white"
                    >
                    Has Proposals
                    <Icon boxSize="4" as={FiArrowRight} />
                </Tag>
            </Link>
        )
    } else if(props.status === "open") {
        return (
            <Tag 
                size="sm"
                bg="yellow.500"
                borderColor="yellow.500"
                color="black"
            >
                {props.status}
            </Tag>
        )
    } else {
        return (
            <Tag
                flex="0 0 auto"
                size="sm"
                bg= {props.statusColor}
                borderColor={props.statusColor}
                color="white"
            >
                {props.status}
            </Tag>
        )
    }
}

export const Tags = (props) => {
    
    return (
        <Flex flex="0 0 auto" alignItems="center" p="0.7rem 1.4rem 0.7rem 0">
            {GetTagStatus(props)}
        
            {(props.type === "request" || props.type === "order") && (
                <Flex
                    flex="1 1 auto"
                    alignItems="center"
                    justifyContent="flex-end"
                    fontSize="xsm"
                    fontWeight="extrabold"
                    textTransform="uppercase"
                    letterSpacing="0.1rem"
                >
                    {props.icon} {props.text}
                </Flex>
            )}
        </Flex>
    );
};
