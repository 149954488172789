import { auth } from "../../store/firebase/Firebase";
import { useSignOut } from "react-firebase-hooks/auth";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { store } from "../../store/store";

export const Logout = (props) => {
  const [signOut] = useSignOut(auth);
  const navigate = useNavigate();

  const cancelRef = useRef();

  const onSubmit = async () => {
    const success = await signOut();
    if (success) {
      store.dispatch({ type: "RESET" });
      navigate("/main");
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Log out</AlertDialogHeader>

        <AlertDialogBody>Are you sure you want to log out?</AlertDialogBody>

        <AlertDialogFooter>
          <Button
            variant="outline"
            ref={cancelRef}
            onClick={props.onClose}
            mr={3}
          >
            No
          </Button>
          <Button colorScheme="blue" onClick={onSubmit}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
