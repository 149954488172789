import { Box, SimpleGrid } from "@chakra-ui/react";

export const MenuGrid = ({ children }) => {
  return (
    <Box
      mx="0"
      mt={{
        md: 10,
      }}
      mb={{
        md: 16,
      }}
      px={{
        default: 4,
        md: "2rem",
      }}
      pr={{
        md: "1.4rem",
      }}
      py={{
        default: 0,
        md: 0
      }}
    >
      <SimpleGrid
        columns={{
          default: 1,
          base: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
        }}
        gap={{
          default: "8",
          lg: "10",
        }}
      >
        {children}
      </SimpleGrid>
    </Box>
  );
};
