import { useEffect, useRef } from "react";
import { ChatMessage } from "./ChatMessage";
import { Text, useToast, Flex } from "@chakra-ui/react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  query,
  orderBy,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/features/userSlice";
import { ChatFooter } from "./ChatFooter";
import { useState } from "react";
import { firestore } from "../../store/firebase/Firebase";

/*import chat footer and message components
download relevant messages and display
submit message via form
as inputs it takes the collection name and doc id
*/

export const ChatBox = (props) => {
  const user = useSelector(selectUser);
  const toast = useToast();

  const messageRef = query(
    collection(
      firestore,
      props.collectionName,
      props.collectionDocId,
      "messages"
    ),
    orderBy("created_at")
  );

  const [messages, loading, error] = useCollectionData(messageRef);
  const [inputMessage, setInputMessage] = useState("");

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ behavior: "smooth" }));
    return <div ref={elementRef} />;
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim().length) {
      return;
    }
    //Add a new message document with a auto generated id
    const data = {
      text: inputMessage,
      created_at: serverTimestamp(),
      uid: user.uid,
      username: user.username,
    };

    const sendRef = await addDoc(
      collection(
        firestore,
        props.collectionName,
        props.collectionDocId,
        "messages"
      ),
      data
    );

    if (sendRef) {
      setInputMessage("");
    } else {
      toast({
        position: "top",
        description: "Something went wrong. Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  function checkPrevious(current) {
    //if no previous message then return false
    if (current === 0) {
      return false;
    }

    if (messages[current].uid === messages[current - 1].uid) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Flex w="100%" align="center">
      <Flex w={["100%", "100%", "100%"]} h="100%" flexDir="column">
        <ChatFooter
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
        />
        <Flex w="100%" h="100%" overflowY="scroll" flexDirection="column">
          {error && <Text>Chat not loading now. Please try again later</Text>}
          {loading ? (
            <Text>Loading...</Text>
          ) : (
            messages
              .reverse()
              .map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  uid={user.uid}
                  matchPrevious={checkPrevious(index)}
                />
              ))
          )}
          <AlwaysScrollToBottom />
        </Flex>
      </Flex>
    </Flex>
  );
};
