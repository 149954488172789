import { 
  Flex,
  Text,
} from "@chakra-ui/react";
import { OrderMenuCard } from "./OrderMenuCard";
import { MenuGrid } from "../elements/MenuGrid";

export const OrderMenu = (props) => {

  if (props.orders.length > 0) {
    return (
      <MenuGrid>
        {props.orders.map((order) => (
          <OrderMenuCard key={order.id} order={order} />
        ))}
      </MenuGrid>
    );
  } else {
    return (
      <Flex direction="column" align="center" justify="center">
        <Text
          fontSize="lg"
          my="2rem"
        >{`You do not have any active orders`}</Text>
      </Flex>
    );
  }
};
