import { createSlice } from "@reduxjs/toolkit";

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState: {
    productOptions: null,
    allRfps: null,
    allProposals: null,
    VendorsProposed: null,
    LeadBuyersRfps: null,
    BuyersAsked: null,
    allOrders: null,
    RatingsGiven: null,
    allOpenNotifications: [],
  },
  reducers: {
    addProductOptions: (state, action) => {
      state.productOptions = action.payload;
    },
    addAllRfps: (state, action) => {
      state.allRfps = action.payload;
    },
    addAllProposals: (state, action) => {
      state.allProposals = action.payload;
    },
    addVendorsProposed: (state, action) => {
      state.VendorsProposed = action.payload;
    },
    addLeadBuyersRfps: (state, action) => {
      state.LeadBuyersRfps = action.payload;
    },
    addBuyersAsked: (state, action) => {
      state.BuyersAsked = action.payload;
    },
    addAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    addRatingsGiven: (state, action) => {
      state.RatingsGiven = action.payload;
    },
    addAllOpenNotifications: (state, action) => {
      state.allOpenNotifications = action.payload;
    },
    removeClosedNotifications: (state, action) => {
      state.allOpenNotifications = [
        ...state.allOpenNotifications.filter(
          (not) => not.id !== action.payload
        ),
      ];
    },
  },
});

export const {
  addProductOptions,
  addAllRfps,
  addAllProposals,
  addVendorsProposed,
  addLeadBuyersRfps,
  addBuyersAsked,
  addAllOrders,
  addRatingsGiven,
  addAllOpenNotifications,
  removeClosedNotifications,
} = appInfoSlice.actions;

// selectors
export const selectAppInfo = (state) => state.appInfo;

export default appInfoSlice.reducer;
