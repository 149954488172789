import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState, Fragment, useEffect } from "react";
import { firestore, auth } from "../../../store/firebase/Firebase";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { DeleteAccountAlert } from "./DeleteAccountAlert";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, addUsername } from "../../../store/features/userSlice";
import {
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { checkUsernameExists } from "../../../store/firebase/FirebaseFunctions";

export const AuthChangeProviderThirdParty = () => {
  const [userDocRef, setUserDocRef] = useState();
  const [canDelete, setCanDelete] = useState(false);
  const [updatingUsername, setUpdatingUsername] = useState(false);

  const [signInWithGoogle, userGoogle, loadingGoogle, errorGoogle] =
    useSignInWithGoogle(auth);

  const user = useSelector(selectUser);
  let uid = user.uid;
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: OnCloseDelete,
  } = useDisclosure();

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //See if user exists and if does then pre-populate with profile info
  useEffect(() => {
    const checkExisting = async () => {
      // Make the initial query
      const q = query(
        collection(firestore, "user_accounts"),
        where("uid", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        let profileData = doc.data();

        setUserDocRef(doc.ref);
        //dispatch to reducer
        dispatch(addUsername(profileData.username));
        //pre-populate form
        for (const [key, value] of Object.entries(profileData)) {
          setValue(key, value);
        }
      }
    };
    //if user logged in then get existing data
    if (uid !== null) {
      checkExisting();
    }
  }, [uid, setValue, dispatch]);

  //handle errors
  useEffect(() => {
    if (errorGoogle) {
      toast({
position: "top",
        description: "Something went wrong. Try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [errorGoogle, toast]);

  //after registering
  useEffect(() => {
    if (userGoogle) {
      setCanDelete(true);
      toast({
position: "top",
        description: "Logged in!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [userGoogle, toast]);

  const onSubmitUsername = async (data) => {
    setUpdatingUsername(true);
    checkUsernameExists({ text: data["username"] })
      .then((result) => {
        if (result.data === true) {
          //if it does then set error
          setError("username", {
            type: "custom",
            message: "username already exists",
          });
          setUpdatingUsername(false);
        } else {
          data["updated_at"] = serverTimestamp();
          if (userDocRef) {
            updateDoc(userDocRef, data);
            //dispatch to profile
            dispatch(addUsername(data["username"]));
            setUpdatingUsername(false);
            toast({
position: "top",
              description: "Username updated!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      })
      .catch(() => {
        toast({
position: "top",
          description: "Something went wrong. Try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setUpdatingUsername(false);
      });
  };

  return (
    <Container maxW="100%" p={0}>
      <Stack spacing="5" maxW="70rem" pt={16} pl={44} pr={0}>
        <chakra.form>
          <Heading
            as="h2"
            variant="underline"
            pos="relative"
            top="-0.5rem"
            pt={0}
            mt={0}
          >
            Your Settings
          </Heading>
          <Box>
            <Stack
              spacing="5"
              pl={{
                default: "0",
                md: "8",
              }}
              py={{
                default: "5",
                md: "0",
              }}
            >
              <FormControl id="username" isInvalid={errors.username} mt={4}>
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  {...register("username", {
                    minLength: {
                      value: 5,
                      message: "Must be at least 5 characters long",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_]+$/i,
                      message: "Cannot contain symbols or spaces",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.username && errors.username?.message}
                </FormErrorMessage>
              </FormControl>
              <Box>
                <Button
                  variant="primary"
                  isLoading={updatingUsername}
                  type="submit"
                  onClick={handleSubmit(onSubmitUsername)}
                >
                  Update username
                </Button>
              </Box>

              <Divider p={3} />

              {!canDelete ? (
                <Fragment>
                  <Text fontWeight="medium">
                    Sign in again to enable the ability to delete your account
                  </Text>
                  <Box>
                    <Button
                      variant="primary"
                      isLoading={loadingGoogle}
                      onClick={() => {
                        signInWithGoogle();
                      }}
                    >
                      Sign in
                    </Button>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <Text fontWeight="medium">
                    Click here to delete your account. This is not reversible.
                  </Text>
                  <Box>
                    <Button
                      variant="primary"
                      onClick={() => {
                        onOpenDelete();
                      }}
                    >
                      Delete Account
                    </Button>
                  </Box>
                </Fragment>
              )}
            </Stack>
          </Box>
          <DeleteAccountAlert isOpen={isOpenDelete} onClose={OnCloseDelete} />
        </chakra.form>
      </Stack>
    </Container>
  );
};
