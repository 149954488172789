import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Spacer,
  Text,
  useBreakpointValue,
  useToast,
  chakra,
} from "@chakra-ui/react";
import { LoginFull } from "../../UI/Icons/LoginFull";
import { Footer } from "../../UI/Footer";
import { GoogleIcon } from "../../UI/Icons/ProviderIcons";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { Fragment, useEffect } from "react";
import {
  useCreateUserWithEmailAndPassword,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { auth } from "../../store/firebase/Firebase";
import { useDispatch } from "react-redux";
import { addUser } from "../../store/features/userSlice";
import {
  createUser,
  inviteBuyerToRfp,
} from "../../store/firebase/FirebaseFunctions";
import { store } from "../../store/store";

export const Register = () => {
  const [createUserWithEmailAndPassword, userEmail, loadingEmail, errorEmail] =
    useCreateUserWithEmailAndPassword(auth);
  const [signInWithGoogle, userGoogle, loadingGoogle, errorGoogle] =
    useSignInWithGoogle(auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toast = useToast();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  //check to see if register url contains link to a specific rfp
  const [queryParameters] = useSearchParams();
  let invitedRfp = queryParameters.get("rfpId") ?? null;

  //handle errors
  useEffect(() => {
    if (errorEmail || errorGoogle) {
      let errorMsg;
      if (errorEmail) {
        errorMsg = errorEmail.message;
      } else if (errorGoogle) {
        errorMsg = errorGoogle.message;
      }
      toast({
        position: "top",
        description: errorMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [errorEmail, errorGoogle, toast]);

  //after registering
  useEffect(() => {
    if (userGoogle || userEmail) {
      let user;
      if (userGoogle) {
        user = userGoogle;
      }
      if (userEmail) {
        user = userEmail;
      }

      let userData = {
        uid: user.user.uid,
        email: user.user.email,
        emailVerified: user.user.emailVerified,
        providerId: user.user.providerData[0].providerId,
      };

      createUser(userData)
        .then((result) => {
          const newUser = result.data.newData;
          if (newUser) {
            dispatch(addUser(newUser));
            toast({
              position: "top",
              description: "Account created!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            //after user created, if buyer was invited add them to buyer invite for rfp

            if (invitedRfp) {
              inviteBuyerToRfp({
                rfpId: invitedRfp,
                invitedUser: user.user.uid,
              });
            }

            navigate("/complete-register");
          }
        })
        .catch(() => {
          toast({
            position: "top",
            description: "Something went wrong. Please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, [userGoogle, userEmail, toast, navigate, dispatch, invitedRfp]);

  const onSubmit = async (data) => {
    createUserWithEmailAndPassword(data["email"], data["password"]);
  };

  return (
    <Fragment>
      <Box 
        flex="1 1 auto"
        as="section" 
        pb={0}
        bg="white"
      >
        <Container
          maxW="100%"
          centerContent
          borderTop="0.75rem solid"
          borderColor="blue.300"
          overflow="hidden"
          p={0}
        >
          <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
            <LoginFull pos="relative" top="-1px" w="175%" />
          </Link>
        </Container>
        <Container maxW="md" py={{ default: "12", md: "24" }}>
          <Stack spacing="8">
            <Stack spacing="6" align="center">
              <Stack spacing="3" textAlign="center">
                <Heading 
                  size={useBreakpointValue({ 
                    default: "md", 
                    md: "lg" 
                  })}
                >
                  Create an account
                </Heading>
                <Text color="muted">Increase your buying and selling power</Text>
              </Stack>
            </Stack>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl id="email" isInvalid={errors.email} isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="text"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.email && errors.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="password"
                    isInvalid={errors.password}
                    isRequired
                  >
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Must be at least 8 characters long",
                        },
                        pattern: {
                          value:
                            /^(?=(.*[A-Za-z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
                          message:
                            "Password should contain at least 1 letter, 1 symbol, and 1 number",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.password && errors.password?.message}
                    </FormErrorMessage>
                    <FormHelperText>
                      Password must be at least 8 characters long
                    </FormHelperText>
                  </FormControl>
                  <PasswordStrengthBar password={watch("password")} />

                  <FormControl
                    id="password_repeat"
                    isInvalid={errors.password_repeat}
                    isRequired
                  >
                    <FormLabel>Repeat Password</FormLabel>
                    <Input
                      type="password"
                      {...register("password_repeat", {
                        required: "Password is required",
                        validate: (value) =>
                          value === watch("password") ||
                          "The passwords must match",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.password_repeat && errors.password_repeat?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Spacer />
                <Stack spacing="4">
                  <Button
                    variant="primary"
                    isLoading={loadingEmail}
                    loadingText="Creating..."
                    type="submit"
                    m="2rem 3rem"
                  >
                    Create account
                  </Button>
                  <Text align="center" fontSize="sm" pb={4}>
                    OR
                  </Text>
                  <Button
                    border="1px"
                    variant="secondary"
                    isLoading={loadingGoogle}
                    leftIcon={<GoogleIcon boxSize="5" />}
                    iconSpacing="3"
                    onClick={() => signInWithGoogle()}
                  >
                    Sign up with Google
                  </Button>
                </Stack>
              </Stack>
            </chakra.form>
            <Spacer />
            <HStack justify="center" spacing="1">
              <Text color="muted">Already have an account?</Text>
              <Link 
                href="/login"
                onClick={() => {
                  store.dispatch({ type: "RESET" });
                }}
              >
                Log in
              </Link>
            </HStack>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </Fragment>
  );
};
