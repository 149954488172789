import { Box, Heading, Text } from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { MenuGrid } from "../../platform/elements/MenuGrid";
import { PastOrderCard } from "../../platform/orders/PastOrderCard";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../../store/features/appInfoSlice";
import { selectUser } from "../../../store/features/userSlice";

export const VendorPastOrdersMenu = () => {
  const appInfo = useSelector(selectAppInfo);
  const user = useSelector(selectUser);

  const ratingsGiven = appInfo.RatingsGiven;

  //get orders that are completed
  const completedOrders = appInfo.allOrders.filter(function (myo) {
    return myo.status === "completed";
  });

  return (
    <Layout>
      <PageLayout>
        <Box position="relative" zIndex="1" p="2.3rem 0 0 10rem">
          <Box>
            <Heading
              as="h2"
              variant="underline"
              display="flex"
              justifyContent="space-between"
            >
              <Text>Completed Orders</Text>
            </Heading>
          </Box>
          {completedOrders.length === 0 ? (
            <Text p={10}>You have not yet completed any orders</Text>
          ) : (
            <MenuGrid>
              {completedOrders.map((order) => (
                <PastOrderCard
                  key={order.id}
                  order={order}
                  user={user}
                  ratingsGiven={ratingsGiven}
                />
              ))}
            </MenuGrid>
          )}
        </Box>
      </PageLayout>
    </Layout>
  );
};
