import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      uid: null,
      account_type: null,
      email: null,
      username: null,
    },
  },
  reducers: {
    addUserId: (state, action) => {
      state.user.uid = action.payload;
    },
    addUserEmail: (state, action) => {
      state.user.email = action.payload;
    },
    addUsername: (state, action) => {
      state.user.username = action.payload;
    },
    addVendorStoreImage: (state, action) => {
      state.user.store_image = action.payload;
    },
    addUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});

export const {
  addUser,
  removeUser,
  addUserId,
  addUserEmail,
  addUsername,
  addVendorStoreImage,
} = userSlice.actions;

// selectors
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
