import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import { selectAppInfo } from "../../../../store/features/appInfoSlice";
import { selectSessionInfo } from "../../../../store/features/sessionInfoSlice";
import { getDateString } from "../../../utilities/utilities";
import { useState } from "react";
import { useSelector } from "react-redux";
import { proposalStatusOptions } from "../../../utilities/utilities";
import { ProposalInfoPopup } from "../../../platform/proposals/ProposalInfoPopup";
import { DetailsModal } from "../../../platform/elements/DetailsModal";
import { 
  FiEye,
  FiCalendar,
} from "react-icons/fi";

/*
supporter who has joined rfp can access proposals
can see details of proposals that are open or accepted
*/

export const SupporterProposalTabActions = (props) => {
  const appInfo = useSelector(selectAppInfo);
  const allProposals = appInfo.allProposals;
  const sessionInfo = useSelector(selectSessionInfo);
  const rfp = sessionInfo.currentRfp;

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();

  const [myProposals] = useState(getRelevantProposals());
  const [modalData, setModalData] = useState();
  const [acceptedProposals] = useState(getAcceptedProposals());

  //get proposals for this rfp
  function getRelevantProposals() {
    const relevantProposals = allProposals.filter(function (proposal) {
      return proposal.rfp_id === props.rfp.id;
    });
    return relevantProposals;
  }

  function getAcceptedProposals() {
    const acceptedProposals = allProposals.filter(function (proposal) {
      return proposal.rfp_id === props.rfp.id && proposal.status === "accepted";
    });
    return acceptedProposals;
  }

  const proposalToRFPDifference = (pricePer, numUnits, rfpEstimateStart, rfpEstimateEnd) => {
    let low = rfpEstimateStart * numUnits;
    let high = rfpEstimateEnd * numUnits;
    let proposalTotal = pricePer * numUnits;
    let range = null;
    
    if (proposalTotal < low) {
      range = ["green", "Below Estimate"];
    } else if(proposalTotal > high) {
      range = ["red", "Above Estimate"];
    } else if(proposalTotal > low && proposalTotal < high) {
      range = ["blue", "Within Estimate"];
    }
    
    return (
      <Badge
        variant="solid"
        colorScheme={range[0]}
        size="md"
      >
        {range[1]}
      </Badge>
    )
  };

  const allProposalsStack = () => {
    return (
      <Stack 
        spacing="5" 
        flex="1"
        p={5}
      >
        {myProposals && (
          <List listStyleType="none">
            <Stack spacing="3" width="full">
              {myProposals.map((proposal) =>
                proposal ? (
                  <ListItem
                    key={proposal.id}
                    position="relative"
                    my={1}
                    px={0}
                    pb={4}
                    borderBottom="2px solid"
                    borderColor="gray.200"
                  >
                    <Flex
                      direction={{
                        default: "column",
                        md: "row",
                      }}
                      flexWrap={{
                        md: "wrap",
                      }}
                    >
                      <Stack 
                        order={{
                          default: "0",
                          md: "1",
                        }}
                        shouldWrapChildren 
                        spacing="2" 
                        px={{
                          default: 0,
                          md: 2,
                        }} 
                        flex="1 0 auto"
                      >
                        <Box>
                          <Text fontSize="lg" fontWeight="bold">
                            {proposal.company_name}
                          </Text>
                          <Text>
                            <Icon as={FiCalendar} />{" "}Delivery between{" "}
                            {getDateString(proposal.delivery_date_start)} {" - "}{" "}
                            {getDateString(proposal.delivery_date_end)}
                          </Text>
                          <Text>{proposal.other_details}</Text>
                        </Box>
                      </Stack>
                      <Flex 
                        flex="0 0 auto"
                        order={{
                          default: "2",
                          md: "2",
                        }}
                        justifyContent={{
                          default: "space-between",
                          md: "space-between",
                        }}
                        mt={6} 
                        spacing={8}
                      >
                        <Button
                          variant="stacked"
                          size="md"
                          py="2rem"
                          onClick={() => {
                            setModalData(proposal);
                            onOpenDetails();
                          }}
                        >
                          <Icon boxSize={5} as={FiEye} />
                          <Text fontSize="xsm">View Details</Text>
                        </Button>
                      </Flex>
                      <Box 
                        flex="0 0 auto"
                        order={{
                          default: "1",
                          md: "0",
                        }}
                        mt={{
                          default: 2,
                          md: 0,
                        }}
                        pr={{
                          md: 8,
                        }}
                        textAlign={{
                          default: "left",
                          md: "left",
                        }}
                      >
                        <Text fontSize="sm" color="gray.500">Proposed Amount</Text>
                        <Flex
                          direction={{
                            default: "row",
                            md: "column",
                          }}
                        >
                          <Text
                              fontSize={{
                                  default: "xl",
                                  md: "xxl",
                              }}
                              fontWeight="extrabold"
                              lineHeight="2rem"
                              pt={1}
                          >
                              <Text as="span" fontSize="md" position="relative" top="-1rem">
                                  {" "}
                                  ${" "}
                              </Text>
                              {(proposal.price_per * proposal.num_units).toLocaleString("en-US")}
                          </Text>
                          
                          {proposal.num_units > 1 && (
                            <Text fontWeight="extrabold">
                                {proposal.num_units}{" "}
                                <Text
                                    as="span"
                                    fontSize={{
                                        default: "xsm",
                                        md: "sm",
                                    }}
                                    fontWeight="normal"
                                >
                                    {proposal.num_units > 1 ? "Units" : "Unit" } @ {" "}
                                </Text>
                                {proposal.price_per}{" "}
                                <Text
                                    as="span"
                                    fontSize={{
                                        default: "xsm",
                                        md: "sm",
                                    }}
                                    fontWeight="normal"
                                >
                                    per unit
                                </Text>
                            </Text>
                          )}

                          <Box
                            mt={{
                              default: 1,
                              md: 2,
                            }}
                            ml={{
                              default: 6,
                              md: 0,
                            }}
                          >
                            {proposalToRFPDifference(
                              proposal.price_per,
                              proposal.num_units,
                              rfp.price_per_range_start,
                              rfp.price_per_range_end ? rfp.price_per_range_end : ""
                            )}
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </ListItem>
                ) : null
              )}
            </Stack>
          </List>
        )}
      </Stack>
    );
  };

  const acceptedProposalCard = () => {
    return (
      <Stack spacing="5" flex="1">
        <Text color={"green.600"} fontSize="lg" fontWeight={"600"}>
          1 Accepted Proposal
        </Text>
        {acceptedProposals && (
          <List listStyleType="none">
            <Stack spacing="3">
              {acceptedProposals.map((proposal) =>
                proposal ? (
                  <ListItem
                    key={proposal.id}
                    bg="bg-surface"
                    p="4"
                    boxShadow={mode("2xl", "sm-dark")}
                    position="relative"
                    borderRadius="lg"
                  >
                    <Stack shouldWrapChildren spacing="2">
                      <HStack justify="space-between">
                        <Text fontSize="md" fontWeight="600" color="emphasized">
                          {proposal.company_name}
                        </Text>
                        <Badge
                          colorScheme={
                            proposalStatusOptions[proposal.status][0]
                          }
                          size="md"
                        >
                          {proposal.status}
                        </Badge>
                      </HStack>
                      <Text fontSize="md">
                        ${proposal.price_per} per unit for {proposal.num_units}{" "}
                        units
                      </Text>
                      <Text fontSize="md">
                        {getDateString(proposal.delivery_date_start)}
                        {" - "}
                        {getDateString(proposal.delivery_date_end)}
                      </Text>
                      <SimpleGrid columns={[1, null, 1]} spacing={3} mt={4}>
                        <Text as="em" align="center" fontWeight={"600"}>
                          Waiting for vendor confirmation to start order
                        </Text>
                        <Button
                          size="md"
                          colorScheme="blue"
                          fontWeight={"400"}
                          variant="outline"
                          onClick={() => {
                            setModalData(proposal);
                            onOpenDetails();
                          }}
                        >
                          Details
                        </Button>
                      </SimpleGrid>
                    </Stack>
                  </ListItem>
                ) : null
              )}
            </Stack>
          </List>
        )}
      </Stack>
    );
  };

  return (
    <Container
      maxW="100%" 
      mx="auto" 
      p={0}
    >
      {acceptedProposals.length > 0
        ? acceptedProposalCard()
        : allProposalsStack()}

      <DetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        body={
          <ProposalInfoPopup proposal={modalData} product={props.product} />
        }
      />
    </Container>
  );
};
