import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Layout } from "../../../UI/Layout";
import { PageLayout } from "../../../UI/PageLayout";
import { useSelector } from "react-redux";
import { selectSessionInfo } from "../../../store/features/sessionInfoSlice";
import { OrderInfoCard } from "../../platform/orders/OrderInfoCard";
import { useGetProduct } from "../../../hooks/useGetProduct";
import { ProgressStat } from "../../../UI/ProgressStat";
import { Fragment, useEffect, useState } from "react";
import { DeliveryPopoverForm } from "../../platform/orders/DeliveryPopoverForm";
import { useNavigate } from "react-router-dom";
import { ChatDrawer } from "../../chat/ChatDrawer";

/*
For vendor to keep track of and update orders
*/

export const VendorCurrentOrder = () => {
  const sessionInfo = useSelector(selectSessionInfo);
  const order = sessionInfo.currentOrder;

  const product = useGetProduct(order);

  const navigate = useNavigate();
  const {
    onOpen: onOpenDeliveryUpdate,
    onClose: onCloseDeliveryUpdate,
    isOpen: isOpenDeliveryUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenChat,
    onOpen: onOpenChat,
    onClose: onCloseChat,
  } = useDisclosure();

  const [isPaying, setIsPaying] = useState();
  useEffect(() => {
    //check to see if payments still need to be completed
    if (order.status === "paying") {
      setIsPaying(true);
    } else if (order.status === "current") {
      setIsPaying(false);
    }
  }, [order.status, setIsPaying]);

  const [numDelivered, setNumDelivered] = useState(
    order.num_units_delivered ?? 0
  );

  const numReceived = order.num_units_received ?? 0;
  const total_units = order.num_units;

  let progStatDelivered = {
    type: "delivery",
    label: "You have delivered",
    value: Number(numDelivered),
    limit: Number(total_units),
  };
  
  let progStatReceived = {
    type: "receipt",
    label: "The buyer has confirmed receipt of",
    value: Number(numReceived),
    limit: Number(total_units),
  };

  let progStatPaid = {
    type: "paid",
    label: "Buyers paid",
    value: Number(order.num_payments_completed),
    limit: Number(order.num_payments_required),
  };

  useEffect(() => {
    //check to see if order is completed
    if (total_units === numReceived && total_units === numDelivered) {
      navigate("/vendor/completed-order");
    }
  }, [total_units, numDelivered, numReceived, navigate]);

  const OrderActions = () => {
    return (
      <Fragment>
        <Stack
          direction={["column", "row"]}
          spacing="24px"
          mt={4}
          px={8}
        >
          {!isPaying && (
            <DeliveryPopoverForm
              isOpen={isOpenDeliveryUpdate}
              onOpen={onOpenDeliveryUpdate}
              onClose={onCloseDeliveryUpdate}
              setNumUnits={setNumDelivered}
              orderField={"Delivery Info"}
              order={order}
            />
          )}
        </Stack>
      </Fragment>
    );
  };

  return (
    <Layout>
      <PageLayout>
        <Container
          maxW="100%"
          pt={{
            md: 0,
          }}
          px={{
            md: 0,
          }}
        >
          <Heading
            as="h2"
            variant="underline"
            ml={{
              md: "12rem",
            }}
            mb={{
              md: 6,
            }}
            pt={{
              md: 4,
            }}
          >
            Current Order
          </Heading>
          
          <Flex
            direction={{
              default: "column",
              sm: "row",
            }}
          >
            <Box
              flex={{
                default: "1 1 auto",
                md: "0 0 50%",
              }}
              maxW={{
                default: "none",
                md: "45rem",
              }}
            >
              <OrderInfoCard flex="1 1 50%" product={product} />
            </Box>

            <Box
              flex={{
                default: "1 1 auto",
                md: "1 1 auto",
              }}
              position="relative"
              zIndex="4"
              p={{
                default: 5,
                md: "2.5rem 0 0 1rem",
              }}
              pl={{
                md: "1rem",
              }}
            >
              <Heading
                as="h3" 
                width="100%" 
                mb={4}
                pb={8}
                borderBottom="1px solid"
                borderColor="gray.200"
                fontSize="xl" 
                textAlign="center"
              >
                Delivery Status
              </Heading>
              
              {!isPaying ? (
                <Fragment>
                  <ProgressStat mt={0} {...progStatDelivered} />
                  <ProgressStat mt={0} {...progStatReceived} />
                </Fragment>
              ) : (
                <ProgressStat mt={0} {...progStatPaid} />
              )}
              <Divider p={2} />
              <OrderActions />
            </Box>
          </Flex>
          <ChatDrawer
            isOpen={isOpenChat}
            onClose={onCloseChat}
            title={"Chat with the buyer"}
            collectionName={"buyer_vendor_order_chat"}
            collectionDocId={order.id}
          />
        </Container>
      </PageLayout>
    </Layout>
  );
};
