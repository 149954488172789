import { chakra, HTMLChakraProps, Show } from "@chakra-ui/react";

export const LogoWithText = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    width={{
      default: 14,
      md: 20,
    }}
    height={{
      default: 14,
      md: 20,
    }}
    viewBox="0 0 171 117"
    fill="#FFCE00"
    {...props}
  >
    <title>Yeehive Logo</title>
    <g>
        <polygon className="logo_background" points="102.2,35.7 98.8,34 85.2,41.4 71.6,34 68.2,35.8 68.2,39.8 81.8,47.8 81.8,61.9 85.2,63.6 88.6,61.9 88.6,47.8 102.2,39.8"/>
        <g className="logo_mark">
            <polygon points="111.8,26.4 87.6,12.8 87.6,3 85.2,2 82.9,3 82.9,12.8 58.6,26.4 63.6,29.2 85.2,17 106.9,29.2"/>
            <polygon points="60.4,36.3 55.6,33.5 55.6,58.9 47.1,63.7 47.4,66.3 49.4,67.8 57.9,63 80.9,75.9 80.9,70.4 60.4,58.8"/>
            <polygon points="114.9,58.3 114.9,33.5 110.1,36.3 110.1,58.8 89.4,70.5 89.5,76 113.1,62.7 122.1,66.5 123.9,64.7 123.9,62.2"/>
        </g>
    </g>
    <Show above='md'>
      <g className="logo_text">
          <path d="M15.5,94.1h8.4l-7,11.8v8.9H9.4v-8.9l-7-11.8h8.4l2.4,5.2C13.1,99.3,15.5,94.1,15.5,94.1z"/>
          <path d="M31.9,114.8V94.1h13.8v5.6h-6.4v2h5.8v5.4h-5.8v2h6.5v5.8C45.9,114.8,31.9,114.8,31.9,114.8z"/>
          <path d="M55.7,114.8V94.1h13.8v5.6h-6.4v2h5.8v5.4h-5.8v2h6.5v5.8C69.6,114.8,55.7,114.8,55.7,114.8z"/>
          <path d="M79.4,114.8V94.1h7.5v6.9h4.3v-6.9h7.4v20.7h-7.4v-7.3h-4.3v7.3H79.4z"/>
          <path d="M108.8,114.8V94.1h7.4v20.7H108.8z"/>
          <path d="M138.8,94.1h7.8l-7.9,20.7h-6.5l-7.9-20.7h7.8l3.4,11.4C135.5,105.5,138.8,94.1,138.8,94.1z"/>
          <path d="M154.7,114.8V94.1h13.8v5.6h-6.4v2h5.8v5.4h-5.8v2h6.5v5.8H154.7L154.7,114.8z"/>
      </g>
    </Show>
  </chakra.svg>
);
