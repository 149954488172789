import { useDispatch } from "react-redux";
import { addUser } from "../../store/features/userSlice";
import {
  Box,
  Button,
  chakra,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { LoginFull } from "../../UI/Icons/LoginFull";
import { Footer } from "../../UI/Footer";
import { useForm, Controller } from "react-hook-form";
import { useState, Fragment } from "react";
import {
  checkUsernameExists,
  completeRegistration,
} from "../../store/firebase/FirebaseFunctions";
import { useNavigate } from "react-router-dom";

export const CompleteRegister = () => {
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    setIsCreating(true);
    //check to see if username exists
    checkUsernameExists({ text: data["username"] })
      .then((result) => {
        if (result.data === true) {
          //if it does then set error
          setError("username", {
            type: "custom",
            message: "Username already exists. Try another one.",
          });
          setIsCreating(false);
        } else {
          //if clear, then update profile with user info
          completeRegistration(data)
            .then((userData) => {
              dispatch(addUser(userData.data));
              toast({
                position: "top",
                description: "Registration complete!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              navigate("/profile");
            })
            .catch((error) => {
              toast({
                position: "top",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
              setIsCreating(false);
            });
        }
      })
      .catch(() => {
        toast({
          position: "top",
          description: "Something went wrong. Try again later",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsCreating(false);
      });
  };

  return (
    <Fragment>
      <Box 
        as="section" 
        flex="1 1 auto"
        maxW="1800px"
        m="0 auto"
        pb={50}
        bg="white"
      >
        <Container
          maxW="100%"
          px="0"
          py={{
            default: "0",
            md: "0",
          }}
        >
          <Container
            maxW="100%"
            centerContent
            borderTop="0.75rem solid"
            borderColor="blue.300"
            overflow="hidden"
            p={0}
          >
            <Link
              href="/"
              pos="relative"
              top="-1px"
              w="175%" 
              maxW="60rem"
              _focus={{
                outlineOffset: "0"
              }}
            >
              <LoginFull pos="relative" top="-1px" w="175%" />
            </Link>
          </Container>
          <Container 
            maxW="40rem"
            p={{
              default: 4,
              sm: 20,
            }} 
            pt={{
              sm: 10,
            }}
          >
            <Stack spacing="8">
            <Heading
                align="center"
                size={useBreakpointValue({
                  default: "md",
                  md: "md",
                })}
              >
                Pick your account type and username to get started!
              </Heading>
              <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6">
                <Stack spacing="8">
                  <FormControl
                    id="account_type"
                    isInvalid={errors.account_type}
                    isRequired
                  >
                    <FormLabel>Account Type</FormLabel>
                    <Controller
                      name="account_type"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row" spacing="5">
                            <Radio value="buyer">Buyer</Radio>
                            <Radio value="vendor">Vendor</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      rules={{
                        required: { value: true, message: "This is required." },
                      }}
                    />
                    <FormErrorMessage>
                      {errors.account_type && errors.account_type?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="username"
                    isInvalid={errors.username}
                    isRequired
                  >
                    <FormLabel>Username</FormLabel>
                    <Input
                      type="text"
                      {...register("username", {
                        required: "Username is required",
                        minLength: {
                          value: 5,
                          message: "Must be at least 5 characters long",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9_]+$/i,
                          message: "Cannot contain symbols or spaces",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.username && errors.username?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack spacing="4">
                  <Button
                    variant="primary"
                    isLoading={isCreating}
                    loadingText="Creating..."
                    type="submit"
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </chakra.form>
            </Stack>
          </Container>
        </Container>
      </Box>
    <Footer />
  </Fragment>
  
  );
};
