import { useSelector } from "react-redux";
import { selectUser } from "../../../store/features/userSlice";
import { BuyerHelp } from "./BuyerHelp";
import { VendorHelp } from "./VendorHelp";

export const Help = () => {
  const user = useSelector(selectUser);

  if (user.account_type === "buyer") {
    return <BuyerHelp />;
  }
  if (user.account_type === "vendor") {
    return <VendorHelp />;
  }
};
