import { Text, Link, Icon, CloseButton, Flex, Spacer } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectAppInfo,
  removeClosedNotifications,
} from "../../store/features/appInfoSlice";
import {
  addCurrentOrder,
  addCurrentProposal,
  addCurrentRfp,
} from "../../store/features/sessionInfoSlice";
import { selectUser } from "../../store/features/userSlice";
import { auth } from "../../store/firebase/Firebase";
import { closeNotification } from "../../store/firebase/FirebaseFunctions";

export const NotificationCard = (props) => {
  const user = useSelector(selectUser);
  const appInfo = useSelector(selectAppInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = props.notification;
  const account_type = user.account_type;

  //check to see if notification created since last signed in - if so then show icon
  function checkIfNew() {
    let lastsignin =
      new Date(auth.currentUser?.metadata?.lastSignInTime) ?? new Date();
    let ntfTime = notification.created_at;
    if (ntfTime > lastsignin) {
      return (
        <Icon viewBox="0 0 200 200" color="green.500">
          <path
            fill="currentColor"
            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
          />
        </Icon>
      );
    }
  }

  function getObj(objId, field, objectsToSearch) {
    const foundObj = objectsToSearch.filter(function (item) {
      return item[field] === objId;
    });
    return foundObj[0];
  }

  function clickedNotification() {
    //get object - rfp, order, proposal
    //dispatch object to session variable
    //navigate to correct page
    let objType = notification.object_type;
    let objId = notification.object_id;

    //if notification is about rfp
    if (objType === "rfp") {
      dispatch(addCurrentRfp(getObj(objId, "id", appInfo.allRfps)));
      navigate(`/${account_type}/current-rfp`);
    }
    //if notification is about order
    else if (objType === "order") {
      dispatch(addCurrentOrder(getObj(objId, "id", appInfo.allOrders)));
      navigate(`/${account_type}/current-order`);
    }
    //if notification is about proposal - then show rfp if buyer otherwise proposal for vendor
    else if (objType === "proposal") {
      if (account_type === "vendor") {
        dispatch(addCurrentProposal(getObj(objId, "id", appInfo.allProposals)));
        navigate("/vendor/current-proposal");
      }
      if (account_type === "buyer") {
        dispatch(addCurrentRfp(getObj(objId, "rfp_id", appInfo.allProposals)));
        navigate("/buyer/current-rfp");
      }
    }
  }

  function closeOutNotification() {
    closeNotification({
      notification_id: notification.id,
    });
    dispatch(removeClosedNotifications(notification.id));
  }

  function getMonthDay() {
    let ntfDate = new Date(notification.created_at);
    return ntfDate.toLocaleDateString("en", {
      month: "short",
      day: "2-digit",
    });
  }
  //return stack based on if outputting for notification page or menu popup
  if (props.type === "popover") {
    return (
      <Flex direction="row">
        {checkIfNew()}
        <Link onClick={clickedNotification}>
          <Text fontSize="xs" color="muted">
            {notification.text}
          </Text>
        </Link>
        <CloseButton onClick={closeOutNotification} />
      </Flex>
    );
  } else {
    return (
      <Flex p="4">
        <Link onClick={clickedNotification}>
          <Text fontSize="sm" color="muted">
            {notification.text}
          </Text>
          {props.earlier && (
            <Text mt={2} fontSize="xs">
              {getMonthDay()}
            </Text>
          )}
        </Link>
        <Spacer />
        <CloseButton onClick={closeOutNotification} />
      </Flex>
    );
  }
};
