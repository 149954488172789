import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Icon,
  Image,
  Skeleton,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  FiClipboard,
  FiSearch,
  FiDollarSign,
  FiCalendar,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useGetProduct } from "../../../hooks/useGetProduct";
import {
  captializeFirstLetter,
  getDateString,
} from "../../utilities/utilities";
import { addRecreateRfpObject } from "../../../store/features/sessionInfoSlice";
import { PastOrderButtons } from "./PastOrderButtons";
import { useDispatch } from "react-redux";
var pluralize = require("pluralize");

export const PastOrderCard = (props) => {
  const order = props.order;
  const product = useGetProduct(order);
  const dispatch = useDispatch();
  const user = props.user;
  const navigate = useNavigate();

  let productName = captializeFirstLetter(product.name);
  let unitText = pluralize("Unit", order.num_units);

  const total = order.price_per * order.num_units;

  // let start_date = order.delivery_date_start;
  let end_date = order.delivery_date_end;

  const setRatings = {};

  for (const [orderKey, orderValue] of Object.entries(props.ratingsGiven)) {
    //check to see if order already rated
    if (orderKey === order.id) {
      for (const [key, value] of Object.entries(orderValue)) {
        if (key === order.lead_buyer) {
          setRatings["lead_buyer"] = value;
        }
        if (key === order.vendor) {
          setRatings["vendor"] = value;
        }
      }
    }
  }

  const recreateRfp = () => {
    let oldRfp = {
      name: order.name,
      product: order.product,
      num_units: order.num_units,
      city: order.city,
      state: order.state,
      zipcode: order.zipcode,
      price_per_range_start: order.price_per,
      price_per_range_end: order.price_per,
    };
    dispatch(addRecreateRfpObject(oldRfp));
    navigate("/buyer/create-rfp");
  };

  return (
    <Flex
      maxW="40rem"
      direction="column"
      p={0}
      border="1px solid"
      borderTopWidth="3px"
      borderColor="gray.300"
      className="card"
    >
      <div className="top-left-angle-white"></div>
      <Flex flex="0 0 auto" p="0.7rem 1rem">
        <Tag
          as="div"
          flex="0 0 auto"
          bg="transparent"
          borderColor="gray.500"
          color="gray.500"
          size="sm"
        >
          Order Complete
        </Tag>
      </Flex>
      <Box flex="0 0 auto" position="relative" maxH="10rem" overflow="hidden">
        <AspectRatio ratio={4 / 3}>
          <Image
            src={product?.image_url}
            alt={product?.name}
            draggable="false"
            fallback={<Skeleton />}
          />
        </AspectRatio>
      </Box>

      <Flex direction="column" p={0} flex="1 1 auto">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          flex="1 1 auto"
          p={4}
          pb={2}
        >
          <Text fontSize="lg" fontWeight="bold">
            {order.name}
          </Text>
          {user.account_type === "buyer" && (
            <Text fontSize="sm">{order.company_name}</Text>
          )}

          <Text fontSize="sm" mb="0.5rem">
            {order.city}
            {", "}
            {order.state} {order.zipcode}
          </Text>

          <Flex alignItems="center" mb="0.5rem">
            <Container p={0} flex="1 1 50%">
              <Text fontSize="sm" color="gray.500">
                Confirmed Price:
              </Text>
              <Text fontSize="xxl" fontWeight="extrabold" lineHeight="normal">
                <Text as="span" fontSize="md" position="relative" top="-1rem">
                  {" "}
                  ${" "}
                </Text>
                {total}
              </Text>
            </Container>

            <Container p={0} flex="1 1 50%">
              <Text>
                {order.num_units} {productName} {unitText}
                {/* <Text as="span" fontSize="sm">
                  Units
                </Text> */}
              </Text>
              <Text>
                ${order.price_per}{" "}
                <Text as="span" fontSize="sm">
                  per unit
                </Text>
              </Text>
            </Container>
            <Flex align="center" pr={6}>
              <Icon as={FiDollarSign} fontSize="lg" color="gray.500" />
            </Flex>
          </Flex>

          <Divider />

          <Flex py={2}>
            <Flex direction="column" flex="1 1 100%">
              <Text as="span" fontSize="sm" color="gray.500">
                Deadline{" "}
              </Text>
              <Text as="span" fontSize="md" fontWeight="bold">
                {getDateString(end_date)}
              </Text>
            </Flex>
            <Flex align="center" pr={6}>
              <Icon as={FiCalendar} fontSize="lg" color="gray.500" />
            </Flex>
            {/* <Flex direction="column" flex="1 1 50%">
              <Text as="span" fontSize="sm">
              Proposed delivery date{" "}
              </Text>
              <Text as="span" fontSize="md" fontWeight="bold">
              {formatDate(proposal.delivery_date_end)}{" "}
              </Text>
            </Flex> */}
          </Flex>

          <Divider />

          <ButtonGroup
            w="100%"
            justifyContent="space-between"
            mt="2rem"
            pr="40px"
          >
            {user.uid === order.vendor ? (
              <Button variant="stacked">
                <Icon boxSize={5} as={FiSearch} mr="0.5rem" />
                <Text as="span">Find Similar RFP</Text>
              </Button>
            ) : (
              <Button variant="stacked" onClick={recreateRfp}>
                <Icon boxSize={5} as={FiClipboard} mr="0.5rem" />
                <Text as="span">Create Similar RFP</Text>
              </Button>
            )}
            <PastOrderButtons
              order={order}
              user={user}
              ratingsGiven={props.ratingsGiven}
            />
          </ButtonGroup>
        </Flex>
      </Flex>
      <div className="bottom-right-angle-white"></div>
    </Flex>
  );
};
